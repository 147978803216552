import React, { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import { login, sendOTP } from "api";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import chat from "assets/images/logo.svg";
import { AuthContext } from "context/AuthContext";
import { toasterShow } from "../../../../store/slices/userSlice";
import { loaderShow } from "./../../../../store/slices/commonUISlice";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import MuiLink from "@mui/material/Link";

function Illustration({ pageType, title, description, illustration }) {

  const [loginWithEmail, setLoginWithEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formData = useCallback(() => {
    if (loginWithEmail) {
      return { email: "" };
    } else {
      return { mobile: "" };
    }
  }, [loginWithEmail]);
  const validationSchema = useCallback(() => {
    if (loginWithEmail) {
      return Yup.object().shape({
        email: Yup.string().email('The email address is invalid').required('The email address is required'),
      })
    } else {
      const regex = /^[6-9]\d{9}$/; // regular expression to match 10-digit Indian mobile numbers
      return Yup.object().shape({
        mobile: Yup.string().matches(regex, 'The mobile number is invalid').required('The mobile number is required'),
      })
    }
  }, [loginWithEmail]);

  const _handleSendOTP = async () => {
    try {
      //setLoading(true);
      dispatch(loaderShow({
        loader: true
      }));
      const response = await sendOTP(values);
      dispatch(loaderShow({
        loader: false
      }));
      setOtpSent(true);
      dispatch(toasterShow({
        toasterColor: "success",
        toasterMessage: response.data.detail,
        toasterToggle: true
      }));
    } catch (error) {
      dispatch(loaderShow({
        loader: false
      }));
      dispatch(toasterShow({
        toasterColor: "error",
        toasterMessage: error.message,
        toasterToggle: true
      }));
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange: handleLoginInputChange,
    handleSubmit: handleSendOTP
  } =
    useFormik({
      initialValues: formData,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        _handleSendOTP()
      },
      validateOnChange: true,
      validateOnBlur: true,
    });

  const handleChange = useCallback(
    (e) => {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");
      const otpArray = [];
      for (let i = 0; i < 6; i++) {
        const fieldName = `otp-${i}`;
        const fieldValue = document.getElementsByName(fieldName)[0].value;
        otpArray.push(fieldValue || "");
      }
      const newOtp = otpArray.join("");
      setOtp(newOtp);
      if (value.length >= maxLength) {
        if (parseInt(fieldIndex, 10) < 6) {
          const nextSibling = document.querySelector(
            `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
          );
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }
    },
    [setOtp]
  );
  const { handleOTPVerification } = useContext(AuthContext);
  const handleVerifyOTP = useCallback(async () => {
    try {
      dispatch(loaderShow({
        loader: true
      }));
      const response = await handleOTPVerification({ ...values, token: otp });
      dispatch(loaderShow({
        loader: false
      }));
      let token = localStorage.getItem("token");
      if (token) {
        navigate('/invoices')
      }

      if (response.success === false) {
        dispatch(toasterShow({
          toasterColor: "error",
          toasterMessage: response.data.token[0],
          toasterToggle: true
        }));
      }
    } catch (error) {
      dispatch(loaderShow({
        loader: false
      }));
      dispatch(toasterShow({
        toasterColor: "error",
        toasterMessage: error.message,
        toasterToggle: true
      }));
    }
  }, [values, otp]);

  useEffect(() => {
    if (otp.length === 6) {
      handleVerifyOTP();
    }
  }, [otp, handleVerifyOTP]);

  // if user is already logged in, redirect to dashboard
  useEffect(() => {
    let login = localStorage.getItem("token");
    if (login) {
      navigate('/invoices')
    }
  }, [login]);
  const renderInputField = useCallback(() => {
    if (otpSent) {
      return (
        <>
          <SoftBox mb={2}>
            <Grid container spacing={2}>
              {[...Array(6)].map((_, index) => (
                <Grid item xs key={index}>
                  <SoftInput
                    size="large"
                    inputProps={{ maxLength: 1 }}
                    onChange={handleChange}
                    name={`otp-${index}`}
                  />
                </Grid>
              ))}
            </Grid>
          </SoftBox>
          <SoftBox textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Haven&apos;t received it?{" "}
              <MuiLink component="button" onClick={(event) => {
                event.preventDefault()
                handleSendOTP()
              }}>
                <SoftTypography variant="button">
                  Resend a new code
                </SoftTypography>
              </MuiLink>
            </SoftTypography>
          </SoftBox>
          <SoftBox textAlign="center">
            <MuiLink component="button" onClick={(event) => {
              event.preventDefault()
              setOtpSent(false)
            }}>
              <SoftTypography variant="button">Wrong {loginWithEmail ? "email" : "mobile number"}?</SoftTypography>
            </MuiLink>
          </SoftBox>
        </>
      );
    }
    return null;
  }, [otpSent, handleChange]);

  const classes = classNames({
    "sign-in": pageType === "sign-in",
    "sign-up": pageType === "sign-up",
  });

  return (
    <IllustrationLayout
      color="success"
      variant="outlined"
      pageType="sign-in"
      title="Login or Signup"
      description="Enter your registered mobile number"
      illustration={{
        image: chat,
        title: 'GST and beyond, all in one place',
        description:
          "Manage your business with ease - invoicing, GST returns, reports, loans, all in one place.",
      }}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          {loginWithEmail ? <>
            <SoftInput
              name={"email"}
              type="email"
              placeholder="Enter your email address"
              size="large"
              value={values.email}
              onChange={handleLoginInputChange}
              onBlur={handleBlur}
              onKeyPress={function (event) {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSendOTP()
                }
              }}
              icon={{
                component: "email",
                direction: "left",
              }}
              style={{ letterSpacing: "0.2rem" }}
              disabled={otpSent}
              error={touched.email && Boolean(errors.email)}
            />
            <FormHelperText error={true}>{touched.email && errors.email}</FormHelperText>
          </> : <>
            <SoftInput
              name={"mobile"}
              type="tel"
              placeholder="Enter your mobile number"
              inputProps={{ maxLength: 10 }}
              size="large"
              value={values.mobile}
              onChange={handleLoginInputChange}
              onBlur={handleBlur}
              onKeyPress={function (event) {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSendOTP()
                }
              }}
              icon={{
                component: values.mobile.length === 10 ? "check" : "phone",
                direction: "left",
              }}
              style={{ letterSpacing: "0.2rem" }}
              disabled={otpSent}
              error={touched.mobile && Boolean(errors.mobile)}
            />
            <FormHelperText error={true}>{touched.mobile && errors.mobile}</FormHelperText>
          </>}

        </SoftBox>
        {otpSent ? (
          renderInputField()
        ) : (
          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant="gradient"
              color="success"
              size="large"
              onClick={handleSendOTP}
              fullWidth
              disabled={loading}
            >
              {/* {loading ? <CircularProgress size={24} /> : "send otp"} */}
              Send otp
            </SoftButton>
          </SoftBox>
        )}
      </SoftBox>

    </IllustrationLayout>
  );
}

export default Illustration;
