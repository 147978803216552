const form = {
    formId: "gstin-login-form",
    formField: {
      GSTINNumber: {
        name: "GSTINNumber",
        label: "GSTIN Number",
        type: "text",
        placeholder: "",
        errorMsg: "GSTIN Number is required.",
      },
      GSTINUsername: {
        name: "GSTINUsername",
        label: "GSTIN Username",
        type: "text",
        placeholder: "",
        errorMsg: "GSTIN Username is required.",
      },
      OTP: {
        name: "OTP",
        label: "OTP",
        type: "text",
        placeholder: "",
        errorMsg: "OTP is required.",
      },
    },
  };
  
  export default form;
