import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CardConrol from "./cardControl";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import Divider from "@mui/material/Divider";
import { DownloadGSTR2AB2BReport } from "api/Business/GSTAuthentication";
import Loader from "common/loader";
import { toasterShow } from "store/slices/userSlice";

const FileReturn = (props) => {
  const dispatch = useDispatch();

  const { data, month, year, businessId } = props;
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(false);
  }, [data]);

  const getGSTR2AB2BSummary = useCallback(
    async (month, year) => {
      const formData = {
        businessId: businessId,
        month: parseInt(month.value),
        year: parseInt(year.value),
      };
      try {
        const response = await DownloadGSTR2AB2BReport(formData);

        if (response.success === true) {

          const reportUrl = response.data.report_file_url;

          // Create a link element
          const link = document.createElement("a");
          link.href = reportUrl;
          link.target = "_blank";
          link.download = "report.pdf";

          // Append the link to the document body
          document.body.appendChild(link);

          // Simulate a click on the link to trigger the download
          link.click();

          // Remove the link from the document body
          document.body.removeChild(link);

          dispatch(
            toasterShow({
              toasterColor: "success",
              toasterToggle: true,
              toasterTitle: "Report generated successfully",
            })
          );
        } else {
          dispatch(
            toasterShow({
              toasterColor: "error",
              toasterMessage: response.message,
              toasterToggle: true,
              toasterTitle: "Error while generating report",
            })
          );
        }
      } catch (error) {
        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: error.message,
            toasterToggle: true,
            toasterTitle: "Error while generating report",
          })
        );
      }
      setLoading(false);
    },
    [businessId, dispatch]
  );

  const handleButtonClick = () => {
    setLoading(true);
    getGSTR2AB2BSummary(month, year);
  };

  const totalNoofInvoices = data
    ? data.reduce((acc, item) => {
        return acc + (item.inv ? item.inv.length : 0);
      }, 0)
    : 0;

  const renderSupplierInvoices = () => {
    return data.map((item) => {
      const inv = item.inv;

      return (
        <SoftBox my={3} mx={7} key={item.ctin}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={12}>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="button" fontWeight="medium">
                  Supplier: {item.ctin} | No. of Invoices: {item.inv.length}
                </SoftTypography>
              </SoftBox>
            </Grid>

            {inv.map((invoice) => {
              const itms = invoice.itms;

              return (
                <Grid item xs={12} md={3} key={invoice.inum}>
                  <CardConrol
                    label1={`Invoice No: ${invoice.inum}`}
                    label2={`Invoice Amount: ₹${invoice.val.toLocaleString()}`}
                    label3={`Invoice Type: ${invoice.inv_typ}`}
                    iconname="check_circle"
                    filed={true}
                  />
                </Grid>
              );
            })}
          </Grid>
        </SoftBox>
      );
    });
  };

  return (
    <>
          <Loader open={loading} />

      <>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" my={2} mx={7}>
          <SoftBox>
            <SoftBox mb={1}>
              <SoftTypography variant="h6" fontWeight="medium">
                GSTR2A B2B Invoices
              </SoftTypography>
            </SoftBox>
            <SoftTypography component="p" variant="button" fontWeight="regular" color="text">
              No. of Suppliers: <span sx={{ fontWeight: "bold" }}>{data.length}</span>
            </SoftTypography>
            <SoftTypography component="p" variant="button" fontWeight="regular" color="text">
              No. of Invoices: <span sx={{ fontWeight: "medium" }}>{totalNoofInvoices}</span>
            </SoftTypography>
          </SoftBox>
          <SoftButton variant="gradient" color="secondary" onClick={handleButtonClick} disabled={loading}>
            Download Report
          </SoftButton>
        </SoftBox>
        <Divider />
        {renderSupplierInvoices()}
      </>
    </>
  );
};

export default FileReturn;
