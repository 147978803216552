import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CardConrol from "./cardControl";


const FileReturn = (props) => {
  const { data, returnType, returnPeriod } = props;

  if (data.length < 1) {
    return null; // Return null if data is empty
  }

  return (
    <SoftBox key="gstr summary">
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={12}>
          <SoftBox height="100%" mt={0.5} lineHeight={1}>
            <SoftTypography variant="h5" fontWeight="medium">
              {returnType} Summary | Period {returnPeriod}
            </SoftTypography>
          </SoftBox>
        </Grid>
        {data
          .filter((item) => item.ttl_rec > 1)
          .map((item, index) => (
            <Grid item xs={12} md={3} key={index}>
              <CardConrol
                label1={`Total Records: ${item.ttl_rec}`}
                label2={`Total Tax: ${item.ttl_tax}`}
                label3={`Total Value: ${item.ttl_val}`}
                record_type={item.sec_nm}
                filed={true}
              />
            </Grid>
          ))}
      </Grid>
    </SoftBox>
  );
};

export default FileReturn;