// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { useNavigate } from "react-router-dom";

function Header(props) {
  const navigate = useNavigate();

  const handleBackButton = () => navigate(-1);

  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftButton variant="gradient" color="secondary" onClick={handleBackButton}>
                <Icon>arrow_back</Icon>&nbsp;
                back
              </SoftButton>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftButton variant="outlined" color="secondary" onClick={handleBackButton}>
                cancel
              </SoftButton>
              <SoftButton variant="gradient" color="success" sx={{ ml: 2 }} onClick={props.handleSubmit}>
                <SoftTypography color="dark" variant="button" textTransform="uppercase">Submit</SoftTypography>
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default Header;
