import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import selectData from "common/DatetimeData";
import SoftButton from "components/SoftButton";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";

function Settings() {
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(
    selectData.financialYear.currentYearObj
  );
  const [selectedQuarter, setSelectedQuarter] = useState(selectData.quarter.currentQuarterObj);
  const [selectedMonth, setSelectedMonth] = useState(selectData.month.currentMonthObj);

  const [quarterMonths, setQuarterMonths] = useState([]);

  useEffect(() => {
    updateQuarterMonths();
  }, [selectedQuarter]);

  const updateQuarterMonths = () => {
    const startMonthValue = selectedQuarter.startMonthValue;
    const endMonthValue = selectedQuarter.endMonthValue;
    const filteredMonths = selectData.month.months.filter(
      (month) => month.value >= startMonthValue && month.value <= endMonthValue
    );
    setQuarterMonths(filteredMonths);
    setSelectedMonth(filteredMonths[0]);
  };

  const currentBusiness = useSelector((state) => state.user.selectedBusiness);

  const showAlert = (type) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `Are you sure you want to file GSTR ${type}?`,
        html: `
      <style>
        .alert-content {
          font-family: Arial, sans-serif;
          color: #333;
        }
        .alert-content p {
          margin-bottom: 15px;
        }
        .alert-content .section {
          margin-bottom: 25px;
        }
        .alert-content .section-heading {
          font-weight: bold;
          display: block;
          margin-bottom: 10px;
        }
        .alert-content ul {
          list-style-type: none;
          padding-left: 0;
          margin-top: 0;
        }
        .alert-content li {
          margin-bottom: 5px;
        }
        .alert-content li strong {
          font-weight: bold;
        }
      </style>
    
      <div class="alert-content">
    
        <div class="section">
          <span class="section-heading">Business Details:</span>
          <ul>
            <li><strong>Business Name:</strong> ${currentBusiness.name}</li>
            <li><strong>GSTIN:</strong> ${currentBusiness.additional_details.gstin}</li>
          </ul>
        </div>
    
        <div class="section">
          <span class="section-heading">Time Period:</span>
          <ul>
          <li><strong>Month:</strong> ${selectedMonth.label}</li>
          <li><strong>Quarter:</strong> ${selectedQuarter.value}</li>
            <li><strong>Financial Year:</strong> ${selectedFinancialYear.value}</li>
          </ul>
        </div>
      </div>
    `,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          sendSMS(type);
        }
      });
  };

  const sendSMS = (type) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal.fire({
      html: `
      <style>
      /* Adjusting Swal container */
      .swal2-container {
        font-size: 0.9em; /* Reducing the font size for overall content */
      }

      .alert-content {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #333;
        line-height: 1.4; /* Adjusting line height for compactness */
        max-width: 500px;
        margin: auto;
      }

      .alert-content h3 {
        color: #2c3e50;
        font-size: 1.1em; /* Slightly smaller heading */
        margin-bottom: 15px; /* Reduced margin */
      }

      .alert-content .section {
        background-color: #e9f7ef;
        padding: 10px; /* Less padding for compactness */
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 15px; /* Reduced margin */
      }

      .alert-content .section-heading {
        font-weight: 600;
        margin-bottom: 8px; /* Reduced margin */
        color: #38c172;
      }

      .alert-content ul {
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
      }

      .alert-content li {
        margin-bottom: 8px; /* Reduced margin */
      }

      .alert-content li strong {
        font-weight: bold;
      }

      .alert-content textarea {
        width: 100%;
        padding: 8px; /* Less padding for compactness */
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 5px;
        resize: none;
      }

      .copy-text {
        display: inline-block;
        margin-left: 10px;
        color: #3498db;
        cursor: pointer;
        font-size: 0.9em; /* Smaller font size */
        position: relative;
      }
    </style>
  
      <div class="alert-content">
        <h3>Follow these steps to file GSTR ${type}</h3>
        <div class="section">
          <span class="section-heading">Step 1:</span>
          <ul>
            <li>Send the following message to <strong>14409</strong> from your registered mobile number:</li>
            <li>
              <textarea readonly>NIL R${type} ${currentBusiness.additional_details.gstin} ${selectedMonth.value.toString().padStart(2, '0')}${selectedFinancialYear.startYear}</textarea>
              <span class="copy-text" onclick="navigator.clipboard.writeText(this.previousElementSibling.value);">
                📋 Copy Message
              </span>
            </li>
          </ul>
        </div>
  
        <div class="section">
          <span class="section-heading">Step 2:</span>
          <ul>
            <li>If validations for filing Nil Form GSTR-${type} are satisfied, you will receive a “Verification CODE” on the same mobile number.</li>
          </ul>
        </div>
  
        <div class="section">
          <span class="section-heading">Step 3:</span>
          <ul>
            <li>Copy the following message, replace <strong>&lt;code&gt;</strong> with the actual code received, and send it to <strong>14409</strong>:</li>
            <li>
              <textarea readonly>CNF R${type} <code></textarea>
              <span class="copy-text" onclick="navigator.clipboard.writeText(this.previousElementSibling.value);">
                📋 Copy Message
              </span>
            </li>
          </ul>
        </div>
      </div>
      `,
      icon: "info",
      confirmButtonText: "Done",
    });
  };

  // const showOtpInput = () => {
  //   Swal.fire({
  //     customClass: {
  //       confirmButton: "button button-success",
  //       cancelButton: "button button-error",
  //       input: "swal2-input", // Your default class for input
  //     },
  //     buttonsStyling: false,
  //     title: "Enter OTP",
  //     input: "text", // Keep as "text" to allow maxLength to work
  //     inputAttributes: {
  //       autocapitalize: "off",
  //       maxlength: 8, // Limit input to 8 characters
  //       inputmode: "numeric", // Brings up the numeric keypad on mobile devices
  //     },
  //     showCancelButton: true,
  //     confirmButtonText: "Submit",
  //     showLoaderOnConfirm: true,
  //     preConfirm: (enteredOtp) => {
  //       setOtp(enteredOtp);
  //       return new Promise((resolve, reject) => {
  //         // Simulating OTP verification delay
  //         setTimeout(() => {
  //           // Implement actual OTP verification logic here
  //           const isOtpCorrect = true; // Replace with actual OTP verification logic

  //           if (isOtpCorrect) {
  //             resolve();
  //           } else {
  //             Swal.hideLoading();
  //             Swal.resetValidationMessage(); // Reset any previous validation messages
  //             Swal.showValidationMessage("Invalid OTP. Please try again.");
  //             reject();
  //           }
  //         }, 2000);
  //       });
  //     },
  //     allowOutsideClick: () => !Swal.isLoading(),
  //   })
  //     .then((result) => {
  //       if (result.isConfirmed) {
  //         // OTP is valid
  //         // Add the success class to the input field
  //         const inputField = Swal.getInput();
  //         if (inputField) {
  //           inputField.classList.add("input-success");
  //         }
  //         showSuccessMessage();
  //       }
  //     })
  //     .catch((error) => {
  //       // No need to handle here as the validation message is shown in preConfirm
  //     });
  // };

  // const showSuccessMessage = () => {
  //   Swal.fire({
  //     title: "Success!",
  //     text: "Thanks for filing GST using GoSeta. It has been successfully filed.",
  //     icon: "success",
  //   });
  // };

  const handleButtonClick = (type) => {
    showAlert(type);
  };

  const setFinancialYear = (year) => {
    setSelectedFinancialYear(year);
  };

  const setQuarter = (quarter) => {
    setSelectedQuarter(quarter);
    updateQuarterMonths();
  };

  const setMonth = (month) => {
    setSelectedMonth(month);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SoftBox py={3}>
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "relative",
            mt: 2,
            mb: 3,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  Business Name
                </SoftTypography>
                <SoftTypography variant="h5" color="dark" fontWeight="medium">
                  {currentBusiness.name}
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  Business GSTIN
                </SoftTypography>

                <SoftTypography variant="h5" fontWeight="medium">
                  {currentBusiness.additional_details.gstin}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </Card>

        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <SoftBox mt={2} px={3}>
            <SoftTypography textAlign="center" variant="h6">
              1. Select the Financial Year, Quarter and Month for which you want to file the return.
            </SoftTypography>
            <Divider />
          </SoftBox>

          <SoftBox component="form" pb={4}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={9}>
                <Grid container spacing={3} mt={1}>
                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Financial Year
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        placeholder={selectedFinancialYear}
                        options={selectData.financialYear.years}
                        value={selectedFinancialYear}
                        onChange={(event) => setFinancialYear(event)}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Quarter
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        placeholder={selectedQuarter}
                        options={selectData.quarter.quarters}
                        value={selectedQuarter}
                        onChange={(event) => setQuarter(event)}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Month
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        placeholder={selectedMonth}
                        options={quarterMonths}
                        value={selectedMonth}
                        onChange={(event) => setMonth(event)}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>

        <SoftBox mt={4}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <SoftBox mt={2} px={3}>
              <SoftTypography textAlign="center" variant="h6">
                2. Choose the type of return you want to file.
              </SoftTypography>
              <Divider />
            </SoftBox>

            <SoftBox component="form" pb={5}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <Grid container spacing={4} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftButton
                          variant="contained"
                          size="large"
                          color="success"
                          onClick={() => handleButtonClick("1")}
                        >
                          GSTR 1
                        </SoftButton>
                      </SoftBox>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftButton
                          variant="contained"
                          size="large"
                          color="success"
                          onClick={() => handleButtonClick("3")}
                        >
                          GSTR 3B
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
