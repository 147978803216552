// @mui core components
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDatePicker from "components/SoftDatePicker";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import BusinessInformationCard from "layouts/invoices/invoice/components/CreateInvoice/BusinessInformationCard";

import { useSelector } from "react-redux";

function BasicInfo(props) {
  const { buyers, reloadBuyersList, values, errors, touched, setFieldValue, handleBlur, handleChange } = props;

  const listBusiness = useSelector((state) => {
    return state.user.businessesList;
  });

  const selectedBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );
  const selectedBusiness = listBusiness.find(
    (business) => business.id === selectedBusinessID
  );

  const handleSetInvoiceDate = ([date]) => setFieldValue("date", date);
  const handleSetDueDate = ([date]) => setFieldValue("due_date", date);

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">Basic Info</SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              name="invoice_number"
              label="Invoice Number"
              placeholder="INV00001"
              value={values.invoice_number}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.invoice_number && Boolean(errors.invoice_number)}
            />
            <FormHelperText error={true}>{touched.invoice_number && errors.invoice_number}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SoftBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Invoice Date
                </SoftTypography>
              </SoftBox>
              <SoftDatePicker
                input={{ name: "date", onBlur: handleBlur }}
                value={values.date}
                onChange={handleSetInvoiceDate}
                error={touched.date && Boolean(errors.date)}
              />
              <SoftBox display="inline-block">
                <CalendarMonthIcon data-toggle className="date-picker-icon" />
              </SoftBox>
            </SoftBox>
            <FormHelperText error={true}>{errors.date}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SoftBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Invoice Due Date
                </SoftTypography>
              </SoftBox>
              <SoftDatePicker
                input={{ name: "due_date", onBlur: handleBlur }}
                value={values.due_date}
                onChange={handleSetDueDate}
                error={touched.due_date && Boolean(errors.due_date)}
              />
              <SoftBox display="inline-block">
                <CalendarMonthIcon data-toggle className="date-picker-icon" />
              </SoftBox>
            </SoftBox>
            <FormHelperText error={true}>{errors.due_date}</FormHelperText>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Buyer
                    </SoftTypography>
                  </SoftBox>
                  <BusinessInformationCard
                    businessList={buyers}
                    reloadBusinessList={reloadBuyersList}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Seller
                    </SoftTypography>
                  </SoftBox>
                  <BusinessInformationCard
                    details={selectedBusiness}
                    businessList={[]}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
