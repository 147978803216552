/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SoftInput from "components/SoftInput";
import Grid from "@mui/material/Grid";
import SoftDatePicker from "components/SoftDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import SoftSelect from "components/SoftSelect";
import "../invoices.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddNewCustomer from "../components/addNewCustomer";
import InvoiceFooter from "../components/invoiceFooter";
import AddNewProduct from "../components/addNewProduct";
import AddToBill from "../components/addToBill/addToBill";
import SearchExistingCustomer from "../components/addToBill/components/SearchExistingCustomer";
import SearchExistingProduct from "../components/existingCustomer/existingCustomerSearch";
import productData from "../components/addToBill/components/data/productData";
// import customerData from "../components/existingCustomer/data/customerData";
import Label from "../common/commonlabel";
import dataTableData from "../components/addToBill/dataTableData";
import { Link } from "react-router-dom";
import { NoProductInvoice } from "../components/noProductInvoice/noProductInvoice";
import { getCustomerDataFun } from "../../../../api";
import { useDispatch } from "react-redux";
import { toasterShow } from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";

const AllOPtions = [
  { value: "january", label: "January" },
  { value: "february", label: "February" },
  { value: "march", label: "March" },
  { value: "april", label: "April" },
  { value: "may", label: "May" },
  { value: "june", label: "June" },
  { value: "july", label: "July" },
  { value: "august", label: "August" },
  { value: "september", label: "September" },
  { value: "october", label: "October" },
  { value: "november", label: "November" },
  { value: "december", label: "December" },
];
function OrderList() {
  const [addBillTableData, setAddBillTableData] = useState(dataTableData);
  const [customername, setCustomername] = useState(AllOPtions[0].value);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchExisting, setSearchExisting] = useState(false);
  const [searchExistingVal, setSearchExistingVal] = useState("");
  const [customerData, setCustomerData] = useState();
  // const [getId,setGetId]=useState();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    billSupply: false,
    showDescription: false,
    showItems: false,
  });
  const handleBillSupply = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSetStartDate = (newDate) => setStartDate(newDate);
  const handleSetEndDate = (newDate) => setEndDate(newDate);
  const { billSupply, showDescription, showItems } = state;
  const getId = useSelector((state) => state.user.selectedBusinessID);
  const getCustomerData = async () => {
    const URL = `api/v1/vendor/?business=${getId}`;
    try {
      const response = await getCustomerDataFun(URL);
      console.log("response data", response.data.results.length);
      if (response) {
        setCustomerData(response.data.results);
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
        })
      );
    }
  };
  useEffect(() => {
    getCustomerData();
  }, [getId]);
  console.log("customerData", customerData);
  return (
    <DashboardLayout className="invoice-gosta-container">
      <DashboardNavbar />
      <div className="create-invoice-section">
        <SoftBox p={2} sx={{ marginBottom: "24px" }}>
          <Grid container spacing={1}>
            <Grid item md={12} className="create-invoice-header">
              <SoftTypography component="h5">
                <Link to="/invoices/invoiceGosta">
                  <ArrowBackIosIcon />
                </Link>
                <span>Create Invoice </span>
              </SoftTypography>
              <div className="slectbox">
                <SoftSelect
                  color="dark"
                  defaultValue={{ value: "inv", label: "INV" }}
                  openMenuOnClick={true}
                  title=""
                  options={[
                    { value: "inv", label: "INV" },
                    { value: "inv1", label: "INV1" },
                  ]}
                />
              </div>
              <div className="slectbox">
                <SoftInput placeholder="1" type="number" />
              </div>
            </Grid>
          </Grid>
        </SoftBox>
        <Card sx={{ marginBottom: "24px", overflow: "visible" }}>
          <SoftBox p={2}>
            <Grid container spacing={1}>
              <Grid item md={4}>
                <FormControlLabel
                  sx={{ paddingLeft: "8px" }}
                  control={
                    <Checkbox
                      checked={billSupply}
                      onChange={handleBillSupply}
                      name="billSupply"
                      style={{ backgroundColor: "#ced4da" }}
                    />
                  }
                  label="Bill of Supply"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className="select-customer-group">
              <Grid item md={4}>
                <div className="form-control">
                  <div className="label-group">
                    <Label label="Select Customer" />
                    <AddNewCustomer variant="text" />
                  </div>
                  <div className="search-existing-cusNew">
                    {/* <SoftInput placeholder="Search existing Customers, Company Name, GSTIN, tags..."
                                            onChange={searchExistingCusFun}
                                            value={searchExistingVal}
                                        /> */}
                    <SearchExistingCustomer
                      placeholder="Search existing Customers, Company Name, GSTIN, tags..."
                      customerData={customerData}
                    />

                    {/* {
                                            searchExisting && <SearchExistingCustomerDropdown />
                                        } */}
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <div className="form-control">
                      <Label
                        label={
                          billSupply ? "Bill of Supply Date" : "Invoice Date"
                        }
                      />
                      <div class="flatpickr">
                        <SoftDatePicker
                          allowInput={true}
                          value={startDate}
                          onChange={handleSetStartDate}
                          placeholder="30-07-2023"
                        />
                        <CalendarMonthIcon
                          data-toggle
                          className="date-picker-icon"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className="form-control">
                      <Label label="Due Date" />
                      <SoftDatePicker
                        value={endDate}
                        onChange={handleSetEndDate}
                        placeholder="31-10-2023"
                        options={{ minDate: startDate }}
                      />
                      <CalendarMonthIcon
                        data-toggle
                        className="date-picker-icon"
                      />
                    </div>
                  </Grid>

                  {/* <Grid item md={12}>
                                        
                                    </Grid> */}
                </Grid>
              </Grid>
              <Grid item md={4} className="">
                <div className="form-control">
                  <Label label="Reference" />
                  <SoftInput
                    placeholder="Any text, PO Number etc... (Optional)"
                    multiline
                    rows={2}
                  />
                </div>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
        <SoftBox sx={{ marginBottom: "8px", marginTop: "24px" }}>
          <Grid container spacing={1}>
            <Grid item md={9}>
              <SoftBox sx={{ display: "inline-flex", paddingLeft: "16px" }}>
                <FormControlLabel
                  sx={{ marginRight: "50px" }}
                  control={
                    <Checkbox
                      checked={showDescription}
                      onChange={handleBillSupply}
                      name="showDescription"
                      style={{ backgroundColor: "#ced4da" }}
                    />
                  }
                  label="Show description"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showItems}
                      onChange={handleBillSupply}
                      name="showItems"
                      style={{ backgroundColor: "#ced4da" }}
                    />
                  }
                  label="Show items in reverse order"
                />
              </SoftBox>
            </Grid>
            <Grid item md={3}>
              <Label label="Change Discount Type" />
              <SoftSelect
                className="custom-selectbox"
                defaultValue={{ value: "clothing" }}
                options={[
                  { value: "priceTax", label: "Price With Tax" },
                  { value: "unitPrice", label: "Unit Price" },
                  { value: "netAmount", label: "Net Amount" },
                  { value: "TotalAmount", label: "Total Amount" },
                ]}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <Card>
          <SoftBox
            display="block"
            bgColor="white"
            color="text"
            borderRadius="md"
            p={2}
            lineHeight={1.5}
            sx={{
              fontSize: ({ typography: { size } }) => size.md,
            }}
          >
            <Grid container spacing={1} className="select-customer-group">
              <Grid item md={5}>
                <div className="form-control">
                  <div className="label-group pb-0">
                    <AddNewProduct />
                  </div>
                  <div className="slect-input-group">
                    {/* <SoftInput placeholder="Any text, PO Number etc... (Optional)" className="input-field" /> */}

                    <SearchExistingProduct
                      placeholder="Any text, PO Number etc... (Optional)"
                      productData={productData}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={2}>
                <div className="form-control">
                  <Label label="Quantity" />
                  <SoftInput placeholder="1" type="number" />
                </div>
              </Grid>
              <Grid item md={3} className="addTobill">
                <div className="form-control">
                  <Label label="&nbsp;" />
                  <SoftButton color="info" variant="contained">
                    <AddCircleOutlineIcon />
                    Add To Bill
                  </SoftButton>
                </div>
              </Grid>
            </Grid>
          </SoftBox>
          {addBillTableData.rows.length < 0 ? (
            <>
              <AddToBill
                columns={addBillTableData.columns}
                rows={addBillTableData.rows}
              />
              <SoftBox
                display="block"
                bgColor="white"
                color="text"
                p={2}
                lineHeight={1.5}
                sx={{
                  fontSize: ({ typography: { size } }) => size.md,
                }}
              >
                <div className="add-discount-all">
                  <SoftTypography variant="h5">
                    Apply discount(%) to all items?
                  </SoftTypography>
                  <p>Items / Qty 0 / 0.000</p>
                </div>
                <div className="add-discount-all">
                  <SoftSelect
                    className="custom-selectbox"
                    defaultValue={{ value: "0", label: "0" }}
                    options={[{ value: "0", label: "0" }]}
                  />
                  <AddNewProduct buttonDesign="contained" />
                </div>
              </SoftBox>
            </>
          ) : (
            <>
              <NoProductInvoice foruse="add new product" />
            </>
          )}
        </Card>

        <InvoiceFooter />
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
