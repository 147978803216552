/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
// Soft UI Dashboard PRO React example components
function BaseLayout({ stickyNavbar, children }) {
  const [tabValue, setTabValue] = useState(0);


  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <AppBar position="static" className="gstLeftTab">
      <Tabs orientation={"vertical"} value={tabValue} onChange={handleSetTabValue}>
        <Tab label="GSTR1" />
        <Tab label="GSTR2" />
        <Tab label="GSTR3" />
      </Tabs>
    </AppBar>
  );
}

// Setting default values for the props of BaseLayout
BaseLayout.defaultProps = {
  stickyNavbar: false,
};

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
