import React, { createContext, useState } from "react";
import { login, signup, sendOTP, verifyOTP } from "api";
import { token } from "stylis";

export const AuthContext = createContext(); 

function AuthProvider(props) {
  const [currentUser, setCurrentUser] = useState(null);

  const handleOTPVerification = async (data) => {
    const response = await verifyOTP(data);
    if (response.success === true) {
      const token = response.data.token;
      localStorage.setItem("token", token);
    }
    return response;
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return false;
    }
    return true;
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, isAuthenticated, handleOTPVerification }}
      {...props}
    />
  );
}

export default AuthProvider;