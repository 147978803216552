import { makeRequest } from "api";

export const termsAndConditionsFetch = async (business_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      business: business_id,
    }
  };
  return makeRequest("api/v1/terms_and_conditions/", options);
};

export const addTermsAndConditions = async function (payload, business_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      business: business_id,
    },
    data: payload,
  };
  const data = await makeRequest("api/v1/terms_and_conditions/", options);
  return data;
};
