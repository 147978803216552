import { useState, useRef, useEffect, useCallback, useContext } from "react";

import { Formik, Form } from "formik";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";
import GSTINInfo from "../components/login/GSTINInfo";
import VerifyOTP from "../components/login/VerifyOTP";
import Loader from "common/loader";

import {
  userModalToggle,
  toasterShow,
  setErrors,
  setSelectedBusinessInfo,
} from "store/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";

import { SendGSTOTP, VerifyGSTOTP } from "api/Business/GSTAuthentication";

import validations from "./Validations";
import form from "./Schemas";
import initialValues from "./InitialValues";
import { Link, useNavigate } from "react-router-dom";

function getSteps() {
  return ["GSTIN Information", "Verify OTP"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <GSTINInfo formData={formData} />;
    case 1:
      return <VerifyOTP formData={formData} />;

    default:
      return null;
  }
}

function GSTINLogin() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [GSTINUsername, setGSTINUsername] = useState("");
  const [GSTINNumber, setGSTINNumber] = useState("");
  const [loading, setLoading] = useState(false);


  const businessId = useSelector((state) => state.user.selectedBusinessID);

  // const accessCode = useSelector((state) => state.user.GSTAccessToken);
  const [accessCode, setAccessCode] = useState("");

  const selectedBusinessInfo = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fieldMapping = {
    gstin: "GSTINNumber",
    gst_username: "GSTINUsername",
    otp: "OTP",
  };

  const fetchGSTAccessToken = useCallback(async (values, actions) => {
    try {
      const response = await SendGSTOTP(values);
      console.log("response", response);
      if (response.success === true) {
        // dispatch(
        //   setSelectedBusinessInfo({
        //     ...selectedBusinessInfo,
        //     GSTAccessToken: response.data.access_code,
        //   })
        // );
        setAccessCode(response.data.access_code);
        setGSTINUsername(values.GSTINUsername);
        setGSTINNumber(values.GSTINNumber);
        setActiveStep(activeStep + 1);

        actions.setTouched({});
      } else if (response.success === false) {

        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: "Please enter valid GSTIN number and username",
            toasterToggle: true,
            toasterTitle: "Invalid GSTIN number or username",
          })
        );
      } else {
        const mappedErrors = {};
        Object.keys(response.data).forEach((field) => {
          const frontendField = fieldMapping[field];
          if (frontendField) {
            mappedErrors[frontendField] = response.data[field];
          }
        });
        actions.setErrors(mappedErrors);
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
          toasterTitle: "Error fetching GSTIN details",
        })
      );
    }
    setLoading(false);

  }, [accessCode]);

  const VerifyGSTAccessTokenOTP = useCallback(async (values, actions, accessCode) => {
    const updatedValues = {
      ...values,
      businessId: businessId,
      accessCode: accessCode,
      OTP: parseInt(values.OTP),
    };
    console.log("updatedValues", updatedValues);
    try {
      const response = await VerifyGSTOTP(updatedValues);

      if (response.success === true) {
        dispatch(
          toasterShow({
            toasterColor: "info",
            toasterMessage: "GSTIN verified successfully",
            toasterToggle: true,
            toasterTitle: "Success",
          })
        );
        // setActiveStep(activeStep + 1);
        // actions.setTouched({});
        navigate("/gst/returnTrack");
      } else {
        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: response.data.message,
            toasterToggle: true,
            toasterTitle: "Error Verifying GSTIN OTP",
          })
        ); 
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
          toasterTitle: "Error Verifying GSTIN OTP",
        })
      );
    }
    setLoading(false);
    actions.setSubmitting(false);

  }, []);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      setLoading(true);
      VerifyGSTAccessTokenOTP(values, actions, accessCode);
    } else {
      setLoading(true);
      fetchGSTAccessToken(values, actions);
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader open={loading} />

      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </SoftButton>
                          )}
                          <SoftButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "send" : "next"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GSTINLogin;
