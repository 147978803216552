import { useContext } from "react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";


const ProtectedRoutes = ({ ComponentPro }) => {
  const { isAuthenticated } = useContext(AuthContext)
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/')
    }
  });
  return (
    <ComponentPro />
  )
}

export default ProtectedRoutes;
