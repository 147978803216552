import { date } from "yup";

const datetimeData = {
    gstrType: (() => {
      const gstr = [
        { value: 1, label: "GSTR1" },
        { value: 2, label: "GSTR2" },
        { value: 3, label: "GSTR3" },
        { value: 4, label: "GSTR4" },
      ];
      return gstr;
    })(),
    financialYear: (() => {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
      const endYear = startYear + 1;
      const years = [];
      for (let i = 0; i < 5; i++) {
        const year = startYear - i;
        const label = `${year}-${year + 1}`;
        const yearObj = {
          value: label,
          label,
          startYear: year,
          endYear: year + 1,
        };
        years.unshift(yearObj);
      }
      const currentYearObj = {
        value: `${startYear}-${endYear}`,
        label: `${startYear}-${endYear}`,
        startYear,
        endYear,
      };
      return {
        currentYearObj,
        years,
      };
    })(),
    quarter: (() => {
      const currentMonth = new Date().getMonth() + 1;
      const quarters = [
        { value: 1, label: "Quarter 1 (Apr - Jun)", startMonth: "Apr", endMonth: "Jun", startMonthValue: 4, endMonthValue: 6 },
        { value: 2, label: "Quarter 2 (Jul - Sep)", startMonth: "Jul", endMonth: "Sep", startMonthValue: 7, endMonthValue: 9 },
        { value: 3, label: "Quarter 3 (Oct - Dec)", startMonth: "Oct", endMonth: "Dec", startMonthValue: 10, endMonthValue: 12 },
        { value: 4, label: "Quarter 4 (Jan - Mar)", startMonth: "Jan", endMonth: "Mar", startMonthValue: 1, endMonthValue: 3 },
      ];
      const currentQuarter = currentMonth >= 4 ? Math.ceil((currentMonth - 3) / 3) : 4;
      const currentQuarterObj = {
        value: currentQuarter,
        label: `Quarter ${currentQuarter} (${quarters[currentQuarter - 1].startMonth} - ${
          quarters[currentQuarter - 1].endMonth
        })`,
        startMonth: `${quarters[currentQuarter - 1].startMonth}`,
        endMonth: `${quarters[currentQuarter - 1].endMonth}`,
        startMonthValue: quarters[currentQuarter - 1].startMonthValue,
        endMonthValue: quarters[currentQuarter - 1].endMonthValue,
      };
      return {
        currentQuarterObj,
        quarters: quarters,
      };
    })(),
    month: (() => {
      const currentMonth = new Date().getMonth() + 1;
      const months = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
      ];
      const currentMonthObj = months[currentMonth - 1];
      return {
        currentMonthObj,
        months,
      };
  })(),
  };
  
  export default datetimeData;
  