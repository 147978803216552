import * as React from "react";
import { useState, useRef, useEffect, useCallback, useContext } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftDialog from "components/SoftDialog";
import { Formik, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";

import SoftDatePicker from "components/SoftDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormField from "layouts/pages/account/components/FormField";
import { businessDetailsFetchUsingGSTIN } from "api/Business";
import { useSelector, useDispatch } from "react-redux";
import { loaderShow } from "store/slices/commonUISlice";
import { userModalToggle, toasterShow } from "store/slices/userSlice"; 
import {CityDetailsUsingPincode} from "api/Address";
import { AddNewBusiness } from "api/Business/AddBusiness"
import { editBusiness } from "api/Business"
import { editBusinessFetch } from "api";
import { addNewBuyer } from "api/Buyers";
import Loader from "common/loader";

const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

export default function BusinessDetails(props) {
  const [formData, setFormData] = useState({
    companyGSTIN: "",
    companyName: "",
    companyAddress: "",
    companyAddressId: -1,
    companyEmail: "",
    companyPhone: "",
    companyPAN: "",
    companyPINCODE: "",
    companyCity: "",
    companyState: "",
  });
  const [fetchedData, setFetchedData] = useState({});
  const [fetchedCityData, setFetchedCityData] = useState({});
  const { handleDialogClose } = props;  
  const { readonlyDetails = false } = props;
  const { submitButton = true } = props;
  const { loadEditData = true } = props;
  const { addBuyerAPI = false } = props;
  const [loadingEditData, setLoadingEditData] = useState(loadEditData);

  const handleClose = () => {
    handleDialogClose();
    };

  const dispatch = useDispatch();
 

  const validationSchema = Yup.object().shape({
    companyGSTIN: Yup.string(),
    companyName: Yup.string().required("Name is required"),
    companyAddress: Yup.string().required("Address is required"),
    companyAddressId: Yup.number(),
    companyEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    companyPhone: Yup.string().required("Phone is required"),
    companyPAN: Yup.string(),
    companyPINCODE: Yup.string().required("PIN Code is required"),
    companyCity: Yup.string().required("City is required"),
    companyState: Yup.string().required("State is required"),
  });

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );

  const { values, errors, touched, isValid, setFieldValue, setErrors, setTouched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        try {
          let response;
          if (addBuyerAPI) {
            response = await addNewBuyer(values, currentBusinessID);
          } else if (loadEditData) {
            response = await editBusiness(forEditselectedBusinessID, values);
          } else {
            response = await AddNewBusiness(values);
          }

          if (response && response.success === false) {
            const _errors = {
              ...response.data,
              companyGSTIN: response.data.additional_details.gstin,
              companyName: response.data.name,
              companyAddress: response.data.address,
              companyPAN: response.data.additional_details.pan,
              companyPhone: response.data.mobile,
              companyEmail: response.data.email,
              companyPINCODE: response.data.pincode,
            };
            setTouched(_errors).then(() => {
              setErrors(_errors);
            });

            dispatch(
              toasterShow({
                toasterColor: "error",
                toasterMessage: response.data.message,
                toasterToggle: true,
                toasterTitle: "Error " + (addBuyerAPI ? "adding buyer" : (loadEditData ? "updating business" : "adding business")),
              })
            );
          } else {
            setFormData({
              ...values,
              companyName: response.data.name,
              companyGSTIN: response.data.additional_details.gstin,
              companyAddress: response.data.address,
              companyPINCODE: response.data.pincode,
            });
            handleClose();

            dispatch(
              toasterShow({
                toasterColor: "success",
                toasterMessage: (addBuyerAPI ? "Buyer added" : (loadEditData ? "Business details updated" : "Business added")) + " successfully",
                toasterToggle: true,
              })
            );
          }
        } catch (error) {
          dispatch(
            toasterShow({
              toasterColor: "error",
              toasterMessage: error.message,
              toasterToggle: true,
            })
          );
        }
      },
      validateOnChange: true,
      validateOnBlur: true,
    });

  const fetchBusinessDetailsUsingGSTIN = useCallback(async (value) => {
    setLoadingEditData(true)

    try {

      const response = await businessDetailsFetchUsingGSTIN(value);
        
      if (response.success === true) {
        setFetchedData({
            companyName: response.data.company_name,
            companyAddress: response.data.company_address,
            companyPINCODE: response.data.company_pincode,
        });
    } else {
        dispatch(
            toasterShow({
              toasterColor: "error",
              toasterMessage: response.data.message,
              toasterToggle: true,
              toasterTitle: "Error fetching GSTIN details",
            })
          );    }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
          toasterTitle: "Error fetching GSTIN details",
        })
      );
    }

    setLoadingEditData(false)
  }, []);

  const fetchCityDetailsUsingPincode = useCallback(async (value) => {
    setLoadingEditData(true)

    try {

      const response = await CityDetailsUsingPincode(value);

      setFetchedCityData({
        pincodeID: response.data.id,
        stateName: response.data.city.state.name,
        stateID: response.data.city.state.id,
        cityName: response.data.city.name,
        cityID: response.data.city.id,
      });
    } catch (error) {

      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
        })
      );
    }

    setLoadingEditData(false)
  }, []);

  const handleGSTINChange = (event) => {
    let value = event.target.value;
    if (value) {
      value = value.toUpperCase();
    }
    setFieldValue('companyGSTIN', value);
  };

  const forEditselectedBusinessID = useSelector(
    (state) => state.user.forEditselectedBusinessID
  );

  useEffect(() => {
    if (!loadEditData && values.companyGSTIN.length === 15) {
        fetchBusinessDetailsUsingGSTIN(values.companyGSTIN);
    }
  }, [values.companyGSTIN]);

  const getEditData = async (id) => {
    try {
      const response = await editBusinessFetch(id);
      if (response && response.success) {
        const data = response.data;
        // setFieldValue("companyGSTIN", data.email);
        setFieldValue("companyName", data.name);
        setFieldValue("companyAddress", data.addresses[0].address);
        setFieldValue("companyAddressId", data.addresses[0].id);
        setFieldValue("companyPhone", data.mobile);
        setFieldValue("companyPINCODE", data.addresses[0].pincode);
        setFieldValue("companyEmail", data.email);
        // setFieldValue("companyPhone", data.mobile);
        setFieldValue("companyGSTIN", data.additional_details.gstin);
        setFieldValue("companyPAN", data.additional_details.pan ?? "");
        // setFieldValue("companyPINCODE", data.email);
        // setFieldValue("companyCity", data.email);
        // setFieldValue("companyState", data.email);
        console.log("edit businesses list here", data);
      }
    } catch (error) {
     console.log("edit error", error)
    }

    setLoadingEditData(false)
  };

  useEffect(() => {
    if(loadEditData && forEditselectedBusinessID > -1){
      getEditData(forEditselectedBusinessID);
    }
  }, [forEditselectedBusinessID]);



  useEffect(() => {
    if (Object.keys(fetchedData).length > 0) {
        console.log("fetchedData", fetchedData);
      setFieldValue("companyName", fetchedData.companyName);
      setFieldValue("companyAddress", fetchedData.companyAddress);
      setFieldValue("companyPINCODE", fetchedData.companyPINCODE);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (values.companyPINCODE.length === 6) {
        fetchCityDetailsUsingPincode(values.companyPINCODE);
    }
  }, [values.companyPINCODE]);

  useEffect(() => {
    if (Object.keys(fetchedCityData).length > 0) {
        console.log("fetchedCityData", fetchedCityData);
      setFieldValue("companyCity", fetchedCityData.cityName);
      setFieldValue("companyState", fetchedCityData.stateName);
    }
  }, [fetchedCityData]);

  return (
    <SoftBox component="form" pb={3} px={3}>
      <Loader open={loadingEditData} />
      <form onSubmit={handleSubmit}>
        <SoftBox
          display="flex"
          flexDirection="center"
          justifyContent="center"
          height="100%"
        >
          <SoftBox ml={1}>
            <FormField
              label="Company GSTIN"
              placeholder="22AAAAA0000A1Z5"
              name="companyGSTIN"
              value={values.companyGSTIN}
              onChange={handleGSTINChange}
              onBlur={handleBlur}
              error={touched.companyGSTIN && Boolean(errors.companyGSTIN)}
              helperText={touched.companyGSTIN && errors.companyGSTIN}
            />
            <FormHelperText error={true}>{touched.companyGSTIN && errors.companyGSTIN}</FormHelperText>
          </SoftBox>
        </SoftBox>
        <SoftBox
          mt={2}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Divider
            light
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
            }}
          >
            <SoftTypography
              variant="text"
              fontWeight="bold"
              color="dark"
              size="large"
            >
              { !readonlyDetails ? "OR" : "GSTIN DETAILS" } 
            </SoftTypography>
          </Divider>
        </SoftBox>

        <SoftBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Company Name"
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyName && Boolean(errors.companyName)}
                helpertext={touched.companyName && errors.companyName}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyName && errors.companyName}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Company Address"
                name="companyAddress"
                value={values.companyAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyAddress && Boolean(errors.companyAddress)}
                helperText={touched.companyAddress && errors.companyAddress}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyAddress && errors.companyAddress}</FormHelperText>
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                label="Company PAN"
                name="companyPAN"
                value={values.companyPAN}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyPAN && Boolean(errors.companyPAN)}
                helperText={touched.companyPAN && errors.companyPAN}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyPAN && errors.companyPAN}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label="Company Phone"
                name="companyPhone"
                value={values.companyPhone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyPhone && Boolean(errors.companyPhone)}
                helperText={touched.companyPhone && errors.companyPhone}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyPhone && errors.companyPhone}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label="Company Email"
                name="companyEmail"
                value={values.companyEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyEmail && Boolean(errors.companyEmail)}
                helperText={touched.companyEmail && errors.companyEmail}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyEmail && errors.companyEmail}</FormHelperText>
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                label="Company PINCODE"
                name="companyPINCODE"
                value={values.companyPINCODE}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyPINCODE && Boolean(errors.companyPINCODE)}
                helperText={touched.companyPINCODE && errors.companyPINCODE}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyPINCODE && errors.companyPINCODE}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label="Company City"
                name="companyCity"
                value={values.companyCity}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyCity && Boolean(errors.companyCity)}
                helperText={touched.companyCity && errors.companyCity}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyCity && errors.companyCity}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label="Company State"
                name="companyState"
                value={values.companyState}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyState && Boolean(errors.companyState)}
                helperText={touched.companyState && errors.companyState}
                disabled={readonlyDetails}
              />
              <FormHelperText error={true}>{touched.companyState && errors.companyState}</FormHelperText>
            </Grid>
          </Grid>
        </SoftBox>
        { submitButton ?
        <SoftBox justifyContent="center" alignItems="center" display="flex" mt={4}>
        <SoftButton onClick={handleSubmit} variant="contained" color="success">
            Submit
        </SoftButton>
        </SoftBox>
        : null}
      </form>
    </SoftBox>
  );
}
