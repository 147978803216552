import { makeRequest } from "api";

export const productsListFetch = async (business_id) => {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };
  return makeRequest(`api/v1/products?business=${business_id}`, options);
};

export const fetchProductDetails = async (business_id, product_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      business: business_id,
    }
  };
  return makeRequest(`api/v1/products/${product_id}/`, options);
};

export const taxNameListFetch = async function () {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`
    },
  };
  return makeRequest(`api/v1/tax/names`, options);
};

export const addNewProduct = async function (payload, business_id) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`
    },
    data: payload,
  };
  const data = await makeRequest(`api/v1/products/?business=${business_id}`, options);
  return data;
};
