import { Divider } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { fetchProductDetails } from "api/Products";
import Loader from "common/loader";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function ViewProduct() {
  const navigate = useNavigate();

  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [loadingProductDetails, setLoadingProductDetails] = useState(true);

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );

  const getData = async () => {
    try {
      const response = await fetchProductDetails(currentBusinessID, productId);
      setProductDetails(response.data)
    } catch (error) {
      console.log("error", error);
    }

    setLoadingProductDetails(false)
  };

  useEffect(() => {
    getData();
  }, []);

  const renderHeaderOrFooter = () => (
    <>
      {/* {loadingProductDetails ? null :
        <SoftBox mt={2} mb={2}>
          <Card>
            <SoftBox p={2}>
              <SoftButton variant="gradient" color="secondary" onClick={() => navigate(-1)}>
                <Icon>arrow_back</Icon>&nbsp;
                back
              </SoftButton>
            </SoftBox>
          </Card>
        </SoftBox>
      } */}
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={{ xs: 2, md: 4 }} mb={{ xs: 2, md: 4 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={11}>
            {renderHeaderOrFooter()}
            <Card>
              <Loader open={loadingProductDetails} />
              {!productDetails ? (
                <>
                  {loadingProductDetails ? null :
                    <SoftBox p={3}>
                      <SoftTypography variant="h6" fontWeight="medium" align={"center"}>
                        Product details not found
                      </SoftTypography>
                    </SoftBox>
                  }
                </>
              ) : (
                <SoftBox>
                  <SoftBox p={3}>
                    <SoftBox mb={3}>
                      <SoftTypography variant="h5" fontWeight="medium" align={"center"}>
                        Product Details
                      </SoftTypography>
                    </SoftBox>

                    <Divider />

                    <SoftBox mb={1}>
                      <SoftTypography variant="h3" fontWeight="bold">
                        {productDetails.name}
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox mt={3} mb={1} ml={0.5}>
                      <SoftTypography variant="h6" fontWeight="bold">
                        Description
                      </SoftTypography>
                      <SoftTypography variant="body2" color="text" verticalAlign="middle">
                        <div dangerouslySetInnerHTML={{ __html: (productDetails.description || "No description provided for this product").replace("\n", "<br />") }} />
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox mt={3} mb={1} ml={0.5}>
                      <SoftTypography variant="h6" fontWeight="bold">
                        HSN
                      </SoftTypography>
                      <SoftTypography variant="body2" color="text" verticalAlign="middle">
                        {productDetails.hsn}
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox mt={3} mb={1} ml={0.5}>
                      <SoftTypography variant="h6" fontWeight="bold">
                        Taxes
                      </SoftTypography>
                      <Grid container justifyContent="space-between">
                        {productDetails.taxes?.length > 0 ?
                          <Grid item xs={12} md={4} lg={3}>
                            <SoftBox display="flex">
                              {
                                productDetails.taxes?.map((tax) => (
                                  <SoftBadgeDot
                                    badgeContent={tax.name_disp + ": " + parseFloat(tax.rate) + "%"}
                                    color="success"
                                    size="md"
                                    minWidth="max-content"
                                  />
                                ))
                              }
                            </SoftBox>
                          </Grid> :
                          <SoftTypography variant="body2" color="text" verticalAlign="middle">
                            {"No tax added for this product"}
                          </SoftTypography>
                        }
                      </Grid>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}
            </Card>
            {renderHeaderOrFooter()}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ViewProduct;
