import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "../invoices.css";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
// import dataTableData from "layouts/ecommerce/products/products-list/data/dataTableData";
import DataTable from "examples/Tables/DataTable";
import Filters from "../components/ListViewFilters"

import { fetchInvoiceFiltersConfig, invoiceListFetch } from "api/invoices";
import { useDispatch, useSelector } from "react-redux";
import InvoiceCell from "layouts/invoices/invoice/components/invoiceCell";
// Images
import { useSoftUIController } from "context";
import { CurrencyRupee } from "@mui/icons-material";
import Loader from "common/loader";


function OrderList() {
  const [controller, dispatch] = useSoftUIController();
  const dispatchOther = useDispatch();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const columns = [
    {
      Header: "invoice no.",
      accessor: "invoice_no",
      width: "12%",
      Cell: ({ value: [name, data], row }) => (
        <Link to={"/invoice/view/" + row.original.invoice_id}><InvoiceCell name={name} checked={data.checked} /></Link>
      ),
    },
    {
      Header: "buyer",
      accessor: "buyer",
      width: "20%",
    },
    { Header: "date", accessor: "date" },
    { Header: "due date", accessor: "due_date" },
    {
      Header: "total amount",
      accessor: "total_amount",
      Cell: ({ value, row }) => (
        <SoftBox display="flex" alignItems="center"><CurrencyRupee />
          {(parseFloat(value) + parseFloat(row.original.tax)).toFixed(2)}
        </SoftBox>
      ),
    },
  ];

  const [tableData, setTableData] = useState({
    columns: columns,
    rows: [],
  });
  const _initialFilters = {
    "invoice_number": "",
    "buyer_gst": "",
    "buyer_name": "",
    "buyer_email": "",
    "buyer_mobile": "",
    "min_total_amount": 0,
    "max_total_amount": 10000,
    "from_date": "",
    "to_date": "",
  };
  const [initialFilters, setInitialFilters] = useState(_initialFilters);
  const [filters, setFilters] = useState(_initialFilters);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingInvoiceList, setLoadingInvoiceList] = useState(true);

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );
  const fromDateFun = (data) => {
    setStartDate(data);
    console.log("setStartDate", data)
  }
  const toDateFun = (data) => {
    setEndDate(data);
    console.log("setEndDate", data)
  }
  useEffect(() => {
    if (startDate != "" && endDate != "") {
      getData();
    }
  }, [startDate, endDate])

  const loadFiltersConfig = async () => {
    setLoadingInvoiceList(true)

    try {
      const response = await fetchInvoiceFiltersConfig(currentBusinessID);
      if (response && response.success === true) {
        let _min_total_amount = response.data.total_amount.min;
        let _max_total_amount = response.data.total_amount.max;
        if (_min_total_amount === _max_total_amount) {
          _min_total_amount = 0;
        }

        const _filters = {
          ...initialFilters,
          "min_total_amount": _min_total_amount,
          "max_total_amount": _max_total_amount,
        }
        setInitialFilters(_filters)
        setFilters(_filters)
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoadingInvoiceList(false)
  };

  useEffect(() => {
    loadFiltersConfig()
  }, []);

  const getData = async () => {
    try {
      const response = await invoiceListFetch(currentBusinessID, filters);
      if (response && response.data.count >= 0) {
        const invoices = response.data.results;
        let products = invoices?.map((invoice) => ({
          invoice_id: invoice.id,
          invoice_no: [invoice.invoice_number, { checked: false }],
          buyer: invoice.buyer.name,
          date: invoice.date,
          due_date: invoice.due_date,
          total_amount: invoice.total_amount,
          tax: invoice.items.reduce(
            (partialSum, item) => partialSum + (
              item.product.taxes.reduce(
                (itemTaxPartialSum, tax) => itemTaxPartialSum + item.quantity * parseFloat(item.unit_price) * parseFloat(tax.rate) / 100, 0
              ) || 0
            ), 0
          ) || 0,
        }));
        const fromdate = new Date(startDate);
        const todate = new Date(endDate);
        const finalProd = [];
        products?.filter(prod => {
          const rowDate = new Date(prod.date);
          if (rowDate > fromdate && rowDate < todate) {
            finalProd.push(prod);
          }
        });
        if (finalProd.length > 0) {
          products = finalProd
        }
        setTableData({
          columns: columns,
          rows: products,
        });

      } else {
        console.log("No invoices found");
      }
    } catch (error) {
      console.log("error", error);

    }

    setLoadingInvoiceList(false)
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let filtersCount = ['invoice_number', 'buyer_gst', 'buyer_name', 'buyer_email', 'buyer_mobile'].filter(
      (filter) => initialFilters[filter] !== filters[filter]
    ).length;

    if (initialFilters.from_date !== filters.from_date || initialFilters.to_date !== filters.to_date) {
      filtersCount += 1
    }
    if (initialFilters.min_total_amount !== filters.min_total_amount || initialFilters.max_total_amount !== filters.max_total_amount) {
      filtersCount += 1
    }
    setAppliedFiltersCount(filtersCount)
  }, [filters]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <DashboardLayout>

      <DashboardNavbar />
      <Loader open={loadingInvoiceList} />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >

            <Stack spacing={1} direction="row">
              <Link to="/invoices/create">
                <SoftButton variant="gradient" color="success" size="medium">
                  <SoftTypography variant="button" fontWeight="bold" color="dark" textTransform="uppercase">
                    create new invoice
                  </SoftTypography>
                </SoftButton>
              </Link>
            </Stack>

            <Stack spacing={1} direction="row">
              <SoftBadge badgeContent={appliedFiltersCount} circular>
                <SoftButton variant="outlined" color="dark" size="small"
                            onClick={handleOpenDrawer}>
                  Filters
                </SoftButton>
              </SoftBadge>
            </Stack>
          </SoftBox>
          {tableData && (
            <DataTable
              table={tableData}
              pagination={
                {
                  color: "dark"
                }
              }
              entriesPerPage={{
                defaultValue: 10,
                entries: [10, 20, 50],
              }}
              canSearch={true}
              canFilter={false}
              fromDateProps={fromDateFun}
              toDateProps={toDateFun}
              noDataText={"No invoice found"}
            />
          )}
        </Card>
        <Filters
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
          initialFilters={initialFilters}
          filters={filters}
          setFilters={setFilters}
        />

      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
