import * as React from "react";
import { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import FormField from "layouts/pages/account/components/FormField";
import { useDispatch, useSelector } from "react-redux";
import { toasterShow } from "store/slices/userSlice";
import { addNewProduct as addNewProductAPI, taxNameListFetch } from "../../../../../../../api/Products";
import FormHelperText from "@mui/material/FormHelperText";
import SoftTypography from "../../../../../../../components/SoftTypography";
import SoftSelect from "../../../../../../../components/SoftSelect";
import SoftInput from "../../../../../../../components/SoftInput";
import Icon from "@mui/material/Icon";

export default function CreateNewProduct(props) {
  const { handleDialogClose, hideSubmitButton = false, submitClicked = false } = props;

  const [taxNameList, setTaxNameList] = useState([]);
  const [taxRows, _setTaxRows] = useState([
    { id: 1, name: "", rate: "" }
  ]);
  const [taxRowsIndex, setTaxRowsIndex] = useState(taxRows.length);
  const setTaxRows = (_taxes) => {
    _setTaxRows(_taxes);
    setFieldValue("taxes", _taxes.map((tax) => ({
      name: tax.name,
      rate: tax.rate,
    })));
  }

  const dispatch = useDispatch();
  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );

  const formData = {
    name: "",
    hsn: "",
    description: "",
    taxes: [{ id: 1, name: "", rate: "" }],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Product name is required"),
    hsn: Yup.string().required("Product hsn is required"),
    description: Yup.string(),
    taxes: Yup.array(
      Yup.object().shape({
        name: Yup.number().integer().positive().required("The tax name is required"),
        rate: Yup.number().typeError("The tax rate must be a positive number").positive("The tax rate must be a positive number").required("The tax rate is required"),
      })
    ).uniqueProperty('name', 'The tax name must be unique')
  });

  const { values, errors, touched, setFieldValue, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        try {
          let response;
          response = await addNewProductAPI(values, currentBusinessID);
          handleDialogClose();

          dispatch(
            toasterShow({
              toasterColor: "success",
              toasterMessage: "Product created successfully",
              toasterToggle: true,
            })
          );
        } catch (error) {
          dispatch(
            toasterShow({
              toasterColor: "error",
              toasterMessage: error.message,
              toasterToggle: true,
            })
          );
        }
      },
      validateOnChange: true,
      validateOnBlur: true,
    });

  useEffect(() => {
    const fetchTaxesData = async () => {
      try {
        const response = await taxNameListFetch();
        if (response && response.data.length > 0) {
          setTaxNameList(
            response.data.map((data) => ({
              "label": data[1],
              "value": data[0],
            }))
          );
        } else {
          console.log("No tax found");
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchTaxesData();
  }, []);

  useEffect(() => {
    if (submitClicked) {
      handleSubmit();
    }
  }, [submitClicked])

  const handleAddTaxRow = () => {
    const newRow = { id: taxRowsIndex + 1, name: "", rate: "" };
    setTaxRows([...taxRows, newRow]);
    setTaxRowsIndex(taxRowsIndex + 1)
  };

  const handleTaxRowChange = (id, field, value) => {
    const updatedRows = taxRows.map((tax_row) => {
      if (tax_row.id === id) {
        return { ...tax_row, [field]: value };
      }
      return tax_row;
    });
    setTaxRows(updatedRows);
  };

  const handleDeleteTaxRow = (id) => {
    const updatedRows = taxRows.filter((tax_row) => tax_row.id !== id);
    setTaxRows(updatedRows);
  };


  return (
    <SoftBox component="form" pb={3} px={3}>
      <form onSubmit={handleSubmit}>
        <SoftBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <FormField
                label="Product Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helpertext={touched.name && errors.name}
              />
              <FormHelperText error={true}>{touched.name && errors.name}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label="HSN"
                name="hsn"
                value={values.hsn}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.hsn && Boolean(errors.hsn)}
                helperText={touched.hsn && errors.hsn}
              />
              <FormHelperText error={true}>{touched.hsn && errors.hsn}</FormHelperText>
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mt={4}>
          <FormField
            label="Product Description"
            name="description"
            value={values.description}
            multiline
            rows={5}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </SoftBox>
        <FormHelperText error={true}>{touched.description && errors.description}</FormHelperText>

        <SoftBox mt={4}>
          <SoftTypography fontWeight="bold">Product Taxes</SoftTypography>
        </SoftBox>
        <SoftBox pb={3} px={3}>
          <FormHelperText error={true}>{(typeof errors.taxes == "string") && errors.taxes}</FormHelperText>
          {taxRows.length === 0 ? (
            <SoftBox justifyContent="center" alignItems="center" display="flex" mt={2}>
              <SoftTypography
                variant="text"
                color="secondary"
                size="large"
              >
                No tax added for product
              </SoftTypography>
            </SoftBox>
          ) : (<Grid container spacing={1} md={12}>
            <>{taxRows.map((tax_row, row_index) => (
              <Grid item md={6}>
                <Grid
                  container
                  spacing={1}
                  key={tax_row.id}
                  display="flex"
                  alignItems="flex-start">
                  <Grid item md={5}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Tax
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        inputId={"taxes[" + row_index + "][name]"}
                        defaultValue={taxNameList.find((tax_name) => tax_name.value === tax_row.value)}
                        placeholder="Select Tax"
                        noOptionsMessage={() => "No Tax Configured"}
                        options={taxNameList}
                        onChange={(tax) =>
                          handleTaxRowChange(tax_row.id, "name", tax.value)
                        }
                        onBlur={handleBlur}
                        error={touched.taxes?.[row_index]?.name && Boolean(errors.taxes?.[row_index]?.name)}
                      />
                    </SoftBox>
                    <FormHelperText error={true}>
                      {touched.taxes?.[row_index]?.name && errors.taxes?.[row_index]?.name}
                    </FormHelperText>
                  </Grid>
                  <Grid item md={3}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Rate
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        name={"taxes[" + row_index + "][rate]"}
                        value={tax_row.rate}
                        onChange={(e) => handleTaxRowChange(tax_row.id, "rate", e.target.value)}
                        onBlur={handleBlur}
                        error={touched.taxes?.[row_index]?.rate && Boolean(errors.taxes?.[row_index]?.rate)}
                      ></SoftInput>
                    </SoftBox>
                    <FormHelperText error={true}>
                      {touched.taxes?.[row_index]?.rate && errors.taxes?.[row_index]?.rate}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                      mt={6}
                    >
                      <SoftButton
                        variant="outlined"
                        color={"error"}
                        iconOnly
                        onClick={() => handleDeleteTaxRow(tax_row.id)}
                      >
                        <Icon>delete</Icon>
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            ))}</>
          </Grid>)}
          <Grid
            container
            spacing={3}
            mt={5}
            display="flex"
            justifyContent="center"
          >
            <Grid item xs={12} sm={4}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftButton
                  variant="gradient"
                  color="secondary"
                  onClick={handleAddTaxRow}
                >
                  <Icon>add</Icon>&nbsp; {taxRows.length === 0 ? "Add taxes" : "Add more taxes"}
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox justifyContent="center" alignItems="center" display={hideSubmitButton ? "none" : "flex"} mt={2}>
          <SoftButton onClick={handleSubmit} variant="contained" color="success">
            Submit
          </SoftButton>
        </SoftBox>
      </form>
    </SoftBox>
  );
}
