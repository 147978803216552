/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// Data
import selectData from "./data/selectData";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";

function BasicInfo() {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftBox height="100%" mt={0.5} lineHeight={1}>
          <SoftTypography variant="h5" fontWeight="medium">
            CustTestCustomeromer 1
          </SoftTypography>
          <SoftTypography variant="button" color="text" fontWeight="medium">
            27AADCB2230M1ZT

            <Link to="/" style={{ marginLeft: '10px' }}>
              <SoftTypography
                variant="body2"
                color="info"
                textTransform="capitalize"
                component="span"
              >
                Business name
              </SoftTypography>
            </Link>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Select Section
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect placeholder="2023-24" options={selectData.financialYear} />
                </SoftBox>
              </Grid>

              <Grid item xs={12} sm={3}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      &nbsp;
                    </SoftTypography>
                  </SoftBox>
                  <SoftButton variant="contained" color="info">
                    GSTR1 JSON
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >

                    </SoftTypography>
                  </SoftBox>
                  <SoftButton variant="outlined" color="dark">
                    View Summary
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >

                    </SoftTypography>
                  </SoftBox>
                  <SoftButton variant="outlined" color="dark">
                    Sales Report
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
