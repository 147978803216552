import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalhide: false,
  count: 10,
  toasterColor: "error",
  toasterMessage: "message here...",
  toasterToggle: false,
  selectedBusinessID: null,
  selectedBusiness: {},
  businessesList: [],
  forEditselectedBusinessID: "-1",
  GSTloggedIn: false,
  toasterTitle: "Something went wrong",
  errors: {},
  GSTAccessToken: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    userModalToggle(state, action) {
      console.log("action in reducer", action);
      state.modalhide = action.payload.modal;
      state.count = action.payload.countabc;
    },
    toasterShow(state, action) {
      state.toasterTitle = action.payload.toasterTitle;
      state.toasterToggle = action.payload.toasterToggle;
      state.toasterMessage = action.payload.toasterMessage;
      state.toasterColor = action.payload.toasterColor;
    },
    setSelectedBusinessInfo(state, action) {
      console.log("action in reducer", state, action);
      state.selectedBusinessID = action.payload.selectedBusinessID;
      state.businessesList = action.payload.businessesList;
      state.selectedBusiness = action.payload.selectedBusiness;
      state.GSTloggedIn = action.payload.GSTloggedIn;
      state.GSTAccessToken = action.payload.GSTAccessToken;
    },
    forEditselectedBusFun(state, action) {
      state.forEditselectedBusinessID =
        action.payload.forEditselectedBusinessID;
    },
  },
});

export default userSlice.reducer;
export const { userModalToggle } = userSlice.actions;
export const { setErrors, toasterShow, setSelectedBusinessInfo, forEditselectedBusFun } =
  userSlice.actions;
