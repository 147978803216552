import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SoftButton from "components/SoftButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SoftInput from "components/SoftInput";
// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// NewProduct page components
import { useFormik } from 'formik';
import SwitchControl from "./SwitchControl";
import { Link } from 'react-router-dom';
import Label from '../common/commonlabel';
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from 'formik';


const addNewCustomerSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("please enter the  name"),
    email: Yup.string().email().required("please enter your email"),
    phone: Yup.number().required("please enter your phone number"),
    gstin: Yup.number().required("please enter your gstin number"),
    businessName: Yup.string().min(2).max(25).required("please enter your business name"),
    debitAmount: Yup.number().required("please enter your debit amount"),
    balanceAmount: Yup.number().required("please enter your balance amount"),
});

const initialValues = {
    name: "",
    email: "",
    phone: "",
    gstin: "",
    businessName: "",
    debitAmount: "",
    balanceAmount: ""
}
export default function AddNewCustomer(props) {
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [openingBalance, setOpeningBalance] = useState("debit")
    const [tds, setTds] = useState(false);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: addNewCustomerSchema,
        onSubmit: (values, action) => {
            console.log("values", values);
            action.resetForm();
        }
    });



    const handleChangeRadio = (event) => {
        setOpeningBalance(event.target.value);
    };

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <>
            <SoftButton size="small" onClick={handleClickOpen('paper')} variant={props.variant == "text" ? "text" : "contained"} color="info">Add New Customer</SoftButton>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                scroll={"paper"}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Add Customer
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <form onSubmit={handleSubmit} className='formp'>
                    <DialogContent dividers={scroll === 'paper'}>
                        <SoftTypography variant="h5">Basic Details</SoftTypography>
                        <SoftBox
                            bgColor="light"
                            py={1.875}
                            px={1.875}
                            borderRadius="sm"
                            mt={1}
                        >

                            <SoftBox>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Label label="name" />
                                        <SoftInput
                                            id="name"
                                            label="name"
                                            name="name"
                                            variant="filled"
                                            className='w-100'
                                            margin="normal"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter Name"
                                            type="text"
                                            error={(errors.name && touched.name) ? errors.name : false}
                                            helperText={(errors.name && touched.name) ? errors.name : null}
                                        />
                                        <p>
                                            {errors.name && touched.name && errors.name}
                                        </p>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                            <SoftBox mt={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Label label="phone" />
                                        <SoftInput
                                            id="phone"
                                            label="phone"
                                            name="phone"
                                            variant="filled"
                                            className='w-100'
                                            margin="normal"
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter Mobile Number"
                                            type="number"
                                            error={(errors.phone && touched.phone) ? errors.phone : false}
                                            helperText={(errors.phone && touched.phone) ? errors.phone : null}
                                        />
                                        <p>
                                            {errors.phone && touched.phone && errors.phone}
                                        </p>

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Label label="Email" />
                                        <SoftInput
                                            id="email"
                                            label="email"
                                            name="email"
                                            variant="filled"
                                            className='w-100'
                                            margin="normal"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter email address"
                                            type="email"
                                            error={(errors.email && touched.email) ? errors.email : false}
                                            helperText={(errors.email && touched.email) ? errors.email : null}
                                        />
                                        <p>
                                            {errors.email && touched.email && errors.email}
                                        </p>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>

                        <SoftTypography variant="h5" mt={3}>Company Details(Optional)</SoftTypography>
                        <SoftBox
                            bgColor="light"
                            py={1.875}
                            px={1.875}
                            borderRadius="sm"
                            mt={1}
                        >

                            <SoftBox>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Label label="GSTIN" />
                                        <div className='gst-fetch'>
                                            <SoftInput
                                                id="gstin"
                                                label="GSTIN"
                                                name="gstin"
                                                variant="filled"
                                                className='w-100'
                                                margin="normal"
                                                value={values.gstin}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="GSTIN"
                                                type="number"
                                                error={(errors.gstin && touched.gstin) ? errors.gstin : false}
                                                helperText={(errors.gstin && touched.gstin) ? errors.gstin : null}
                                            />
                                            <button type='button'>Fetch Details</button>
                                        </div>
                                        <p>
                                            {errors.gstin && touched.gstin && errors.gstin}
                                        </p>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Label label="Bussiness Name" />
                                        <SoftInput
                                            id="businessName"
                                            label="Bussiness Name"
                                            name="businessName"
                                            variant="filled"
                                            className='w-100'
                                            margin="normal"
                                            value={values.businessName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Bussiness Name"
                                            type="text"
                                            error={(errors.businessName && touched.businessName) ? errors.businessName : false}
                                            helperText={(errors.businessName && touched.businessName) ? errors.businessName : null}
                                        />
                                        <p>
                                            {errors.businessName && touched.businessName && errors.businessName}
                                        </p>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>




                        <SoftBox
                            bgColor="white"
                            py={1.875}
                            px={1.875}
                            borderRadius="sm"
                            mt="2"
                        >
                            <SoftBox mt={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <SoftTypography variant="h5" py={1}>Billing Address</SoftTypography>
                                        <SoftButton color="info" variant="contained" mt={1}>
                                            <AddCircleOutlineIcon />
                                            Add Billing Address
                                        </SoftButton>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SoftTypography variant="h5" py={1}>Shipping Address</SoftTypography>
                                        <SoftButton color="info" variant="contained" mt={1}>
                                            <AddCircleOutlineIcon />
                                            Add Shipping Address
                                        </SoftButton>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>






                        <SoftTypography variant="h5" mt={2}>Optional Details</SoftTypography>

                        <SoftBox
                            bgColor="light"
                            py={1.875}
                            px={1.875}
                            borderRadius="sm"
                            mt={1}
                            className="add-customer-optional"
                        >
                            <SoftBox>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} className='opening-balance'>
                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group" color="text" py={4}>Opening Balance</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                row
                                                value={openingBalance}
                                                onChange={handleChangeRadio}
                                                mt={1}
                                                className='add-customer-radiogroup'
                                            >
                                                <FormControlLabel value="debit" control={<Radio style={{ backgroundColor: "#000000" }} />} label="Debit" />
                                                <FormControlLabel value="credit" control={<Radio style={{ backgroundColor: "#000000" }} />} label="Credit" />
                                            </RadioGroup>
                                        </FormControl>

                                        <SoftTypography variant="p" fontSize={'12px'} className="select-credit">
                                            If you select Credit: text will be
                                            <Link to="/">
                                                "You pay the customer"
                                            </Link>
                                        </SoftTypography>


                                    </Grid>

                                </Grid>

                                {
                                    openingBalance == "debit" && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <div className='customer-pays-you'>
                                                    <Label label="Enter debit amount" />
                                                    <SoftInput
                                                        id="debitAmount"
                                                        label="Enter debit amount"
                                                        name="debitAmount"
                                                        variant="filled"
                                                        className='w-100'
                                                        margin="normal"
                                                        value={values.debitAmount}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Enter debit amount"
                                                        error={(errors.debitAmount && touched.debitAmount) ? errors.debitAmount : false}
                                                        helperText={(errors.debitAmount && touched.debitAmount) ? errors.debitAmount : null}
                                                    />
                                                    <div className='cus-pay'>Customer Pays You</div>
                                                </div>
                                                <p>
                                                    {errors.debitAmount && touched.debitAmount && errors.debitAmount}
                                                </p>


                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Label label="Balance" />
                                                <SoftInput
                                                    id="balanceAmount"
                                                    label="Balance"
                                                    name="balanceAmount"
                                                    variant="filled"
                                                    className='w-100'
                                                    margin="normal"
                                                    value={values.balanceAmount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Balance"
                                                    error={(errors.balanceAmount && touched.balanceAmount) ? errors.balanceAmount : false}
                                                    helperText={(errors.balanceAmount && touched.balanceAmount) ? errors.balanceAmount : null}
                                                />
                                                <p>
                                                    {errors.balanceAmount && touched.balanceAmount && errors.balanceAmount}
                                                </p>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <div style={{ margin: '16px 0', width: '100%' }}>
                                                    <SwitchControl label="TDS" />
                                                </div>
                                                <div className="slect-input-group" mt={2}>
                                                    <SoftSelect className="custom-selectbox"
                                                        defaultValue={{ value: "clothing", label: "TDS Prcentage" }}
                                                        options={[
                                                            { value: "clothing", label: "Clothing" },
                                                            { value: "electronics", label: "Electronics" },
                                                            { value: "furniture", label: "Furniture" },
                                                            { value: "others", label: "Others" },
                                                            { value: "real estate", label: "Real Estate" },
                                                        ]}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </SoftBox>
                        </SoftBox>



                    </DialogContent>
                    <DialogActions>
                        <SoftButton onClick={handleClose} variant="outlined" color="info">Cancel</SoftButton>
                        <SoftButton type="submit" variant="contained" color="info">Save</SoftButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}