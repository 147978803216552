import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Custom styles for the SidenavCard
import {
  card,
  cardContent,
  cardIconBox,
  cardIcon,
} from "examples/Sidenav/styles/sidenavCard";
// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

const SidenavCard = () => {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  const card = (theme, { miniSidenav }) => ({
    position: "fixed",
    bottom: 0,
    marginBottom: "2rem",
  });

  return (
    <Card sx={(theme) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
        {miniSidenav ? (
          <>
            <SoftBox
              bgColor="white"
              width="2rem"
              height="2rem"
              borderRadius="md"
              shadow="md"
              sx={cardIconBox}
            >
              <Icon
                fontSize="medium"
                sx={(theme) => cardIcon(theme, { sidenavColor })}
              >
                call
              </Icon>
            </SoftBox>
            <SoftTypography variant="button" color="dark">
              help?
            </SoftTypography>
          </>
        ) : (
          <>
            <SoftBox
              bgColor="white"
              width="2rem"
              height="2rem"
              borderRadius="md"
              shadow="md"
              mb={2}
              sx={cardIconBox}
            >
              <Icon
                fontSize="medium"
                sx={(theme) => cardIcon(theme, { sidenavColor })}
              >
                help
              </Icon>
            </SoftBox>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h6" color="dark">
                Need help?
              </SoftTypography>
              <SoftBox mb={1.825} mt={-1}>
                <SoftTypography
                  variant="caption"
                  color="dark"
                  fontWeight="medium"
                >
                  Please call our customer support
                </SoftTypography>
              </SoftBox>
              <SoftButton
                component={Link}
                href="tel:+918567075678"
                target="_blank"
                rel="noreferrer"
                size="small"
                color="white"
                fullWidth
              >
                +91 8567075678
              </SoftButton>
            </SoftBox>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default SidenavCard;
