import { makeRequest } from "api";

export const invoiceListFetch = async (business_id, params = {}) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  };
  return makeRequest(`api/v1/invoices/?business=${business_id}`, options);
};

export const createNewInvoice = async function (payload) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };

  return await makeRequest("api/v1/invoices/", options);
};

export const fetchInvoiceDetails = async (business_id, invoice_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(`api/v1/invoices/${invoice_id}/?business=${business_id}`, options);
};

export const fetchInvoiceDetailsUsingInvoiceNumber = async (business_id, invoice_number) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(`api/v1/invoices/invoice_number/${invoice_number}/?business=${business_id}`, options);
};

export const fetchInvoiceFiltersConfig = async (business_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      business: business_id,
    }
  };
  return makeRequest(`api/v1/invoices/filters/config`, options);
};
