import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CardConrol from "./cardControl";
import { Link } from "react-router-dom";

const FileReturn = (props) => {
  const { data } = props;
  return (
    <>
      {Object.keys(data).map((key) => (
        <SoftBox>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={12}>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {key}
                </SoftTypography>
              </SoftBox>
            </Grid>

            {data[key].map((item, index) => (
              <Grid item xs={12} md={3}>
                {key === "GSTR1" ? (
                  <Link to={`/gst/returns/summary/${key}/${item.ret_prd}`}>
                    <CardConrol
                      label1={`Return Period: ${item.ret_prd_frmtd}`}
                      label2={`Date of Filing: ${item.dof}`}
                      label3={`Return Status: ${item.status}`}
                      iconname="check_circle"
                      filed={item.status === "Filed" ? true : false}
                      has_link={true}
                    />
                  </Link>
                ) : (
                  <CardConrol
                    label1={`Return Period: ${item.ret_prd_frmtd}`}
                    label2={`Date of Filing: ${item.dof}`}
                    label3={`Return Status: ${item.status}`}
                    iconname="check_circle"
                    filed={item.status === "Filed" ? true : false}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </SoftBox>
      ))}
    </>
  );
};

export default FileReturn;
