import * as Yup from "yup";
import checkout from "../Schemas";

const {
  formField: { GSTINNumber, GSTINUsername , OTP },
} = checkout;

const validations = [
  Yup.object().shape({
    [GSTINNumber.name]: Yup.string().required(GSTINNumber.errorMsg),
    [GSTINUsername.name]: Yup.string().required(GSTINUsername.errorMsg),
  }),
  Yup.object().shape({
    [OTP.name]: Yup.string().required(OTP.errorMsg),
  }),
];

export default validations;
