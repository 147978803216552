import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftButton from "components/SoftButton";

function SoftDialog(props) {
  const { component: Component, title, handleClose, open, maxWidth="md", closeButtonText="Close", handleSubmit, submitButtonText="Submit", submitButtonColor="success" } = props;

  return (
    <SoftBox>
      <Dialog open={open} onClose={handleClose} fullWidth scroll="paper" maxWidth={maxWidth}>
        <DialogTitle>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={2}
            px={2}
          >
            <SoftTypography variant="h6" fontWeight="medium">
              {title}
            </SoftTypography>
            <IconButton
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleClose}
            >
              <Icon>close</Icon>
            </IconButton>
          </SoftBox>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ maxHeight: "500px", overflow: "auto" }}>
          {Component}
        </DialogContent>
        <DialogActions>
          <SoftButton variant="outlined" color="success" onClick={handleClose}>
            {closeButtonText}
          </SoftButton>
          {handleSubmit ? 
            <SoftButton color={submitButtonColor} onClick={handleSubmit}>
              {submitButtonText}
            </SoftButton>
            : null
          }
        </DialogActions>
      </Dialog>
    </SoftBox>
  );
}

export default SoftDialog;
