import * as Yup from "yup";

export default function setupYup() {
  Yup.addMethod(Yup.array, 'uniqueProperty', function (property, message) {
    return this.test('unique', '', function (list) {
      const errors = [];

      list.forEach((item, index) => {
        const propertyValue = item[property];

        if (propertyValue && list.filter((item2) => item2[property] === propertyValue).length > 1) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${property}`,
              message,
            })
          );
        }
      });

      if (errors.length > 0) {
        throw new Yup.ValidationError(errors);
      }

      return true;
    });
  });
}
