import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SoftButton from "components/SoftButton";
import { useDispatch, useSelector } from "react-redux";
import { forEditselectedBusFun, setSelectedBusinessInfo, toasterShow } from "store/slices/userSlice";
import { businessFetch, deleteBusiness } from "api";
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessInfoCard from "examples/Cards/InfoCards/BusinessInfoCard";
import BusinessesList from "layouts/business/components/ListBusinesses";
import SoftDialog from "components/SoftDialog";
import SoftTypography from "components/SoftTypography";
import BusinessDetails from "layouts/business/components/BusinessDetails";

export default function ChooseBusinessName() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [listBusiness, setListBusiness] = useState([]);
  const [businessExists, setBusinessExists] = useState(false);
  const [editCurrentBusiness, setEditCurrentBusiness] = useState(false);
  const [businessListFetched, setBusinessListFetched] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectedBusinessInfo = useSelector((state) => state.user);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    dispatch(
      setSelectedBusinessInfo({
        ...selectedBusinessInfo,
        businessId: listBusiness[index].id,
      })
    );
  };

  const options = listBusiness.map((business) => ({
    value: business.id,
    label: business.name,
  }));

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );

  const getData = async () => {
    try {
      const response = await businessFetch();
      if (response && response.data.count > 0) {
        setBusinessExists(true);
        const businesses = response.data.results;
        setListBusiness(businesses);
        console.log("businesses", businesses);
        if (!currentBusinessID || businesses.map((business) => business['id']).indexOf(currentBusinessID) === -1) {
          const selectedBusiness = response.data.results[0];
          const GSTloggedIn = selectedBusiness.has_gst_logged_in;
          dispatch(
            setSelectedBusinessInfo({
              selectedBusinessID: selectedBusiness.id,
              selectedBusiness: selectedBusiness,
              businessesList: businesses,
              GSTloggedIn: GSTloggedIn,
            })
          );
        } else {
          const selectedBusiness = businesses.find((business) => business.id === currentBusinessID);
          const GSTloggedIn = selectedBusiness.has_gst_logged_in;
          dispatch(
            setSelectedBusinessInfo({
              selectedBusinessID: selectedBusiness.id,
              selectedBusiness: selectedBusiness,
              businessesList: businesses,
              GSTloggedIn: GSTloggedIn,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
        })
      );
    }
  
    setBusinessListFetched(true);
  };

  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [deleteBusinessOpen, setDeleteBusinessOpen] = useState(false);

  const handleOpenDeleteBusinessModal = async (business) => {
    setBusinessToDelete(business);
    setSelectBusinessOpen(false);
    setDeleteBusinessOpen(true);
  }

  function handleDeleteBusinessCancel() {
    setDeleteBusinessOpen(false);
  }

  const handleDeleteBusiness = async () => {
    try {
      const response = await deleteBusiness(businessToDelete.id);
      if (response && response.success === false) {
        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: response.data.detail,
            toasterToggle: true,
            toasterTitle: "Error deleting business",
          })
        );
      } else {
         dispatch(
          toasterShow({
            toasterColor: "success",
            toasterMessage: "Business deleted successfully",
            toasterToggle: true,
          })
        );

        setDeleteBusinessOpen(false);
        getData();
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
        })
      );
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const [menu, setMenu] = useState(null);

  const openMenu = (event) => {
    setMenu(event.currentTarget);
  };

  const closeMenu = () => setMenu(null);

  //rabi
  const currentBusiness = listBusiness.find(
    (business) => business.id === currentBusinessID
  );

  const buttonText = businessExists
    ? currentBusiness && currentBusiness.name
    : "Add Business";

  const [selectBusinessOpen, setSelectBusinessOpen] = useState(false);

  function handleSelectBusinessClose() {
    setSelectBusinessOpen(false);
  }

  function handleSelectBusinessOpen() {
    if (!selectBusinessOpen) {
      setSelectBusinessOpen(true);
    }
  }

  function handleBusinessClick() {
    handleSelectBusinessClose();
  }
  const editModalOpenCallBack = (event, param) => {
    console.log("currentBusiness.id", param);
    dispatch(
      forEditselectedBusFun({
        forEditselectedBusinessID: param,
      })
    );
    setEditCurrentBusiness(true);
    setSelectBusinessOpen(false);
    setAddBusinessOpen(true);
  };

  const renderSelectBusiness = (
    <>
      <SoftDialog
        component={
          <BusinessesList
            data={listBusiness}
            onClick={handleBusinessClick}
            currentBusiness={currentBusiness}
            editModalOpenCallBack={editModalOpenCallBack}
            handleDeleteBusiness={handleOpenDeleteBusinessModal}
          />
        }
        title="Select a business"
        handleOpen={handleSelectBusinessOpen}
        handleClose={handleSelectBusinessClose}
        open={selectBusinessOpen}
      />
    </>
  );

  const renderDeleteBusinessConfirmation = (
      <SoftTypography>
        Are you sure you want to delete business <SoftTypography component="span" color="error">"{businessToDelete?.name}"</SoftTypography>? This action cannot be reversed.
      </SoftTypography>
  )

  const renderDeleteBusiness = (
    <SoftDialog
      component={
        renderDeleteBusinessConfirmation
      }
      title="Confirm Business Delete"
      submitButtonText="Delete"
      submitButtonColor="error"
      handleSubmit={handleDeleteBusiness}
      closeButtonText="Cancel"
      handleClose={handleDeleteBusinessCancel}
      open={deleteBusinessOpen}
      maxWidth="sm"
    />
  )

  const [addBusinessOpen, setAddBusinessOpen] = useState(false);

  function handleAddBusinessClose() {
    setAddBusinessOpen(false);
    getData();
  }

  function handleAddBusinessOpen() {
    if (!addBusinessOpen) {
      setEditCurrentBusiness(false);
      setAddBusinessOpen(true);
    }
  }

  function handleAddBusinessClick() {
    handleAddBusinessClose();
  }

  const renderAddBusiness = (
    <>
      <SoftDialog
        component={
          <BusinessDetails
            data={listBusiness}
            onClick={handleAddBusinessClick}
            handleDialogClose={handleAddBusinessClose}
            loadEditData={editCurrentBusiness}
          />
        }
        title={editCurrentBusiness ? "Edit a Business" : "Add a business"}
        handleOpen={handleAddBusinessOpen}
        handleClose={handleAddBusinessClose}
        open={addBusinessOpen || (businessListFetched && !businessExists)}
        maxWidth="lg"
      />
    </>
  );

  const renderMenu = (currentBusiness) => (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      sx={{ mt: 1 }}
      keepMounted
    >
      {currentBusiness && (
        <BusinessInfoCard
          title={currentBusiness.name}
          address={currentBusiness.email} // this part will be used for address
          businessID={currentBusiness.id}
        />
      )}
      <MenuItem
        sx={{ mt: 2 }}
        onClick={(event) => editModalOpenCallBack(event, currentBusiness.id)}
      >
        <ListItemIcon>
          <Icon fontSize="12px">edit</Icon>
        </ListItemIcon>
        <ListItemText>
          <SoftTypography variant="button" color="text">
            Edit Business
          </SoftTypography>
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleSelectBusinessOpen}>
        <ListItemIcon>
          <Icon fontSize="12px">unfold_more</Icon>
        </ListItemIcon>
        <ListItemText>
          <SoftTypography variant="button" color="text">
            Switch Business
          </SoftTypography>
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleAddBusinessOpen}>
        <ListItemIcon>
          <Icon fontSize="12px">add</Icon>
        </ListItemIcon>
        <ListItemText>
          <SoftTypography variant="button" color="text">
            Add New Business
          </SoftTypography>
        </ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <SoftBox display="flex" justifyContent="flex-end" ml={2}>
      <SoftButton
        variant="outlined"
        color="dark"
        onClick={businessExists ? openMenu : handleAddBusinessOpen}
      >
        {renderSelectBusiness}
        {renderAddBusiness}
        {renderDeleteBusiness}

        {businessExists ? (
          <>
            {buttonText}&nbsp;
            <Icon>expand_more</Icon>
          </>
        ) : (
          <>
            <Icon>add_business</Icon>&nbsp; &nbsp;
            {buttonText}
          </>
        )}
      </SoftButton>
      {renderMenu(currentBusiness)}
    </SoftBox>
  );
}
