import { makeRequest } from "api";

export const creditNotesListFetch = async (business_id, params = {}) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  };
  return makeRequest(`api/v1/credit-notes/?business=${business_id}`, options);
};

export const createNewCreditNote = async function (payload) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
    params: {
      business: payload.business,
      invoice_number: payload.invoice_number,
    }
  };

  return await makeRequest("api/v1/credit-notes/", options);
};

export const fetchCreditNoteDetails = async (business_id, credit_note_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(`api/v1/credit-notes/${credit_note_id}/?business=${business_id}`, options);
};

export const fetchCreditNoteFiltersConfig = async (business_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      business: business_id,
    }
  };
  return makeRequest(`api/v1/credit-notes/filters/config`, options);
};
