import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CreateNewProduct from "layouts/invoices/invoice/components/CreateInvoice/AddProducts/CreateNewProduct";
import Header from "layouts/invoices/invoice/components/CreateInvoice/Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


function CreateProduct() {
  const [submitClicked, setSubmitClicked] = useState(0);
  const navigate = useNavigate();

  const handleSubmit = () => {
    setSubmitClicked(submitClicked + 1)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox component="form" mt={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item lg={11}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header handleSubmit={handleSubmit} />
                </Grid>
                <Grid item xs={12}>
                  <Card id="create-product-card" sx={{ overflow: "visible" }}>
                    <SoftBox mt={3}>
                      <CreateNewProduct
                        hideSubmitButton={true}
                        submitClicked={submitClicked}
                        handleDialogClose={() => {
                          setSubmitClicked(0);
                          navigate('/products')
                        }}
                      />
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Header handleSubmit={handleSubmit} />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default CreateProduct;
