import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { loaderShow } from "./../store/slices/commonUISlice";

export default function Loader({open}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(loaderShow({
      loader: false
    }))
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="info" />
    </Backdrop>
  );
}
