import { makeRequest } from "api";

export const bankAccountListFetch = async (business_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      business: business_id,
    }
  };
  return makeRequest("api/v1/bank_accounts/", options);
};

export const addBankAccount = async function (payload, business_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      business: business_id,
    },
    data: payload,
  };
  const data = await makeRequest("api/v1/bank_accounts/", options);
  return data;
};
