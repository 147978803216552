import { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import FormHelperText from "@mui/material/FormHelperText";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftDialog from "components/SoftDialog";
import CreateNewProduct from "./CreateNewProduct";


function AddProducts(props) {
  const { products, reloadProducts, values = {}, setFieldValue, handleBlur, touched, errors } = props;

  const productsList = products.map((product) => ({
    label: product.name,
    value: product.id,
    taxes: product.taxes,
  }));

  const [rows, _setRows] = useState([
    { id: 1, product: "", quantity: "", unit_price: "", tax: 0, totalPrice: 0 }
  ]);
  const [rowsIndex, setRowsIndex] = useState(rows.length);

  useEffect(() => {
    if (values.items) {
      const _rows = values.items.map((row, index) => {
        let taxes = productsList.find((product) => product.value === row.product)?.taxes || [];
        row.tax = taxes.reduce((partialSum, tax) => partialSum + row.quantity * row.unit_price * tax.rate / 100, 0) || 0;
        row.totalPrice = (row.quantity * row.unit_price + row.tax) || 0;
        row.id = index + 1;

        return row;
      })

      _setRows(_rows);
      setRowsIndex(_rows.length)
    }
  }, [values.items])

  const setRows = (_rows) => {
    _rows = _rows.map((row) => {
      let taxes = productsList.find((product) => product.value === row.product)?.taxes || [];
      row.tax = taxes.reduce((partialSum, tax) => partialSum + row.quantity * row.unit_price * tax.rate / 100, 0) || 0;
      row.totalPrice = (row.quantity * row.unit_price + row.tax) || 0;

      return row;
    })

    _setRows(_rows);
    setFieldValue("items", _rows.map((row) => ({
      product: row.product,
      quantity: row.quantity,
      unit_price: row.unit_price,
    })));
  }

  useEffect(() => {
    setRows(rows)
  }, []);

  const handleAddRow = () => {
    const newRow = { id: rowsIndex + 1, product: "", quantity: "", unit_price: "", tax: 0, totalPrice: 0 };
    setRows([...rows, newRow]);
    setRowsIndex(rowsIndex + 1)
  };

  const handleDeleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };


  const handleRowChange = (id, field, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const [createProductOpen, setCreateProductOpen] = useState(false);

  function handleCreateProductOpen() {
    if (!createProductOpen) {
      setCreateProductOpen(true);
    }
  }

  function handleCreateProductClose() {
    setCreateProductOpen(false);
    reloadProducts();
  }

  const renderCreateProduct = (
    <>
      <SoftDialog
        component={
          <CreateNewProduct
            handleDialogClose={handleCreateProductClose}
            loadEditData={false}
            addBuyerAPI={true}
          />
        }
        title={"Create a product"}
        handleOpen={handleCreateProductOpen}
        handleClose={handleCreateProductClose}
        open={createProductOpen}
        maxWidth="lg"
      />
    </>
  );

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <Grid container>
        <Grid item md={9}>
          <SoftBox p={3}>
            <SoftTypography variant="h5">Add Products</SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item md={3} pt={2}>
          <SoftBox
            display="flex"
            justifyContent={{ md: "flex-end" }}
            mr={2}
          >
            <SoftButton
              variant="gradient"
              color="secondary"
              onClick={handleCreateProductOpen}
            >
              <Icon>add</Icon>&nbsp; Create Product
              {renderCreateProduct}
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>
      <SoftBox pb={3} px={3}>
        <FormHelperText error={true}>{(typeof errors.items == "string") && errors.items}</FormHelperText>
        {rows.map((row, row_index) => (
          <Grid container spacing={3} key={row.id}>
            <Grid item xs={12} sm={4}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Product
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  inputId={"items[" + row_index + "][product]"}
                  defaultValue={productsList.find((product) => product.value === row.product)}
                  placeholder="Select Product"
                  noOptionsMessage={() => "No Products"}
                  options={productsList}
                  value={productsList.filter((option) => option.value === values.items?.[row_index]?.product)?.[0]}
                  onChange={(product) =>
                    handleRowChange(row.id, "product", product.value)
                  }
                  onBlur={handleBlur}
                  error={touched.items?.[row_index]?.product && Boolean(errors.items?.[row_index]?.product)}
                />
              </SoftBox>
              <FormHelperText error={true}>
                {touched.items?.[row_index]?.product && errors.items?.[row_index]?.product}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={2}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Unit Price
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name={"items[" + row_index + "][unit_price]"}
                  value={row.unit_price}
                  icon={{
                    component: <Icon>currency_rupee</Icon>,
                    direction: "left",
                  }}
                  onChange={(e) => handleRowChange(row.id, "unit_price", e.target.value)}
                  onBlur={handleBlur}
                  error={touched.items?.[row_index]?.unit_price && Boolean(errors.items?.[row_index]?.unit_price)}
                ></SoftInput>
              </SoftBox>
              <FormHelperText error={true}>
                {touched.items?.[row_index]?.unit_price && errors.items?.[row_index]?.unit_price}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={2}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Quantity
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name={"items[" + row_index + "][quantity]"}
                  value={row.quantity}
                  onChange={(e) => handleRowChange(row.id, "quantity", e.target.value)}
                  onBlur={handleBlur}
                  error={touched.items?.[row_index]?.quantity && Boolean(errors.items?.[row_index]?.quantity)}
                ></SoftInput>
              </SoftBox>
              <FormHelperText error={true}>
                {touched.items?.[row_index]?.quantity && errors.items?.[row_index]?.quantity}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={1.5}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Tax
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  disabled
                  icon={{
                    component: <Icon>currency_rupee</Icon>,
                    direction: "left",
                  }}
                  value={row.tax}
                ></SoftInput>
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={1.5}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Total Price
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  disabled
                  icon={{
                    component: <Icon>currency_rupee</Icon>,
                    direction: "left",
                  }}
                  value={row.totalPrice}
                ></SoftInput>
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={1}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftButton
                  variant="outlined"
                  color={row_index === 0 && rows.length === 1 ? "secondary" : "error"}
                  iconOnly
                  onClick={() => handleDeleteRow(row.id)}
                  disabled={row_index === 0 && rows.length === 1}
                >
                  <Icon>delete</Icon>
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        ))}

        <Grid
          container
          spacing={3}
          mt={5}
          display="flex"
          justifyContent="center"
        >
          <Grid item xs={12} sm={4}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SoftButton
                variant="gradient"
                color="secondary"
                onClick={handleAddRow}
              >
                <Icon>add</Icon>&nbsp; Add more items
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default AddProducts;
