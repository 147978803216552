import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader:false
}

const commonUISlice = createSlice({ 
  name: "commonUI",
  initialState, 
  reducers: {
    loaderShow(state,action) {
      state.loader=action.payload.loader;
    },
  },
});

export default commonUISlice.reducer;
console.log("commonUISlice.actions", commonUISlice.actions)
export const { loaderShow} = commonUISlice.actions;




