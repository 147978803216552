import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FileReturn from "./components/BasicInfo/fileReturnTab";
import { GSTR2AB2BSummary } from "api/Business/GSTAuthentication";
import { toasterShow } from "store/slices/userSlice";
import { setSelectedBusinessInfo } from "store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "common/loader";
import SoftSelect from "components/SoftSelect";
import selectData from "common/DatetimeData";
import SoftButton from "components/SoftButton";

function Settings() {
  const dispatch = useDispatch();
  const businessId = useSelector((state) => state.user.selectedBusinessID);
  const [loading, setLoading] = useState(true);
  const selectedBusinessInfo = useSelector((state) => state.user);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(
    selectData.financialYear.currentYearObj
  );
  const [selectedQuarter, setSelectedQuarter] = useState(selectData.quarter.currentQuarterObj);
  const [selectedMonth, setSelectedMonth] = useState(selectData.month.currentMonthObj);

  const [quarterMonths, setQuarterMonths] = useState([]);

  useEffect(() => {
    updateQuarterMonths();
  }, [selectedQuarter]);

  const updateQuarterMonths = () => {
    const startMonthValue = selectedQuarter.startMonthValue;
    const endMonthValue = selectedQuarter.endMonthValue;
    const filteredMonths = selectData.month.months.filter(
      (month) => month.value >= startMonthValue && month.value <= endMonthValue
    );
    setQuarterMonths(filteredMonths);
    setSelectedMonth(filteredMonths[0]);
  };

  const [returnsData, setReturnsData] = useState([]);

  const navigate = useNavigate();

  const currentBusiness = useSelector((state) => state.user.selectedBusiness);

  const getGSTR2AB2BSummary = useCallback(
    async (month, year) => {
      const formData = {
        businessId: businessId,
        month: parseInt(month.value),
        year: parseInt(year.value),
      };
      try {
        const response = await GSTR2AB2BSummary(formData);
        console.log(response);
        if (response.success === true) {
          setReturnsData(response.data.data);
        } else if (response.data.error_cd === "AUTH158" || response.data.error_cd === "RET11402") {
          // set isGstLoggedIn to false
          dispatch(
            setSelectedBusinessInfo({
              ...selectedBusinessInfo,
              GSTloggedIn: false,
            })
          );

          dispatch(
            toasterShow({
              toasterColor: "error",
              toasterMessage: "Please login to GST portal again",
              toasterToggle: true,
              toasterTitle: "Auth token expired",
            })
          );
          navigate("/gst/login");
        } else {
          dispatch(
            toasterShow({
              toasterColor: "error",
              toasterMessage: response.data.message,
              toasterToggle: true,
              toasterTitle: "Error getting data from GSP",
            })
          );
        }
      } catch (error) {
        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: error.message,
            toasterToggle: true,
            toasterTitle: "Error getting data from GSP",
          })
        );
      }
      setLoading(false);
    },
    [businessId, selectedFinancialYear, dispatch, selectedBusinessInfo, navigate]
  );

  useEffect(() => {
    getGSTR2AB2BSummary(selectedMonth, selectedFinancialYear);
  }, []);

  const handleButtonClick = () => {
    setLoading(true);
    getGSTR2AB2BSummary(selectedMonth, selectedFinancialYear);
  };

  const setFinancialYear = (year) => {
    setSelectedFinancialYear(year);
  };

  const setQuarter = (quarter) => {
    setSelectedQuarter(quarter);
    updateQuarterMonths();
  };

  const setMonth = (month) => {
    setSelectedMonth(month);
  };

  useEffect(() => {
    if (returnsData !== null) {
      setLoading(false);
    }
  }, [returnsData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Loader open={loading} />

      <SoftBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <SoftBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="basic-info" sx={{ overflow: "visible" }}>
                        <Card
                          sx={{
                            backdropFilter: `saturate(200%) blur(30px)`,
                            backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                              rgba(white.main, 0.8),
                            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                            position: "relative",
                            mt: 2,
                            mb: 3,
                            mx: 3,
                            py: 2,
                            px: 2,
                          }}
                        >
                          <Grid container spacing={3} alignItems="center">
                            <Grid item>
                              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                  Business Name
                                </SoftTypography>
                                <SoftTypography variant="h5" color="dark" fontWeight="medium">
                                  {currentBusiness.name}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                            <Grid item>
                              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                  Business GSTIN
                                </SoftTypography>

                                <SoftTypography variant="h5" fontWeight="medium">
                                  {currentBusiness.additional_details.gstin}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </Card>

                        <SoftBox component="form" pb={3} px={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={2}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                      >
                                        Financial Year
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                      placeholder={selectedFinancialYear}
                                      options={selectData.financialYear.years}
                                      value={selectedFinancialYear}
                                      onChange={(event) => setFinancialYear(event)}
                                    />
                                  </SoftBox>
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                      >
                                        Quarter
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                      placeholder={selectedQuarter}
                                      options={selectData.quarter.quarters}
                                      value={selectedQuarter}
                                      onChange={(event) => setQuarter(event)}
                                    />
                                  </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                      >
                                        Month
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                      placeholder={selectedMonth}
                                      options={quarterMonths}
                                      value={selectedMonth}
                                      onChange={(event) => setMonth(event)}
                                    />
                                  </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                      ></SoftTypography>
                                    </SoftBox>
                                    <SoftButton
                                      variant="contained"
                                      color="success"
                                      onClick={handleButtonClick}
                                    >
                                      Search
                                    </SoftButton>
                                  </SoftBox>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </Card>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>

            <SoftBox mt={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Card>
                    <SoftBox p={2}>
                      {returnsData !== undefined && (
                        <FileReturn
                          data={returnsData}
                          month={selectedMonth}
                          year={selectedFinancialYear}
                          businessId={businessId}
                        />
                      )}{" "}
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
