const selectData = {
  gstrType: (() => {
    const gstr = [
      { value: 1, label: "GSTR1" },
      { value: 2, label: "GSTR2" },
      { value: 3, label: "GSTR3" },
      { value: 4, label: "GSTR4" },
    ];
    return gstr;
  })(),
  financialYear: (() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 5; i++) {
      const year = currentYear - i;
      const label = `${year}-${year + 1}`;
      years.unshift({ value: label, label });
    }
    return years;
  })(),
  quarter: (() => {
    const currentMonth = new Date().getMonth() + 1;
    const currentQuarter = Math.ceil(currentMonth / 3);
    const quarters = [
      { value: 1, label: "Quarter 1 (Jan - Mar)", startMonth: "Jan", endMonth: "Mar" },
      { value: 2, label: "Quarter 2 (Apr - Jun)", startMonth: "Apr", endMonth: "Jun" },
      { value: 3, label: "Quarter 3 (Jul - Sep)", startMonth: "Jul", endMonth: "Sep" },
      { value: 4, label: "Quarter 4 (Oct - Dec)", startMonth: "Oct", endMonth: "Dec" },
    ];
    // quarters.unshift({ value: currentQuarter.toString(), label: `Quarter ${currentQuarter} (${quarters[currentQuarter - 1].startMonth} - ${quarters[currentQuarter - 1].endMonth})`, startMonth: quarters[currentQuarter - 1].startMonth, endMonth: quarters[currentQuarter - 1].endMonth });
    return quarters;
  })(),
  month: (() => {
    const months = [
      { value: 1, label: "January" },
      { value: 2, label: "February" },
      { value: 3, label: "March" },
      { value: 4, label: "April" },
      { value: 5, label: "May" },
      { value: 6, label: "June" },
      { value: 7, label: "July" },
      { value: 8, label: "August" },
      { value: 9, label: "September" },
      { value: 10, label: "October" },
      { value: 11, label: "November" },
      { value: 12, label: "December" },
    ];
    // months.unshift({ value: "0", label: "Current Month" });
    return months;
  })(),
};

export default selectData;