import { Add as AddIcon } from "@mui/icons-material";
import Card from "@mui/material/Card";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftDialog from "components/SoftDialog";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import AddTermsAndConditions from "./AddTermsAndConditions";

export default function TermsAndConditions(props) {
    const { termsAndConditions, reloadTermsAndConditions } = props;
    const { values = {}, setFieldValue, handleBlur, touched = {}, errors = {} } = props;
    const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState();
    const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

    const termsAndConditionsList = termsAndConditions.map((tnc) => ({
        label: tnc.title,
        value: tnc.id,
    }));

    function handleTermsAndConditionsOpen() {
        if (!termsAndConditionsOpen) {
            setTermsAndConditionsOpen(true);
        }
    }

    function handleAddTermsAndConditionsClose() {
        setTermsAndConditionsOpen(false);
        reloadTermsAndConditions();
    }

    const handleTermsAndConditionsChange = (option) => {
        if (option) {
            setSelectedTermsAndConditions(termsAndConditions.find((tnc) => tnc.id === option.value));
            setFieldValue("terms_and_conditions", option.value);
        } else {
            setSelectedTermsAndConditions(null);
            setFieldValue("terms_and_conditions", -1);
        }
    };

    useEffect(() => {
        if (values.terms_and_conditions > -1) {
            setSelectedTermsAndConditions(termsAndConditions.find((tnc) => tnc.id === values.terms_and_conditions));
        } else {
            setSelectedTermsAndConditions(null);
        }
    }, [values.terms_and_conditions])

    const renderAddTermsAndConditions = (
        <SoftDialog
            component={
                <AddTermsAndConditions
                    termsAndConditions={termsAndConditions}
                    handleDialogClose={handleAddTermsAndConditionsClose}
                />
            }
            title={"Add Terms And Conditions"}
            handleClose={handleAddTermsAndConditionsClose}
            open={termsAndConditionsOpen}
            maxWidth="lg"
        />
    );

    return (
        <Card id="terms-and-conditions-card" sx={{ overflow: "visible", height: "100%" }}>
            <SoftBox px={3} pt={3}>
                <SoftTypography variant="h5">Terms And Conditions</SoftTypography>
            </SoftBox>
            <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                p={3}
            >
                <SoftBox
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    lineHeight={1}
                >
                    <SoftBox mb={2}>
                        <Grid container>
                            <Grid item md={5} mr={1}>
                                <SoftSelect
                                    inputId="terms_and_conditions"
                                    placeholder="Select Terms And Conditions"
                                    noOptionsMessage={() => "No Terms And Conditions Added"}
                                    isClearable={true}
                                    options={termsAndConditionsList}
                                    mb={1}
                                    value={termsAndConditionsList.filter((option) => option.value === values.terms_and_conditions)?.[0]}
                                    onChange={handleTermsAndConditionsChange}
                                    onBlur={handleBlur}
                                    error={touched.terms_and_conditions && Boolean(errors.terms_and_conditions)}
                                />
                            </Grid>
                            <Grid item>
                                <SoftButton
                                    variant="gradient"
                                    color="secondary"
                                    onClick={handleTermsAndConditionsOpen}
                                >
                                    <AddIcon />&nbsp; Add Terms And Conditions
                                    {renderAddTermsAndConditions}
                                </SoftButton>
                            </Grid>
                        </Grid>
                        <FormHelperText error={true}>{touched.terms_and_conditions && errors.terms_and_conditions}</FormHelperText>
                    </SoftBox>
                    {selectedTermsAndConditions ?
                        <>
                            {selectedTermsAndConditions?.content.split("\n").map((tnc, index) => (
                                <SoftTypography variant="button" fontWeight="regular">
                                    {index + 1}. {tnc}
                                </SoftTypography>
                            ))}
                        </> : null
                    }
                </SoftBox>
            </SoftBox>
        </Card>
    );
}
