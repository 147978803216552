// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Shop from "examples/Icons/Shop";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedBusinessInfo } from "store/slices/userSlice";

function BusinessInfoCard({ title, address, businessID }) {
  const dispatch = useDispatch();

  const selectedBusinessInfo = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      setSelectedBusinessInfo({
        ...selectedBusinessInfo,
        selectedBusinessID: businessID,
      })
    );
  }, [businessID]);
  console.log("BusinessInfoCard", businessID);
  return (
    <Card
      sx={({
        palette: { dark },
        functions: { rgba, linearGradient },
        borders: { borderRadius },
      }) => ({
        backgroundColor: "#e9ecef",
        borderRadius: borderRadius.lg,
        transform: `perspective(999px) rotateX(0deg) translateZ(0)`,
        transformOrigin: "50% 0",
        transition: "200ms ease-out",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",

        "&:hover, &:focus": {
          transform: `perspective(999px) rotateX(7deg) translate3d(0, -4px, 5px)`,
        },
        maxWidth: "20rem",
      })}
      m={2}
    >
      <SoftBox
        p={2}
        mx={3}
        display="flex"
        justifyContent="left"
        flexDirection="row"
        alignItems="center"
      >
        <SoftBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor="success"
          color="white"
          width="3rem"
          height="3rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
          sx={{ flexShrink: 0 }}
        >
          <Shop size="30px" />
        </SoftBox>
        <SoftBox ml={2} display="flex" alignItems="center">
          <SoftTypography variant="h7" textTransform="capitalize">
            {title}
          </SoftTypography>
        </SoftBox>
      </SoftBox>

      <SoftBox pb={2} px={2} textAlign="center" lineHeight={0.25}>
        {address && (
          <SoftTypography variant="caption" color="text" fontWeight="regular">
            {address}
          </SoftTypography>
        )}
        {address && !businessID ? null : <Divider />}
        {businessID && (
          <SoftTypography variant="button" fontWeight="medium">
            Business ID: {businessID}
          </SoftTypography>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
BusinessInfoCard.defaultProps = {
  color: "info",
  businessID: "",
  address: "",
};

// Typechecking props for the DefaultInfoCard
BusinessInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  businessID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BusinessInfoCard;
