import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SoftButton from "components/SoftButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SoftInput from "components/SoftInput";
// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// NewProduct page components
import FormField from "layouts/ecommerce/products.bak/new-product/components/FormField";
import SwitchControl from "./SwitchControl";
import { Link } from 'react-router-dom';
import UploadImage from './uploadImage';
import Label from '../common/commonlabel';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function AddNewProduct(props) {
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [openingBalance, setOpeningBalance] = useState("debit")
    const [tds, setTds] = useState(false);

    const handleSetRememberMe = (event) => {
        setTds(event.target.checked);
    };

    const handleChange = (event) => {
        setOpeningBalance(event.target.value);
    };

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <>
            {
                props.buttonDesign == "contained" ? (
                    <SoftButton
                        color="info"
                        variant="contained"
                        onClick={handleClickOpen('paper')}>
                        <AddCircleOutlineIcon />
                        Add New Product
                    </SoftButton>
                ) : (
                    <SoftButton
                        size="small"
                        onClick={handleClickOpen('paper')}
                        variant="text"
                        color="info"
                        className="addnewpro-label">
                        Add New Product
                    </SoftButton>
                )
            }

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Add Item
                </BootstrapDialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <SoftTypography variant="h5">Basic Details</SoftTypography>
                    <SoftBox
                        bgColor="light"
                        py={1.875}
                        px={1.875}
                        borderRadius="sm"
                        mt={1}
                    >
                        <SoftBox mt={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Label label="Name" />
                                    <FormField type="text" placeholder="Enter Name" />
                                </Grid>
                            </Grid>
                        </SoftBox>
                        <SoftBox mt={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Label label="Selling Price" />
                                    <FormField type="text" placeholder="Enter Selling Price" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className='add-gst-number'>
                                        <a href="javascript:void(0)"> Add GST Number </a>
                                        <SoftTypography variant="h6"> Add GST Details to add tax for the products. </SoftTypography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container my={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className='primary-unit'>
                                        <Label label="Primary Unit" />
                                        <FormField type="text" placeholder="Select Unit" />
                                        <a href="javascript:void(0)"> GST approved UQC codes. </a>
                                    </div>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </SoftBox>
                    <SoftTypography variant="h5" mt={2}>Additional Information (Optional)</SoftTypography>
                    <SoftBox
                        bgColor="light"
                        py={1.875}
                        px={1.875}
                        borderRadius="sm"
                        mt={1}
                    >

                        <SoftBox>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>

                                    <SoftBox mb={3}>

                                        <Label label=" HSN/SAC code" />
                                        <SoftSelect className="custom-selectbox"
                                            defaultValue={{ value: "clothing", label: "HSN/SAC Code" }}
                                            options={[
                                                { value: "clothing", label: "Clothing" },
                                                { value: "electronics", label: "Electronics" },
                                                { value: "furniture", label: "Furniture" },
                                                { value: "others", label: "Others" },
                                                { value: "real estate", label: "Real Estate" },
                                            ]}
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="purchase-price">
                                        <Label label="Purchase Price" />
                                        <FormField type="text" placeholder="" />
                                        <SoftSelect className="custom-selectbox"
                                            defaultValue={{ value: "clothing", label: "With Tax" }}
                                            options={[
                                                { value: "clothing", label: "Clothing" },
                                                { value: "electronics", label: "Electronics" },
                                                { value: "furniture", label: "Furniture" },
                                                { value: "others", label: "Others" },
                                                { value: "real estate", label: "Real Estate" },
                                            ]}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className='barcode'>
                                        <Label label="Barcode" />
                                        <FormField type="text" placeholder="Bussiness Name" />
                                        <button type='button'>Generate barcode</button>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Label label="Category" />
                                    <div className="slect-input-group" mt={2}>
                                        <SoftSelect className="custom-selectbox"
                                            defaultValue={{ value: "clothing", label: "Category" }}
                                            options={[
                                                { value: "clothing", label: "Clothing" },
                                                { value: "electronics", label: "Electronics" },
                                                { value: "furniture", label: "Furniture" },
                                                { value: "others", label: "Others" },
                                                { value: "real estate", label: "Real Estate" },
                                            ]}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </SoftBox>

                    <SoftBox
                        bgColor="white"
                        py={1.875}
                        px={1.875}
                        borderRadius="sm"
                        mt={2}
                    >
                        <UploadImage />
                        <p className='upload-formate'>
                            Product images must be PNG or JPEG, recommended <br />
                            1024 px by 1024 px or 1:1 aspect ratio.
                        </p>

                    </SoftBox>
                    <SoftBox>
                        <SoftTypography variant="h5" mt={3}>Description</SoftTypography>
                        <FormField multiline type="text" rows={5} placeholder="Description" />
                    </SoftBox>
                    <SoftTypography variant="h5" mt={3}>Opening Stock</SoftTypography>
                    <SoftBox
                        bgColor="light"
                        py={1.875}
                        px={1.875}
                        borderRadius="sm"
                        mt={1}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <div className='opening-quantity'>
                                    <Label label="Opening Quantity" />
                                    <FormField type="number" placeholder="0" />
                                    <span>*Quantity of the product available in your existing inventory</span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Label label="Opening Purchase Price (with tax)" />
                                <FormField type="number" placeholder="0" />
                            </Grid>

                            <Grid item xs={12} sm={6} className='opening-stock'>
                                <Label label="Opening Stock Value (with tax)" />
                                <FormField type="number" placeholder="0" />
                            </Grid>
                        </Grid>
                    </SoftBox>



                </DialogContent>
                <DialogActions>
                    <SoftButton onClick={handleClose} variant="outlined" color="info">Cancel</SoftButton>
                    <SoftButton onClick={handleClose} variant="contained" color="info">Add Item</SoftButton>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}