import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { addBankAccount as addBankAccountAPI } from "api/BankAccount";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useFormik } from "formik";
import FormField from "layouts/pages/account/components/FormField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toasterShow } from "store/slices/userSlice";
import * as Yup from "yup";


export default function AddNewBankAccount(props) {
    const { handleDialogClose } = props;
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        account_holder_name: "",
        account_number: "",
        bank_name: "",
        branch_name: "",
        ifsc_code: "",
        iban_number: "",
        swift_code: "",
    });

    const validationSchema = Yup.object().shape({
        account_holder_name: Yup.string().required("Account holder name is required"),
        account_number: Yup.string().required("Account number is required"),
        bank_name: Yup.string().required("Bank name is required"),
        branch_name: Yup.string().required("Branch name is required"),
        ifsc_code: Yup.string().required("IFSC code is required"),
        iban_number: Yup.string(),
        swift_code: Yup.string(),
    });

    const currentBusinessID = useSelector(
        (state) => state.user.selectedBusinessID
    );

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: formData,
            validationSchema: validationSchema,
            onSubmit: async (values, action) => {
                try {
                    await addBankAccountAPI(values, currentBusinessID);
                    handleDialogClose();

                    dispatch(
                        toasterShow({
                            toasterColor: "success",
                            toasterMessage: "Bank account added successfully",
                            toasterToggle: true,
                        })
                    );
                } catch (error) {
                    dispatch(
                        toasterShow({
                            toasterColor: "error",
                            toasterMessage: error.message,
                            toasterToggle: true,
                        })
                    );
                }
            },
            validateOnChange: true,
            validateOnBlur: true,
        });


    return (
        <SoftBox component="form" pb={3} px={3}>
            <form onSubmit={handleSubmit}>
                <SoftBox mt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Account Holder Name"
                                name="account_holder_name"
                                value={values.account_holder_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.account_holder_name && Boolean(errors.account_holder_name)}
                                helperText={touched.account_holder_name && errors.account_holder_name}
                            />
                            <FormHelperText error={true}>{touched.account_holder_name && errors.account_holder_name}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Account Number"
                                name="account_number"
                                value={values.account_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.account_number && Boolean(errors.account_number)}
                                helpertext={touched.account_number && errors.account_number}
                            />
                            <FormHelperText error={true}>{touched.account_number && errors.account_number}</FormHelperText>
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Bank Name"
                                name="bank_name"
                                value={values.bank_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.bank_name && Boolean(errors.bank_name)}
                                helperText={touched.bank_name && errors.bank_name}
                            />
                            <FormHelperText error={true}>{touched.bank_name && errors.bank_name}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Branch Name"
                                name="branch_name"
                                value={values.branch_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.branch_name && Boolean(errors.branch_name)}
                                helpertext={touched.branch_name && errors.branch_name}
                            />
                            <FormHelperText error={true}>{touched.branch_name && errors.branch_name}</FormHelperText>
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <FormField
                                label="IFSC Code"
                                name="ifsc_code"
                                value={values.ifsc_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.ifsc_code && Boolean(errors.ifsc_code)}
                                helperText={touched.ifsc_code && errors.ifsc_code}
                            />
                            <FormHelperText error={true}>{touched.ifsc_code && errors.ifsc_code}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormField
                                label="IBAN Number"
                                name="iban_number"
                                value={values.iban_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.iban_number && Boolean(errors.iban_number)}
                                helperText={touched.iban_number && errors.iban_number}
                            />
                            <FormHelperText error={true}>{touched.iban_number && errors.iban_number}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormField
                                label="Swift Code"
                                name="swift_code"
                                value={values.swift_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.swift_code && Boolean(errors.swift_code)}
                                helperText={touched.swift_code && errors.swift_code}
                            />
                            <FormHelperText error={true}>{touched.swift_code && errors.swift_code}</FormHelperText>
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox justifyContent="center" alignItems="center" display="flex" mt={4}>
                    <SoftButton onClick={handleSubmit} variant="contained" color="success">
                        Submit
                    </SoftButton>
                </SoftBox>
            </form>
        </SoftBox>
    );
}
