import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import GstFileField from "./components/BasicInfo";
import Sidenav from "./components/BasicInfo/Sidenav/index";
import FileReturn from "./components/BasicInfo/fileReturnTab";
import breakpoints from "assets/theme/base/breakpoints";
import { GSTRSummary, GSTRReturnTrack } from "api/Business/GSTAuthentication";
import { toasterShow } from "store/slices/userSlice";
import { setSelectedBusinessInfo } from "store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "common/loader";
import { loaderShow } from "store/slices/commonUISlice";
import SoftSelect from "components/SoftSelect";

// Data
import selectData from "common/DatetimeData";
import SoftButton from "components/SoftButton";

const StyledTableCell = TableCell;

const isObject = (value) => typeof value === "object" && value !== null;

const renderTableCell = (value, level = 0) => {
  if (isObject(value)) {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="nested table">
          <TableBody>
            {Object.keys(value).map((nestedKey) => (
              <TableRow key={nestedKey}>
                <TableCell style={{ paddingLeft: `${level * 20}px` }}>
                  {nestedKey}
                </TableCell>
                <TableCell>
                  {renderTableCell(value[nestedKey], level + 1)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return value;
};

function Settings() {
  const { values } = breakpoints;
  const [jsonData, setJsonData] = useState(null);
  const dispatch = useDispatch();
  const businessId = useSelector((state) => state.user.selectedBusinessID);
  const [loading, setLoading] = useState(true);
  const selectedBusinessInfo = useSelector((state) => state.user);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(
    selectData.financialYear.currentYearObj.value
  );
  const [returnsData, setReturnsData] = useState({});

  const navigate = useNavigate();

  const currentBusiness = useSelector((state) => state.user.selectedBusiness);

  const getGSTRReturnTrack = useCallback(async () => {
    const formData = {
      businessId: businessId,
      RetPeriod: selectedFinancialYear,
    };
    try {
      const response = await GSTRReturnTrack(formData);

      if (response.success === true) {
        setReturnsData(response.data);
        console.log("response.data", response.data);
      } else if (response.data.error_cd == "AUTH158") {
        // set isGstLoggedIn to false
        dispatch(
          setSelectedBusinessInfo({
            ...selectedBusinessInfo,
            GSTloggedIn: false,
          })
        );

        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: "Please login to GST portal again",
            toasterToggle: true,
            toasterTitle: "Auth token expired",
          })
        );
        navigate("/gst/login");
      } else {
        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: response.data.message,
            toasterToggle: true,
            toasterTitle: "Error getting data from GSP",
          })
        );
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
          toasterTitle: "Error getting data from GSP",
        })
      );
    }
    setLoading(false);
  }, [
    businessId,
    selectedFinancialYear,
    dispatch,
    selectedBusinessInfo,
    navigate,
  ]);

  useEffect(() => {
    getGSTRReturnTrack();
  }, []);

  const handleButtonClick = () => {
    setLoading(true);
    getGSTRReturnTrack();
  };

  const setFinancialYear = (year) => {
    setSelectedFinancialYear(year.value);
    console.log("year", selectedFinancialYear);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader open={loading} />

      <SoftBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            {/* <SoftBox mb={1} p={1}>
              <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                Return Track
              </SoftTypography>
            </SoftBox> */}

            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <SoftBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {/* <GstFileField /> */}

                      <Card id="basic-info" sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                          <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                              {currentBusiness.name}
                            </SoftTypography>
                            {/* <SoftTypography variant="button" color="text" fontWeight="medium">
            27AADCB2230M1ZT    

            <Link to="/" style={{marginLeft:'10px'}}>
              <SoftTypography
                variant="body2"
                color="info"
                textTransform="capitalize"
                component="span"
              >
                Business name
              </SoftTypography>
            </Link>
          </SoftTypography> */}
                          </SoftBox>
                        </SoftBox>
                        <SoftBox component="form" pb={3} px={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                {/* <Grid item xs={12} sm={2}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      GSTR
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect placeholder="GSTR1" options={selectData.gstrType} />
                </SoftBox>
              </Grid> */}

                                <Grid item xs={12} sm={2}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox
                                      mb={1}
                                      ml={0.5}
                                      lineHeight={0}
                                      display="inline-block"
                                    >
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                      >
                                        Financial Year
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                      placeholder={selectedFinancialYear}
                                      options={selectData.financialYear.years}
                                      value={selectedFinancialYear}
                                      onChange={(event) =>
                                        setFinancialYear(event)
                                      }
                                    />
                                  </SoftBox>
                                </Grid>

                                {/* <Grid item xs={12} sm={2}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Quarter
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect placeholder="Quarter 4 (Oct - Dec)" options={selectData.quarter} />
                </SoftBox>
              </Grid> */}
                                {/* <Grid item xs={12} sm={3}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Month
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect placeholder="November" options={selectData.month} />
                </SoftBox>
              </Grid> */}
                                <Grid item xs={12} sm={3}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                  >
                                    <SoftBox
                                      mb={1}
                                      ml={0.5}
                                      lineHeight={0}
                                      display="inline-block"
                                    >
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                      ></SoftTypography>
                                    </SoftBox>
                                    <SoftButton
                                      variant="contained"
                                      color="success"
                                      onClick={handleButtonClick}
                                    >
                                      Search
                                    </SoftButton>
                                  </SoftBox>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </Card>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>

            <SoftBox mt={4}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} lg={3}>
                    <Card>
                      <SoftBox p={2}>
                        <Sidenav />
                      </SoftBox>
                    </Card>
                  </Grid> */}
                <Grid item xs={12} lg={12}>
                  <Card>
                    <SoftBox p={2}>
                      <FileReturn data={returnsData} />
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>

            {/* <SoftBox mt={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <div>
                    <h1>Your Table</h1>
                    {jsonData && (
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {Object.keys(jsonData[0]).map((key) => (
                                <StyledTableCell key={key}>{key}</StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {jsonData.map((row, index) => (
                              <TableRow key={index}>
                                {Object.keys(row).map((key) => (
                                  <StyledTableCell key={key}>{renderTableCell(row[key])}</StyledTableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                </Grid>
              </Grid>
            </SoftBox> */}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
