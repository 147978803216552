import { Add as AddIcon } from "@mui/icons-material";
import Card from "@mui/material/Card";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftDialog from "components/SoftDialog";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import AddNewBankAccount from "./AddNewBankAccount";

export default function SelectBankAccount(props) {
    const { bankAccounts, reloadBankAccountList } = props;
    const { values = {}, setFieldValue, handleBlur, touched = {}, errors = {} } = props;
    const [selectedBankAccount, setSelectedBankAccount] = useState();
    const [addBankAccountOpen, setAddBankAccountOpen] = useState(false);

    const bankAccountsList = bankAccounts.map((bank_account) => ({
        label: `${bank_account.bank_name} - ${bank_account.account_number}`,
        value: bank_account.id,
    }));

    function handleBankAccountOpen() {
        if (!addBankAccountOpen) {
            setAddBankAccountOpen(true);
        }
    }

    function handleAddBankAccountClose() {
        setAddBankAccountOpen(false);
        reloadBankAccountList();
    }

    const handleBankAccountChange = (option) => {
        if (option) {
            setSelectedBankAccount(bankAccounts.find((bank_account) => bank_account.id === option.value));
            setFieldValue("bank_account", option.value);
        } else {
            setSelectedBankAccount(null);
            setFieldValue("bank_account", -1);
        }
    };

    const renderAddBankAccount = (
        <SoftDialog
            component={
                <AddNewBankAccount
                    handleDialogClose={handleAddBankAccountClose}
                />
            }
            title={"Add Bank Account"}
            handleClose={handleAddBankAccountClose}
            open={addBankAccountOpen}
            maxWidth="lg"
        />
    );

    useEffect(() => {
        if (values.bank_account > -1) {
            setSelectedBankAccount(bankAccounts.find((bank_account) => bank_account.id === values.bank_account));
        } else {
            setSelectedBankAccount(null);
        }
    }, [values.bank_account])

    return (
        <Card id="bank-account-card" sx={{ overflow: "visible", height: "100%" }}>
            <SoftBox px={3} pt={3}>
                <SoftTypography variant="h5">Bank Account</SoftTypography>
            </SoftBox>
            <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                p={3}
            >
                <SoftBox
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    lineHeight={1}
                >
                    <SoftBox mb={2}>
                        <Grid container>
                            <Grid item md={6.5} mr={1}>
                                <SoftSelect
                                    inputId="bank_account"
                                    placeholder="Select Bank Account"
                                    noOptionsMessage={() => "No Bank Account Added"}
                                    isClearable={true}
                                    options={bankAccountsList}
                                    mb={1}
                                    value={bankAccountsList.filter((option) => option.value === values.bank_account)?.[0]}
                                    onChange={handleBankAccountChange}
                                    onBlur={handleBlur}
                                    error={touched.bank_account && Boolean(errors.bank_account)}
                                />
                            </Grid>
                            <Grid item>
                                <SoftButton
                                    variant="gradient"
                                    color="secondary"
                                    onClick={handleBankAccountOpen}
                                >
                                    <AddIcon />&nbsp; Add Bank Account
                                    {renderAddBankAccount}
                                </SoftButton>
                            </Grid>
                        </Grid>
                        <FormHelperText error={true}>{touched.bank_account && errors.bank_account}</FormHelperText>
                    </SoftBox>
                    {selectedBankAccount ?
                        <>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Account Number:&nbsp;&nbsp;&nbsp;
                                    <SoftTypography
                                        variant="caption"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                    >
                                        {selectedBankAccount?.account_number}
                                    </SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Account Holder Name:&nbsp;&nbsp;&nbsp;
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        {selectedBankAccount?.account_holder_name}
                                    </SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    IFSC Code:&nbsp;&nbsp;&nbsp;
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        {selectedBankAccount?.ifsc_code}
                                    </SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Branch Name:&nbsp;&nbsp;&nbsp;
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        {selectedBankAccount?.branch_name}
                                    </SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text" hidden={!selectedBankAccount?.iban_number}>
                                    IBAN Number:&nbsp;&nbsp;&nbsp;
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        {selectedBankAccount?.iban_number}
                                    </SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text" hidden={!selectedBankAccount?.swift_code}>
                                    Swift Code:&nbsp;&nbsp;&nbsp;
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        {selectedBankAccount?.swift_code}
                                    </SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                        </> : null
                    }
                </SoftBox>
            </SoftBox>
        </Card>
    );
}
