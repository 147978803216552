import { makeRequest, withAPI } from "api";

export const businessDetailsFetchUsingGSTIN = withAPI(async function (gstin) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ gstin: gstin }),
  };
  const data = await makeRequest("api/v1/business/gstin/", options);
  return data;
});


export const editBusiness = async function (business_id, payload) {
  const additional_details = {};
  if (payload.companyGSTIN) {
    additional_details.gstin = payload.companyGSTIN;
  }
  if (payload.companyPAN) {
    additional_details.pan = payload.companyPAN;
  }

  const address_data = {
    "address_type": 1,
    "address": payload.companyAddress,
    "pincode": payload.companyPINCODE,
  };
  if (payload.companyAddressId >= 0) {
    address_data["id"] = payload.companyAddressId;
  }

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      "name": payload.companyName,
      "mobile": payload.companyPhone,
      "email": payload.companyEmail,
      "addresses": [address_data],
      "additional_details": additional_details
    }),
  };
  const data = await makeRequest(`api/v1/business/${business_id}/`, options);
  return data;
};
