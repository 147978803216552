import Grid from "@mui/material/Grid";
import { bankAccountListFetch } from "api/BankAccount";
import { buyerListFetch } from "api/Buyers";
import { productsListFetch } from "api/Products";
import { termsAndConditionsFetch } from "api/TermsAndConditions";
import { createNewInvoice } from "api/invoices";
import Loader from "common/loader";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import AddProducts from "layouts/invoices/invoice/components/CreateInvoice/AddProducts";
import SelectBankAccount from "layouts/invoices/invoice/components/CreateInvoice/BankAccount";
import BasicInfo from "layouts/invoices/invoice/components/CreateInvoice/BasicInfo";
import Header from "layouts/invoices/invoice/components/CreateInvoice/Header";
import TermsAndConditions from "layouts/invoices/invoice/components/CreateInvoice/TermsAndConditions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toasterShow } from "store/slices/userSlice";
import * as Yup from "yup";


function InvoiceDummy() {
  const [buyers, setBuyers] = useState([]);
  const [products, setProducts] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingBuyerList, setLoadingBuyerList] = useState(true);
  const [loadingProductsData, setLoadingProductsData] = useState(true);
  const [loadingBankAccountsList, setLoadingBankAccountsList] = useState(true);
  const [loadingTermsAndConditionsList, setLoadingTermsAndConditionsList] = useState(true);

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );

  const loadBuyersList = async () => {
    setLoadingBuyerList(true)

    try {
      console.log("currentBusinessID", currentBusinessID);
      const response = await buyerListFetch(currentBusinessID);
      if (response && response.data.count > 0) {
        setBuyers(response.data.results);
      } else {
        console.log("No buyer found");
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoadingBuyerList(false)
  };

  const fetchProductsData = async () => {
    setLoadingProductsData(true)

    try {
      const response = await productsListFetch(currentBusinessID);
      if (response && response.data.count > 0) {
        setProducts(response.data.results);
      } else {
        console.log("No product found");
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoadingProductsData(false)
  };

  const loadBankAccountsList = async () => {
    setLoadingBankAccountsList(true)

    try {
      const response = await bankAccountListFetch(currentBusinessID);
      if (response && response.data.count > 0) {
        setBankAccounts(response.data.results);
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoadingBankAccountsList(false)
  };

  const loadTermsAndConditions = async () => {
    setLoadingTermsAndConditionsList(true)

    try {
      const response = await termsAndConditionsFetch(currentBusinessID);
      if (response && response.data.count > 0) {
        setTermsAndConditions(response.data.results);
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoadingTermsAndConditionsList(false)
  };
  useEffect(() => {
    loadBuyersList();
    fetchProductsData();
    loadBankAccountsList();
    loadTermsAndConditions();
  }, []);

  const [formData, setFormData] = useState({
    business: currentBusinessID,
    invoice_number: "",
    date: new Date(),
    due_date: new Date(),
    buyer: "",
    bank_account: -1,
    terms_and_conditions: -1,
    items: [],
  });

  const validationSchema = Yup.object().shape({
    invoice_number: Yup.string().required("The invoice number is required"),
    date: Yup.date().required("The invoice date is required"),
    due_date: Yup.date().required("The invoice due date is required"),
    buyer: Yup.number().integer().positive().required("The buyer field is required"),
    bank_account: Yup.number().integer(),
    terms_and_conditions: Yup.number().integer(),
    items: Yup.array(
      Yup.object().shape({
        product: Yup.number().integer().positive().required("The product field is required"),
        unit_price: Yup.number().typeError("The unit price must be a positive number").positive("The unit price must be a positive number").required("The unit price is required"),
        quantity: Yup.number().typeError("The product quantity must be a positive number").positive("The product quantity must be a positive number").required("The product quantity is required"),
      })
    ).min(1, "Add at least one product to invoice")
      .uniqueProperty('product', 'The product must be unique'),
  });

  const { values, errors, touched, setFieldValue, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        try {
          const data = JSON.parse(JSON.stringify(values));
          data.date = (new Date(data.date)).toISOString().slice(0, 10);
          data.due_date = (new Date(data.due_date)).toISOString().slice(0, 10);

          if (data.bank_account < 0) {
            delete data.bank_account;
          }
          if (data.terms_and_conditions < 0) {
            delete data.terms_and_conditions;
          }

          const response = await createNewInvoice(data);

          dispatch(
            toasterShow({
              toasterColor: "success",
              toasterMessage: "Invoice created successfully",
              toasterToggle: true,
            })
          );

          navigate('/invoices');
        } catch (error) {
          dispatch(
            toasterShow({
              toasterColor: "error",
              toasterMessage: error.message,
              toasterToggle: true,
            })
          );
        }
      },
      validateOnChange: true,
      validateOnBlur: true,
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader open={loadingBuyerList || loadingProductsData || loadingBankAccountsList || loadingTermsAndConditionsList} />
      <SoftBox component="form" mt={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item lg={11}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header handleSubmit={handleSubmit} />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo
                    buyers={buyers}
                    reloadBuyersList={loadBuyersList}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddProducts
                    products={products}
                    reloadProducts={fetchProductsData}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <SelectBankAccount
                        bankAccounts={bankAccounts}
                        reloadBankAccountList={loadBankAccountsList}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TermsAndConditions
                        termsAndConditions={termsAndConditions}
                        reloadTermsAndConditions={loadTermsAndConditions}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Header handleSubmit={handleSubmit} />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default InvoiceDummy;
