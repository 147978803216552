import { makeRequest, withAPI } from "api";

export const SendGSTOTP = withAPI(async function (payload) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`
    },
    data: JSON.stringify({
      "gstin": payload.GSTINNumber,
      "gst_username": payload.GSTINUsername,
    }),
  };
  const data = await makeRequest("api/v1/business/gstin/otp/", options);
  return data;
});

export const VerifyGSTOTP = withAPI(async function (payload) {
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
      data: JSON.stringify({
        "gstin": payload.GSTINNumber,
        "gst_username": payload.GSTINUsername,
        "biz_id": payload.businessId,
        "otp": payload.OTP,
        "access_token": payload.accessCode,
      }),
    };
    console.log("options", options);
    console.log("payload", payload);
    const data = await makeRequest("api/v1/business/gstin/otp/verify/", options);
    return data;
  });


  export const GSTRSummary = withAPI(async function (payload) {
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
      data: JSON.stringify({
        "ret_type": payload.RetType,
        "ret_period": payload.RetPeriod,
        "biz_id": payload.businessId,
      }),
    };
    console.log("options", options);
    console.log("payload", payload);
    const data = await makeRequest("api/v1/business/gstr/summary/", options);
    return data;
  });


  export const GSTRReturnTrack = withAPI(async function (payload) {
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
      data: JSON.stringify({
        "biz_id": payload.businessId,
        "ret_period": payload.RetPeriod,
      }),
    };
    const data = await makeRequest("api/v1/business/gstr/track/", options);
    return data;
  });

  export const GSTR2AB2BSummary = withAPI(async function (payload) {
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
      data: JSON.stringify({
        "biz_id": payload.businessId,
        "month": payload.month,
        "year": payload.year,
      }),
    };
    const data = await makeRequest("api/v1/business/gstr2a/b2b/", options);
    return data;
  });

  export const DownloadGSTR2AB2BReport = withAPI(async function (payload) {
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
      params: {
        "biz_id": payload.businessId,
        "month": payload.month,
        "year": payload.year,
      },
    };
    const data = await makeRequest("api/v1/business/gstr2a/b2b/report/", options);
    return data;
  });
