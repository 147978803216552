import React, { useCallback, useEffect, useState } from "react";
import SoftDialog from "components/SoftDialog";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toasterShow } from "store/slices/userSlice";
import { businessDetailsFetchUsingGSTIN } from "api/Business";
import { CityDetailsUsingPincode } from "api/Address";
import SoftBox from "components/SoftBox";
import FormField from "layouts/pages/account/components/FormField";
import Divider from "@mui/material/Divider";
import SoftTypography from "components/SoftTypography";
import Loader from "common/loader";


export default function CheckGSTIN() {
  const [addBusinessOpen, setAddBusinessOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companyGSTIN, setCompanyGSTIN] = useState("");
  const [fetchedCompanyGSTINDetails, setFetchedCompanyGSTINDetails] = useState({});
  const [fetchedCityData, setFetchedCityData] = useState({});
  const [loadingGSTINInfo, setLoadingGSTINInfo] = useState(false);

  const fetchBusinessDetailsUsingGSTIN = useCallback(async (value) => {
    setLoadingGSTINInfo(true)
    setFetchedCompanyGSTINDetails({})

    try {
      const response = await businessDetailsFetchUsingGSTIN(value);

      if (response.success === true) {
        setFetchedCompanyGSTINDetails({
          companyName: response.data.company_name,
          companyAddress: response.data.company_address,
          companyPINCODE: response.data.company_pincode,
        });
      } else {
        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: response.data.message,
            toasterToggle: true,
            toasterTitle: "Error fetching GSTIN details",
          })
        );
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
          toasterTitle: "Error fetching GSTIN details",
        })
      );
    }

    setLoadingGSTINInfo(false)
  }, []);

  const fetchCityDetailsUsingPincode = useCallback(async (value) => {
    setLoadingGSTINInfo(true)
    setFetchedCityData({})

    try {
      const response = await CityDetailsUsingPincode(value);

      setFetchedCityData({
        pincodeID: response.data.id,
        stateName: response.data.city.state.name,
        stateID: response.data.city.state.id,
        cityName: response.data.city.name,
        cityID: response.data.city.id,
      });
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
        })
      );
    }

    setLoadingGSTINInfo(false)
  }, []);


  useEffect(() => {
    if (companyGSTIN.length === 15) {
      fetchBusinessDetailsUsingGSTIN(companyGSTIN);
    }
  }, [companyGSTIN]);

  useEffect(() => {
    if (fetchedCompanyGSTINDetails.companyPINCODE?.length === 6) {
      fetchCityDetailsUsingPincode(fetchedCompanyGSTINDetails.companyPINCODE);
    }
  }, [fetchedCompanyGSTINDetails]);

  function handleDialogBoxClose() {
    setAddBusinessOpen(false);
    navigate(-1);
  }

  function handleDialogBoxOpen() {
    if (!addBusinessOpen) {
      setAddBusinessOpen(true);
    }
  }

  const renderBusinessDetails = function () {
    return (
      <SoftBox component="form" pb={3} px={3}>
        <Loader open={loadingGSTINInfo} />
        <form>
          <SoftBox
            display="flex"
            flexDirection="center"
            justifyContent="center"
            height="100%"
          >
            <SoftBox ml={1}>
              <FormField
                label="Company GSTIN"
                placeholder="22AAAAA0000A1Z5"
                name="companyGSTIN"
                value={companyGSTIN}
                onChange={(event) => setCompanyGSTIN(event.target.value)}
              />
            </SoftBox>
          </SoftBox>
        </form>

        {Object.keys(fetchedCompanyGSTINDetails).length > 0 ? <>
          <SoftBox
            mt={2}
            mb={1}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Divider
              light
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "70%",
              }}
            >
              <SoftTypography
                variant="text"
                fontWeight="bold"
                color="dark"
                size="large"
              >
                GSTIN DETAILS
              </SoftTypography>
            </Divider>
          </SoftBox>

          <SoftBox hidden={!fetchedCompanyGSTINDetails.companyName}>
            <SoftTypography variant="caption" color="text">
              Company Name :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCompanyGSTINDetails.companyName}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox hidden={!fetchedCompanyGSTINDetails.companyAddress}>
            <SoftTypography variant="caption" color="text">
              Company Address :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCompanyGSTINDetails.companyAddress}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox hidden={!fetchedCompanyGSTINDetails.companyPAN}>
            <SoftTypography variant="caption" color="text">
              Company PAN :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCompanyGSTINDetails.companyPAN}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox hidden={!fetchedCompanyGSTINDetails.companyPhone}>
            <SoftTypography variant="caption" color="text">
              Company Phone :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCompanyGSTINDetails.companyPhone}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox hidden={!fetchedCompanyGSTINDetails.companyEmail}>
            <SoftTypography variant="caption" color="text">
              Company Email :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCompanyGSTINDetails.companyEmail}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox hidden={!fetchedCompanyGSTINDetails.companyPINCODE}>
            <SoftTypography variant="caption" color="text">
              Company PINCODE :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCompanyGSTINDetails.companyPINCODE}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox hidden={!fetchedCityData.cityName}>
            <SoftTypography variant="caption" color="text">
              Company City :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCityData.cityName}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox hidden={!fetchedCityData.stateName}>
            <SoftTypography variant="caption" color="text">
              Company State :&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {fetchedCityData.stateName}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </> : null}
      </SoftBox>
    );
  }

  return (
    <>
      <SoftDialog
        component={
          renderBusinessDetails()
        }
        title="Check GSTIN Details"
        handleOpen={handleDialogBoxOpen}
        handleClose={handleDialogBoxClose}
        open={addBusinessOpen}
        maxWidth="lg"
      />
    </>
  );
}
