import checkout from "../Schemas";

const {
  formField: {
    GSTINNumber,
    GSTINUsername,
    OTP
  },
} = checkout;

const initialValues = {
  [GSTINNumber.name]: "",
  [GSTINUsername.name]: "",
  [OTP.name]: "",
};

export default initialValues;
