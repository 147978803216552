import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'white',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
export default function CustomizedAccordions() {
    // const [formValue, setFormValue] = React.useState([])
    // const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);

    const [notesList, setNotesList] = React.useState([]);
    const [notesName, setNotesName] = React.useState('');

    const [termsList, setTermsList] = React.useState([]);
    const [termsName, setTermsName] = React.useState('');

    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleSetNotes = (event) => {
        setNotesName(event.target.value);
    }
    const addNotesSubmit = () => {
        if (notesName.trim() !== '') {
            setNotesList((prevItems) => [...prevItems, notesName]);
            setNotesName('');
        }
    }

    const handleSetTerms = (event) => {
        setTermsName(event.target.value);
    }
    const addTermsSubmit = () => {
        if (termsName.trim() !== '') {
            setTermsList((prevItems) => [...prevItems, termsName]);
            setTermsName('');
        }
    }
    const cancelAddNotes = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }
    return (
        <div className='add-card-sec'>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <SoftTypography variant="h6"> Notes</SoftTypography>
                        {/* <SoftButton color="info" variant="text" size="small" className="new-note-btn">
                            <AddCircleOutlineIcon />
                            New Note
                        </SoftButton> */}
                        <SoftTypography variant="span" className="new-note-btn" title="Add New Notes">
                            <AddCircleOutlineIcon color="info"/>
                        </SoftTypography>
                    </SoftBox>
                </AccordionSummary>
                <AccordionDetails>
                    <SoftBox>
                        <SoftInput
                            inputProps={{ rows: 10 }}
                            onChange={handleSetNotes}
                            multiline
                            value={notesName}
                            className="custom-textarea"
                        />
                        {/* <SoftBox display="flex" mt={2}>
                            <SoftButton
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={addNotesSubmit}
                            >
                                add
                            </SoftButton>
                            <SoftBox ml={1}>
                                <SoftButton
                                    variant="contained"
                                    color="light"
                                    size="small"
                                    onClick={cancelAddNotes('panel1')}
                                >
                                    cancel
                                </SoftButton>
                            </SoftBox>
                        </SoftBox> */}
                        <ListShowAll alllist={notesList} />
                    </SoftBox>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <SoftTypography variant="h6"> Terms & Conditions </SoftTypography>
                        <SoftTypography variant="span" className="new-note-btn" title="Add New Terms">
                            <AddCircleOutlineIcon color="info"/>
                        </SoftTypography>
                       
                    </SoftBox>
                </AccordionSummary>
                <AccordionDetails>
                    <SoftBox>
                        <SoftInput
                            inputProps={{ rows: 10 }}
                            onChange={handleSetTerms}
                            value={termsName}
                            multiline
                            className="custom-textarea"
                        />
                        {/* <SoftBox display="flex" mt={2}>
                            <SoftButton
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={addTermsSubmit}
                            >
                                add
                            </SoftButton>
                            <SoftBox ml={1}>
                                <SoftButton
                                    variant="contained"
                                    color="light"
                                    size="small"
                                    onClick={cancelAddNotes('panel2')}
                                >
                                    cancel
                                </SoftButton>
                            </SoftBox>
                        </SoftBox> */}
                        {/* <ListShowAll alllist={termsList} /> */}
                    </SoftBox>
                </AccordionDetails>
            </Accordion>



            <div className='attach-file-sec'>
                <SoftTypography component="h5"> Attach files </SoftTypography>
                <Button variant='outlined' color='info'>
                    <UploadIcon />  Attach Files (Max: 3)
                </Button>
            </div>
        </div>
    );
}

const ListShowAll = (props) => {
    return (
        <ul className='notes-all-list'>
            {
                props.alllist.map((notes, index) => {
                    return (
                        <li key={index}>{notes}</li>
                    )
                })
            }
        </ul>
    )
}
