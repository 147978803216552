/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/ 

// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/pages/notifications";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
// import NewProduct from "layouts/ecommerce/products/new-product";
// import EditProduct from "layouts/ecommerce/products/edit-product";
// import ProductPage from "layouts/ecommerce/products/product-page";
// import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import ResetBasic from "layouts/authentication/reset-password/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Icon from "@mui/material/Icon";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";

import InvoiceList from "./layouts/invoices/invoice/invoiceList";
import CreateInvoice from "./layouts/invoices/invoice/createInvoice/createInvoice";
import ProtectedRoutes from "./components/PrivateRoute";
import GstDashboard from "./layouts/gstFiling/dashboard/index";
import ReturnsTrack from "./layouts/returns/track/index";
import GSTR2A from "./layouts/GSTR2A/track/index";
import NilGSTRFiling from "./layouts/nilGSTRFiling/index";
import UploadInvoiceData from "./layouts/gstFiling/uploadInvoiceData/index"
import GstSummary from "./layouts/gstFiling/summary/index";
import GstInvoiceDetails from "./layouts/gstFiling/invoiceDetails/index";
import InvoiceDummy from "layouts/invoices/invoice/createInvoice";
import ViewInvoice from "layouts/invoices/invoice/viewInvoice";
import checkGSTIN from "./layouts/checkGSTIN";
import GSTINLogin from "layouts/gstin/login";
import GSTRSummary from "./layouts/gstrSummary/track/index"
import CreditNotesList from "./layouts/invoices/credit_note/creditNotesList";
import CreateCreditNote from "layouts/invoices/credit_note/createInvoice";
import ViewCreditNote from "layouts/invoices/credit_note/viewCreditNote";
import ProductsList from "layouts/ecommerce/products/productsList";
import CreateProduct from "layouts/ecommerce/products/createProduct";
import ViewProduct from "layouts/ecommerce/products/viewProduct";
import { AccessTime } from "@mui/icons-material";


const routes = [
  {
    type: "collapse",
    name: "Check GSTIN",
    key: "check-gstin",
    route: "check-gstin",
    component: <ProtectedRoutes ComponentPro={checkGSTIN} />,
    icon: <Icon size="12px">search</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sales",
    key: "invoices",
    icon: <Icon size="12px">point_of_sale</Icon>,
    collapse: [
      {
        name: "Invoices",
        key: "invoices-list",
        route: "invoices",
        component: <ProtectedRoutes ComponentPro={InvoiceList} />,
      },
      {
        name: "Credit Notes",
        key: "credit-notes-list",
        route: "credit-notes",
        component: <ProtectedRoutes ComponentPro={CreditNotesList} />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Masters",
    key: "masters",
    icon: <Icon size="12px">business_center</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products-list",
        route: "products",
        component: <ProtectedRoutes ComponentPro={ProductsList} />,
      }
    ],
  },
  {
    type: "collapse",
    name: "GST",
    key: "gst",
    locked: {
      stateCheck: "user.GSTloggedIn",
      route: "/gst/login",
      routeKey: "gst-login"
    },
    icon: <Icon size="12px">currency_rupee</Icon>,
    collapse: [
      // {
      //   name: "Dashboard",
      //   key: "dashboard",
      //   route: "/gst/dashboard",
      //   component: <ProtectedRoutes ComponentPro={GstDashboard} />,
      // },
      {
        name: "Returns Track",
        key: "returns-track",
        route: "/gst/returns-track",
        component: <ProtectedRoutes ComponentPro={ReturnsTrack} />,
      },
      {
        name: "GSTR2A",
        key: "gstr2a",
        route: "/gst/gstr2a",
        component: <ProtectedRoutes ComponentPro={GSTR2A} />,
      },
      {
        name: "Nil GSTR Filing (SMS)",
        key: "nil-gstr-filing",
        route: "/gst/nil-gstr-filing",
        component: <ProtectedRoutes ComponentPro={NilGSTRFiling} />,
      },
      // {
      //   name: "Upload Invoice Data",
      //   key: "uploadInvoiceData",
      //   route: "/gst/uploadInvoiceData",
      //   component: <ProtectedRoutes ComponentPro={UploadInvoiceData} />,
      // },
      // {
      //   name: "Summary",
      //   key: "summary",
      //   route: "/gst/summary",
      //   component: <ProtectedRoutes ComponentPro={GstSummary} />,
      // },
      // {
      //   name: "B2b Invoice Details",
      //   key: "b2bInvoiceDetails",
      //   route: "/gst/b2bInvoiceDetails",
      //   component: <ProtectedRoutes ComponentPro={GstInvoiceDetails} />,
      // }
    ],
      },
      // {
      //   name: "Dummy Invoice",
      //   key: "create-invoice",
      //   route: "/invoices/dummy",
      //   component: <ProtectedRoutes ComponentPro={InvoiceDummy} />,
      // }
      {
        name: "GSTR Summary",
        key: "gstrSummary",
        route: "/gst/returns/summary/:returnType/:returnPeriod",
        component: <ProtectedRoutes ComponentPro={GSTRSummary} />,
      },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Coming Soon", key: "title-docs"},
  {
    type: "collapse",
    name: "GST Filing",
    key: "gstFiling",
    href: "#",
    icon: <AccessTime size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "E-Invoicing",
    key: "eInvoicing",
    href: "#",
    icon: <AccessTime size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "E-Way Bill",
    key: "eWayBill",
    href: "#",
    icon: <AccessTime size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Loans",
    key: "Loans",
    href: "#",
    icon: <AccessTime size="12px" />,
    noCollapse: true,
  },

  {
    name: "CreateInvoice",
    key: "createInvoice",
    route: "/invoices/create",
    component: <ProtectedRoutes ComponentPro={InvoiceDummy} />,
  },
  {
    name: "CreateCreditNote",
    key: "createCreditNote",
    route: "/credit-notes/create",
    component: <ProtectedRoutes ComponentPro={CreateCreditNote} />,
  },
  {
    name: "CreateProduct",
    key: "createProduct",
    route: "/products/create",
    component: <ProtectedRoutes ComponentPro={CreateProduct} />,
  },
  {
    name: "GSTINLogin",
    key: "gstin-login",
    route: "/gst/login",
    component: <ProtectedRoutes ComponentPro={GSTINLogin} />,
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/",
    component: <SignInIllustration />,
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/invoice/view/:invoiceId",
    component: <ProtectedRoutes ComponentPro={ViewInvoice} />,
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/credit-note/view/:creditNoteId",
    component: <ProtectedRoutes ComponentPro={ViewCreditNote} />,
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/product/view/:productId",
    component: <ProtectedRoutes ComponentPro={ViewProduct} />,
  },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Shop size="12px" />,
  //   collapse: [
  //     {
  //       name: "Default",
  //       key: "default",
  //       route: "/dashboards/default",
  //       component: <ProtectedRoutes ComponentPro={Default} />,
  //     },
  //     {
  //       name: "Automotive",
  //       key: "automotive",
  //       route: "/dashboards/automotive",
  //       component: <ProtectedRoutes ComponentPro={Automotive} />,
  //     },
  //     {
  //       name: "Smart Home",
  //       key: "smart-home",
  //       route: "/dashboards/smart-home",
  //       component: <ProtectedRoutes ComponentPro={SmartHome} />,
  //     },
  //     {
  //       name: "Virtual Reality",
  //       key: "virtual-reality",
  //       collapse: [
  //         {
  //           name: "VR Default",
  //           key: "vr-default",
  //           route: "/dashboards/virtual-reality/default",
  //           component: <ProtectedRoutes ComponentPro={VRDefault} />,
  //         },
  //         {
  //           name: "VR Info",
  //           key: "vr-info",
  //           route: "/dashboards/virtual-reality/info",
  //           component: <ProtectedRoutes ComponentPro={VRInfo} />,
  //         },
  //       ],
  //     },
  //     { name: "CRM", key: "crm", route: "/dashboards/crm", component: <ProtectedRoutes ComponentPro={CRM} /> },
  //   ],
  // },
  // { type: "title", title: "Pages", key: "title-pages" },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Office size="12px" />,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "Teams",
  //           key: "teams",
  //           route: "/pages/profile/teams",
  //           component: <Teams />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "Reports",
  //           key: "reports",
  //           route: "/pages/users/reports",
  //           component: <Reports />,
  //         },
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //         {
  //           name: "Security",
  //           key: "security",
  //           route: "/pages/account/security",
  //           component: <Security />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "General",
  //           key: "general",
  //           route: "/pages/projects/general",
  //           component: <General />,
  //         },
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //         {
  //           name: "New Project",
  //           key: "new-project",
  //           route: "/pages/projects/new-project",
  //           component: <NewProject />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage />,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Sweet Alerts",
  //       key: "sweet-alerts",
  //       route: "/pages/sweet-alerts",
  //       component: <SweetAlerts />,
  //     },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <SettingsIcon size="12px" />,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/applications/analytics",
  //       component: <Analytics />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Basket size="12px" />,
  //   collapse: [
  //     {
  //       name: "Overview",
  //       key: "overview",
  //       route: "/ecommerce/overview",
  //       component: <Overview />,
  //     },
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //         {
  //           name: "Products List",
  //           key: "products-list",
  //           route: "/ecommerce/products/products-list",
  //           component: <ProductsList />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Referral",
  //       key: "referral",
  //       route: "/ecommerce/referral",
  //       component: <Referral />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <Document size="12px" />,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         // {
  //         //   name: "Basic",
  //         //   key: "basic",
  //         //   route: "/authentication/sign-in/basic",
  //         //   component: <SignInBasic />,
  //         // },
  //         // {
  //         //   name: "Cover",
  //         //   key: "cover",
  //         //   route: "/authentication/sign-in/cover",
  //         //   component: <SignInCover />,
  //         // },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/",
  //           component: <SignInIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Sign Up",
  //       key: "sign-up",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-up/basic",
  //           component: <SignUpBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-up/cover",
  //           component: <SignUpCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/sign-up/illustration",
  //           component: <SignUpIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Reset Password",
  //       key: "reset-password",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/reset-password/basic",
  //           component: <ResetBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/reset-password/cover",
  //           component: <ResetCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/reset-password/illustration",
  //           component: <ResetIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Lock",
  //       key: "lock",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/lock/basic",
  //           component: <LockBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/lock/cover",
  //           component: <LockCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/lock/illustration",
  //           component: <LockIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "2-Step Verification",
  //       key: "2-step-verification",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/verification/basic",
  //           component: <VerificationBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/verification/cover",
  //           component: <VerificationCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/verification/illustration",
  //           component: <VerificationIllustration />,
  //         },
  //       ],
  //     },

  //     {
  //       name: "Error",
  //       key: "error",
  //       collapse: [
  //         {
  //           name: "Error 404",
  //           key: "error-404",
  //           route: "/authentication/error/404",
  //           component: <Error404 />,
  //         },
  //         {
  //           name: "Error 500",
  //           key: "error-500",
  //           route: "/authentication/error/500",
  //           component: <Error500 />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <SpaceShip size="12px" />,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/soft-ui-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.creative-tim.com/learning-lab/react/colors/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.creative-tim.com/learning-lab/react/grid/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.creative-tim.com/learning-lab/react/borders/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.creative-tim.com/learning-lab/react/functions/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/soft-ui-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <CustomerSupport size="12px" />,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Select",
  //       key: "select",
  //       href: "https://www.creative-tim.com/learning-lab/react/select/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Tag Input",
  //       key: "tag-input",
  //       href: "https://www.creative-tim.com/learning-lab/react/tag-input/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-soft-ui-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <CreditCard size="12px" />,
  //   noCollapse: true,
  // },
];

export default routes;
