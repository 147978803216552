
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import FormField from "layouts/pages/users/new-user/components/FormField";
import { useSelector, useDispatch } from "react-redux";
import { setErrors } from "store/slices/userSlice";
import { useEffect } from "react";


function GSTINInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { GSTINNumber, GSTINUsername } = formField;
  const {
    GSTINNumber: GSTINNumberV,
    GSTINUsername: GSTINUsernameV,
  } = values;

  // const reduxErrors = useSelector((state) => state.user.errors);

  // const errors = {
  //   ...formData.errors,
  //   GSTINNumber: reduxErrors?.GSTINNumber || formData.errors.GSTINNumber,
  //   GSTINUsername: reduxErrors?.GSTINUsername || formData.errors.GSTINUsername,
  // };

  // console.log("GSTINInfo", errors);

  return (
    <SoftBox>
      <SoftBox lineHeight={0} mt={2} mb={5}>
        {/* <SoftTypography variant="h5" fontWeight="bold">
          GSTIN details of your company
        </SoftTypography> */}
        <SoftTypography variant="button" fontWeight="regular" color="text">
        Please provide your GSTIN along with a username for identification purposes.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={GSTINNumber.type}
              label={GSTINNumber.label}
              name={GSTINNumber.name}
              value={GSTINNumberV}
              placeholder={GSTINNumber.placeholder}
              error={errors.GSTINNumber && touched.GSTINNumber}
              success={GSTINNumberV.length > 0 && !errors.GSTINNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={GSTINUsername.type}
              label={GSTINUsername.label}
              name={GSTINUsername.name}
              value={GSTINUsernameV}
              placeholder={GSTINUsername.placeholder}
              error={errors.GSTINUsername && touched.GSTINUsername}
              success={GSTINUsernameV.length > 0 && !errors.GSTINUsername}
            />
          </Grid>
        </Grid>

      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for GSTINInfo
GSTINInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default GSTINInfo;
