import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { productsListFetch } from "api/Products";
import Loader from "common/loader";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import InvoiceCell from "layouts/invoices/invoice/components/invoiceCell";
import "layouts/invoices/invoice/invoices.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


function ProductsList() {
  const columns = [
    {
      Header: "product name",
      accessor: "name",
      width: "20%",
      Cell: ({ value: [name, data], row }) => (
        <Link to={"/product/view/" + row.original.id}><InvoiceCell name={name} checked={data.checked} /></Link>
      ),
    },
    {
      Header: "hsn",
      accessor: "hsn",
      width: "20%",
    },
    {
      Header: "taxes",
      accessor: "taxes",
      width: "20%",
      Cell: ({ value, row }) => (
        <SoftBox display="flex">
          {
            value?.map((tax) => (
              <SoftBadgeDot
                badgeContent={tax.name_disp + ": " + parseFloat(tax.rate) + "%"}
                color="success"
                size="md"
                minWidth="max-content"
              />
            ))
          }
        </SoftBox>
      ),
    },
  ];

  const [tableData, setTableData] = useState({
    columns: columns,
    rows: [],
  });

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingProductList, setLoadingProductList] = useState(true);

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );
  const fromDateFun = (data) => {
    setStartDate(data);
    console.log("setStartDate", data)
  }
  const toDateFun = (data) => {
    setEndDate(data);
    console.log("setEndDate", data)
  }
  useEffect(() => {
    if (startDate != "" && endDate != "") {
      getData();
    }
  }, [startDate, endDate])

  const getData = async () => {
    try {
      const response = await productsListFetch(currentBusinessID);
      if (response && response.data.count >= 0) {
        const products = response.data.results;
        let products_rows = products?.map((product) => ({
          ...product,
          name: [product.name, { checked: false }],
        }));

        setTableData({
          columns: columns,
          rows: products_rows,
        });

      } else {
        console.log("No product found");
      }
    } catch (error) {
      console.log("error", error);

    }

    setLoadingProductList(false)
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>

      <DashboardNavbar />
      <Loader open={loadingProductList} />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >

            <Stack spacing={1} direction="row">
              <Link to="/products/create">
                <SoftButton variant="gradient" color="success" size="medium">
                  <SoftTypography variant="button" fontWeight="bold" color="dark" textTransform="uppercase">
                    create new product
                  </SoftTypography>
                </SoftButton>
              </Link>
            </Stack>

          </SoftBox>
          {tableData && (
            <DataTable
              table={tableData}
              pagination={
                {
                  color: "dark"
                }
              }
              entriesPerPage={{
                defaultValue: 10,
                entries: [10, 20, 50],
              }}
              canSearch={true}
              canFilter={false}
              fromDateProps={fromDateFun}
              toDateProps={toDateFun}
              noDataText={"No product found"}
            />
          )}
        </Card>

      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductsList;
