import * as React from "react";

import { useDispatch } from "react-redux";
import { toasterShow } from "store/slices/userSlice";
import SoftSnackbar from "components/SoftSnackbar";

export const ToasterMessage = ({ open, color, message, title, icon }) => {
  if (!icon) {
    icon = "error";
  }

  const dispatch = useDispatch();

  React.useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        dispatch(
          toasterShow({
            toasterToggle: false,
          })
        );
      }, 10000); // close after 10 seconds
    }
    return () => clearTimeout(timer);
  }, [open, dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      toasterShow({
        toasterToggle: false,
      })
    );
  };

  return (
    <SoftSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      close={handleClose}
      bgWhite={false}
    />
  );
};
