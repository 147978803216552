
import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

import FormField from "layouts/pages/users/new-user/components/FormField";

function VerifyOTP({ formData }) {
  const [state, setState] = useState("...");
  const { formField, values, errors, touched } = formData;
  const { OTP } = formField;
  const { OTP: OTPV } = values;

  const handleSetState = (event) => setState(event.target.value);

  return (
    <SoftBox>
      <SoftTypography variant="h5" fontWeight="bold">
        Please enter your OTP
      </SoftTypography>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={OTP.type}
              label={OTP.label}
              name={OTP.name}
              value={OTPV}
              placeholder={OTP.placeholder}
              error={errors.OTP && touched.OTP}
              success={OTPV.length > 0 && !errors.OTP}
            />
          </Grid>

        </Grid>

      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for VerifyOTP
VerifyOTP.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default VerifyOTP;
