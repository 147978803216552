import React, { useEffect, useState } from "react";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SoftDrawer from "components/SoftDrawer";
import Slider from "@mui/material/Slider";
import SoftInput from "../../../../../components/SoftInput";
import Grid from "@mui/material/Grid";
import SoftDatePicker from "../../../../../components/SoftDatePicker";


const Filters = (props) => {
  const { open, onClose, initialFilters, filters, setFilters } = props;

  const [values, setValues] = useState(filters);

  const [totalAmountSliderValue, setTotalAmountSliderValue] = React.useState([filters.min_total_amount, filters.max_total_amount]);

  useEffect(() => {
    setTotalAmountSliderValue([initialFilters.min_total_amount, initialFilters.max_total_amount])
  }, [initialFilters]);

  const handleTotalAmountSliderChange = (event, newValue) => {
    setTotalAmountSliderValue(newValue);
    setValues({ ...values, min_total_amount: newValue[0], max_total_amount: newValue[1] });
  };

  const handleCloseConfigurator = () => {
    onClose();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleCreditNoteDateChange = (newValue) => {
    let from_date = "";
    let to_date = "";
    if (Array.isArray(newValue) && newValue.length === 2) {
      from_date = newValue[0].getFullYear() + "-" + (newValue[0].getMonth() + 1) + "-" + newValue[0].getDate();
      to_date = newValue[1].getFullYear() + "-" + (newValue[1].getMonth() + 1) + "-" + newValue[1].getDate();
    }
    setValues({ ...values, from_date: from_date, to_date: to_date });
  };

  const resetFilters = () => {
    setFilters(initialFilters);
    setTotalAmountSliderValue([initialFilters.min_total_amount, initialFilters.max_total_amount]);
    setValues(initialFilters);
  }

  return (
    <SoftDrawer anchor="right" open={open} onClose={onClose} ownerState={open}>
      <SoftBox width="100%">
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0.8}
          px={3}
        >
          <SoftBox>
            <SoftTypography variant="h5">Filters</SoftTypography>
          </SoftBox>
          <Icon
            sx={({
                   typography: { size, fontWeightBold },
                   palette: { dark },
                 }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseConfigurator}
          >
            close
          </Icon>
        </SoftBox>

        <Divider />
        <SoftBox px={3}>
          <SoftBox>
            <SoftTypography variant="h6">Credit Note Number</SoftTypography>
            <SoftBox mb={1}></SoftBox>
          </SoftBox>

          <SoftBox lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <SoftInput
              type="text"
              placeholder="CRD00001"
              defaultValue={filters.credit_note_number}
              onChange={handleChange('credit_note_number')}
            />
          </SoftBox>
        </SoftBox>

        <Divider />
        <SoftBox px={3}>
          <SoftBox>
            <SoftTypography variant="h6">Invoice Number</SoftTypography>
            <SoftBox mb={1}></SoftBox>
          </SoftBox>

          <SoftBox lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <SoftInput
              type="text"
              placeholder="INV00001"
              defaultValue={filters.invoice_number}
              onChange={handleChange('invoice_number')}
            />
          </SoftBox>
        </SoftBox>

        <Divider />
        <SoftBox px={3}>
          <SoftBox>
            <SoftTypography variant="h6">Credit Note Date</SoftTypography>
            <SoftBox mb={0.5}></SoftBox>
          </SoftBox>

          <SoftBox lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <SoftDatePicker
              options={{ mode: "range" }}
              onChange={handleCreditNoteDateChange}
              defaultValue={(filters.from_date && filters.to_date) ? filters.from_date + " to " + filters.to_date : ""}
            />
          </SoftBox>
        </SoftBox>


        <Divider />
        <SoftBox px={3}>
          <SoftBox>
            <SoftTypography variant="h6">Buyer</SoftTypography>
            <SoftBox mb={0.5}></SoftBox>
          </SoftBox>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <SoftBox>
                <SoftTypography variant="overline">GSTIN</SoftTypography>
              </SoftBox>
              <SoftBox lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <SoftInput
                  type="text"
                  placeholder="22AAAAA0000A1Z5"
                  defaultValue={filters.buyer_gst}
                  onChange={handleChange('buyer_gst')}
                />
              </SoftBox>
            </Grid>
            <Grid item md={6}>
              <SoftBox>
                <SoftTypography variant="overline">Name</SoftTypography>
              </SoftBox>
              <SoftBox lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <SoftInput
                  type="text"
                  defaultValue={filters.buyer_name}
                  onChange={handleChange('buyer_name')}
                />
              </SoftBox>
            </Grid>
            <Grid item md={6}>
              <SoftBox>
                <SoftTypography variant="overline">Email</SoftTypography>
              </SoftBox>
              <SoftBox lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <SoftInput
                  type="text"
                  defaultValue={filters.buyer_email}
                  onChange={handleChange('buyer_email')}
                />
              </SoftBox>
            </Grid>
            <Grid item md={6}>
              <SoftBox>
                <SoftTypography variant="overline">Mobile</SoftTypography>
              </SoftBox>
              <SoftBox lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <SoftInput
                  type="text"
                  defaultValue={filters.buyer_mobile}
                  onChange={handleChange('buyer_mobile')}
                />
              </SoftBox>
            </Grid>

          </Grid>
        </SoftBox>


        <Divider />
        <SoftBox px={3}>
          <SoftBox>
            <SoftTypography variant="h6"> Total Amount </SoftTypography>
            <SoftBox mb={0.5}></SoftBox>
          </SoftBox>


          <SoftBox mt={3} lineHeight={1} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <Slider
              min={initialFilters.min_total_amount}
              max={initialFilters.max_total_amount}
              step={500}
              value={totalAmountSliderValue}
              valueLabelFormat={(value) => (<SoftTypography variant={"caption"} display="flex"
                                                            alignItems="center"><Icon>currency_rupee</Icon>{value}
              </SoftTypography>)}
              onChange={handleTotalAmountSliderChange}
              size={"small"}
              valueLabelDisplay={"on"}
              disableSwap
            />
          </SoftBox>
        </SoftBox>


        <Divider />
        <SoftBox mt={3} lineHeight={1}
                 sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'column' }}>
          <SoftBox>
            <SoftButton sx={{ marginRight: 2 }} variant="gradient" color="secondary" onClick={() => {
              resetFilters(values);
              handleCloseConfigurator();
            }}>
              Clear
            </SoftButton>
            <SoftButton variant="gradient" color="secondary" onClick={() => {
              setFilters(values);
              handleCloseConfigurator();
            }}>
              Apply
            </SoftButton>
          </SoftBox>
        </SoftBox>

      </SoftBox>
    </SoftDrawer>
  );
};

export default Filters;
