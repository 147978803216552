import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import HasBusinessDetails from "./hasBussinessDetails";
import SoftDialog from "components/SoftDialog";
import { useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";

import SoftDatePicker from "components/SoftDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormField from "layouts/pages/account/components/FormField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const addNewCustomerSchema = Yup.object({
  gstin: Yup.number().required("please enter your gstin number"),
});

const initialValues = {
  gstin: "",
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

export default function HasActiveBussiness(props) {
  const [radioValue, setRadioValue] = React.useState("yes");
  const [gstValue, setGstValue] = React.useState("");
  const [gstsave, setGstsave] = React.useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addNewCustomerSchema,
      onSubmit: (values, action) => {
        setGstValue(values);
        setGstsave(true);
      },
    });
  const radioHandleChange = (event) => {
    setRadioValue(event.target.value);
  };
  const oncloseModal = () => {
    props.oncloseModal(false);
  };

  return (
          <SoftBox component="form" pb={3} px={3}>
            <SoftBox
              display="flex"
              flexDirection="center"
              justifyContent="center"
              height="100%"
            >
              <SoftBox mb={1} mt={2.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Enter your GSTIN
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <FormField placeholder="22AAAAA0000A1Z5" />
              </SoftBox>
            </SoftBox>
            <SoftBox
              mt={2}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <Divider
                light
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "70%",
                }}
              >
                <SoftTypography
                  variant="text"
                  fontWeight="bold"
                  color="dark"
                  size="large"
                >
                  OR
                </SoftTypography>
              </Divider>
            </SoftBox>

            <SoftBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Company Name"
                    placeholder="GOSETA Pvt Ltd"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Company Address"
                    placeholder="GOSETA Pvt Ltd"
                  />
                </Grid>
              </Grid>
            </SoftBox>

            <SoftBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormField label="Company PAN" placeholder="ABCDE1234F" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField label="Company Mobile" placeholder="9999999999" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Company E-mail"
                    placeholder="mycompany@gmail.com"
                  />
                </Grid>
              </Grid>
            </SoftBox>

            <SoftBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormField label="PIN Code" placeholder="560067" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField label="City" placeholder="Bengaluru" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField label="State" placeholder="Karnataka" />
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
  );
}
