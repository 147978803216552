import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SearchExistingProduct({ productData, placeholder }) { 
    const [arrayval,setArrayval] =React.useState([]);
    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={productData}
            disableCloseOnSelect
            getOptionLabel={(option) => option.customer}

            renderTags={(tagValue, getTagProps) => {
                return (
                    <div className='all-tags-customer'>
                        {
                            tagValue.map((option, index) => {
                                return (
                                    <Chip label={option.customer}
                                        {...getTagProps({ index })}
                                    />
                                )
                            })
                        }
                    </div>
                )
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                     <div className="label-list">
                        <label>{option.customer}</label> <br />
                        <label>{option.businessCode} <small>{option.bussiness}</small></label>
                    </div>
                    <div className="value-display">
                        {option.price}
                    </div>
                   
                </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
                <TextField {...params} placeholder={placeholder} />
            )}
        />
    );
}

