import { makeRequest, withAPI } from "api";

export const AddNewBusiness = withAPI(async function (payload) {
  const token = localStorage.getItem("token");
  const additional_details = {};
  if (payload.companyGSTIN) {
    additional_details.gstin = payload.companyGSTIN;
  }
  if (payload.companyPAN) {
    additional_details.pan = payload.companyPAN;
  }
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`
    },
    data: JSON.stringify({
      "name": payload.companyName,
      "mobile": payload.companyPhone,
      "email": payload.companyEmail,
      "addresses": [
        {
          "address_type": 1,
          "address": payload.companyAddress,
          "pincode": payload.companyPINCODE,
        }
      ],
      "additional_details": additional_details
    }),
  };
  const data = await makeRequest("api/v1/business/", options);
  return data;
});