/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import breakpoints from "assets/theme/base/breakpoints";
import SoftTypography from "components/SoftTypography";
// Settings page components
import GstFileField from "./components/BasicInfo";

import dataTableData from "./table/data/dataTableData";
import { useState } from "react";
import TableGrid from "./table/invoiceList/table"
import { Card } from "@mui/material";
function Settings() {
  const { values } = breakpoints;
  const [tableData, setTableData] = useState(dataTableData);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <SoftBox mb={1} p={1}>
                <SoftTypography
                  variant={window.innerWidth < values.sm ? "h3" : "h2"}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  B2B Invoice Details
                </SoftTypography>
              </SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <SoftBox mb={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <GstFileField />
                      </Grid>
                    </Grid>
                  </SoftBox>
                </Grid>
              </Grid>
    

              <Card mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    {
                      tableData.rows.length > 0 ? (
                        <>
                          <Grid container spacing={1} className="total-due">
                            <Grid item xs={12}>
                              <SoftTypography
                                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                                textTransform="capitalize"
                                fontWeight="bold"
                              >
                                B2B  Invoice Details
                              </SoftTypography>
                            </Grid>
                          </Grid>
                          <TableGrid columns={tableData.columns} rows={tableData.rows} />
                        </>
                      ) : (
                        <h2> No Data Here... </h2>
                      )
                    }



                  </Grid>
                </Grid>
              </Card>






            </Grid>
          </Grid>
        </SoftBox>
      </DashboardLayout>



    </>
  );
}

export default Settings;
