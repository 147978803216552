import * as React from 'react';
import { useState } from 'react';
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// NewProduct page components
import { useFormik } from 'formik';
import Label from '../../../invoices/invoice/common/commonlabel';
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from 'formik';


const addNewCustomerSchema = Yup.object({
    email: Yup.string().email().required("please enter your email"),
    address: Yup.string().min(2).max(25).required("please enter your business name"),
    natureBusiness: Yup.string().min(2).max(25).required("please enter your business name"),
    phonenumber: Yup.number().min(10).max(10).required("please enter your phone number"),
    gstin: Yup.number().required("please enter your gstin number"),
    pan: Yup.string().min(2).max(25).required("please enter the  name"),
    BusinessName: Yup.string().min(2).max(25).required("please enter your business name"),
});

const initialValues = {
    email: '',
    address: '',
    natureBusiness: '',
    phonenumber: '',
    gstin: '',
    pan: '',
    BusinessName: ''
}
export default function HasBusinessDetails(props) {
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: addNewCustomerSchema,
        onSubmit: (values, action) => {
            console.log("values", values);
            action.resetForm();
        }
    });
    return (
        <>
            <SoftBox className='entered-gst-sec'>
                <SoftTypography variant="h5" mt={2.5}>ENTER YOUR DETAILS</SoftTypography>
                <SoftTypography variant="body2">
                    One thing I love about the later sunsets is the chance to go for a walk through the neighborhood woods before dinner
                </SoftTypography>
            </SoftBox>
            <form onSubmit={handleSubmit} className='formp'>
                <SoftBox mt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Label label="Business Name" />
                            <SoftInput
                                id="BusinessName"
                                name="BusinessName"
                                variant="filled"
                                className='w-100'
                                margin="normal"
                                value={values.BusinessName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Business Name"
                                type="text"
                                error={(errors.BusinessName && touched.BusinessName) ? errors.BusinessName : false}
                                helperText={(errors.BusinessName && touched.BusinessName) ? errors.BusinessName : null}
                            />
                            <p>
                                {errors.BusinessName && touched.BusinessName && errors.BusinessName}
                            </p>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Label label="Pan" />
                            <SoftInput
                                id="pan"
                                name="pan"
                                variant="filled"
                                className='w-100'
                                margin="normal"
                                value={values.pan}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="FGANP"
                                type="text"
                                error={(errors.pan && touched.pan) ? errors.pan : false}
                                helperText={(errors.pan && touched.pan) ? errors.pan : null}
                            />
                            <p>
                                {errors.pan && touched.pan && errors.pan}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label label="GSTIN" />
                            <SoftInput
                                id="gstin"
                                name="gstin"
                                variant="filled"
                                className='w-100'
                                margin="normal"
                                value={values.gstin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={(errors.gstin && touched.gstin) ? errors.gstin : false}
                                helperText={(errors.gstin && touched.gstin) ? errors.gstin : null}
                            />
                            <p>
                                {errors.gstin && touched.gstin && errors.gstin}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label label="phone Number" />
                            <SoftInput
                                id="phonenumber"
                                name="phonenumber"
                                variant="filled"
                                className='w-100'
                                margin="normal"
                                value={values.phonenumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                error={(errors.phonenumber && touched.phonenumber) ? errors.phonenumber : false}
                                helperText={(errors.phonenumber && touched.phonenumber) ? errors.phonenumber : null}
                            />
                            <p>
                                {errors.phonenumber && touched.phonenumber && errors.phonenumber}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label label="Nature Of Business" />
                            <SoftInput
                                id="natureBusiness"
                                name="natureBusiness"
                                variant="filled"
                                className='w-100'
                                margin="normal"
                                value={values.natureBusiness}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={(errors.natureBusiness && touched.natureBusiness) ? errors.natureBusiness : false}
                                helperText={(errors.natureBusiness && touched.natureBusiness) ? errors.natureBusiness : null}
                            />
                            <p>
                                {errors.natureBusiness && touched.natureBusiness && errors.natureBusiness}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label label="Address" />
                            <SoftInput
                                id="address"
                                name="address"
                                variant="filled"
                                className='w-100'
                                margin="normal"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={(errors.address && touched.address) ? errors.address : false}
                                helperText={(errors.address && touched.address) ? errors.address : null}
                            />
                            <p>
                                {errors.address && touched.address && errors.address}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label label="Email" />
                            <SoftInput
                                id="email"
                                label="email"
                                name="email"
                                variant="filled"
                                className='w-100'
                                margin="normal"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter email address"
                                type="email"
                                error={(errors.email && touched.email) ? errors.email : false}
                                helperText={(errors.email && touched.email) ? errors.email : null}
                            />
                            <p>
                                {errors.email && touched.email && errors.email}
                            </p>
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox className="dislog-footer">
                    <SoftButton type="submit" variant="contained" color="info">Submit</SoftButton>
                </SoftBox>
            </form>
        </>
    );
}