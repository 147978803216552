import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultNavbarMenu from "examples/Navbars/DefaultNavbar/DefaultNavbarMenu";
import curved8 from "assets/images/curved-images/curved8.jpg";

function AuthenticationMenu({ routes, open, close, mobileMenu }) {
  const renderAuthenticationMenuRoute = (routeName) =>
    routes.map(({ key, name, collapse }) => {
      let template;

      if (key === routeName && !mobileMenu) {
        template = (
          <MenuItem key={key}>
            {name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Icon sx={{ fontWeight: "bold", ml: "auto" }}>chevron_right</Icon>
            <AuthenticationMenuCollapse collapse={collapse} close={close} mobileMenu={mobileMenu} />
          </MenuItem>
        );
      } else if (key === routeName && mobileMenu) {
        template = (
          <SoftBox key={key} pr={2} mt={0} mb={2}>
            <SoftTypography variant="h6" fontWeight="bold" gutterBottom>
              {name}
            </SoftTypography>
            <AuthenticationMenuCollapse collapse={collapse} close={close} mobileMenu={mobileMenu} />
          </SoftBox>
        );
      }

      return template;
    });

  const renderMenuContent = (
    <SoftBox display={mobileMenu ? "block" : "flex"}>
      {!mobileMenu && (
        <SoftBox
          width="10rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          borderRadius="lg"
          overflow="hidden"
        >
          <SoftBox
            component="img"
            src={curved8}
            alt="background-image"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
          />
          <SoftBox
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            opacity={0.8}
            bgColor="info"
            variant="gradient"
          />
          <SoftBox position="relative" textAlign="center">
            <SoftBox
              bgColor="white"
              width="3rem"
              height="3rem"
              borderRadius="50%"
              shadow="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mx="auto"
              mb={1}
            >
              <Icon
                sx={({ functions: { linearGradient }, palette: { gradients, transparent } }) => ({
                  backgroundImage: `${linearGradient(
                    gradients.info.main,
                    gradients.info.state
                  )} !important`,
                  WebkitBackgroundClip: "text !important",
                  WebkitTextFillColor: `${transparent.main} !important`,
                })}
              >
                star
              </Icon>
            </SoftBox>
            <SoftTypography variant="body1" fontWeight="medium" color="white">
              Explore our utilities pages
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      <SoftBox py={1} pl={2}>
        {renderAuthenticationMenuRoute("sign-in")}
        {renderAuthenticationMenuRoute("sign-up")}
        {renderAuthenticationMenuRoute("reset-password")}
        {renderAuthenticationMenuRoute("lock")}
        {renderAuthenticationMenuRoute("2-step-verification")}
        {renderAuthenticationMenuRoute("error")}
      </SoftBox>
    </SoftBox>
  );

  return mobileMenu ? (
    renderMenuContent
  ) : (
    <DefaultNavbarMenu open={open} close={close}>
      {renderMenuContent}
    </DefaultNavbarMenu>
  );
}

function AuthenticationMenuCollapse({ collapse, close, mobileMenu }) {
  const [menu, setMenu] = useState(false);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(false);

  return (
    <DefaultNavbarMenu
      placement="right-start"
      open={menu}
      close={closeMenu}
      style={{ paddingLeft: "1.25rem" }}
    >
      {collapse.map(({ key: collapseKey, name: collapseName, route }) => (
        <MenuItem
          component={Link}
          to={route}
          key={collapseKey}
          onClick={mobileMenu ? undefined : close}
        >
          {collapseName}
        </MenuItem>
      ))}
    </DefaultNavbarMenu>
  );
}

AuthenticationMenuCollapse.propTypes = {
  collapse: PropTypes.arrayOf(PropTypes.object).isRequired,
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  mobileMenu: PropTypes.bool,
};

export default AuthenticationMenu;