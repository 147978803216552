import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import GstFileField from "./components/BasicInfo";
import Sidenav from "./components/BasicInfo/Sidenav/index";
import FileReturn from "./components/BasicInfo/fileReturnTab";
import breakpoints from "assets/theme/base/breakpoints";
import { GSTRSummary, GSTRReturnTrack } from "api/Business/GSTAuthentication";
import { toasterShow } from "store/slices/userSlice";
import { setSelectedBusinessInfo } from "store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "common/loader";
import { loaderShow } from "store/slices/commonUISlice";
import SoftSelect from "components/SoftSelect";
import { useParams } from "react-router-dom";

// Data
import selectData from "./components/BasicInfo/data/selectData";
import SoftButton from "components/SoftButton";

const StyledTableCell = TableCell;

const isObject = (value) => typeof value === "object" && value !== null;

const renderTableCell = (value, level = 0) => {
  if (isObject(value)) {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="nested table">
          <TableBody>
            {Object.keys(value).map((nestedKey) => (
              <TableRow key={nestedKey}>
                <TableCell style={{ paddingLeft: `${level * 20}px` }}>
                  {nestedKey}
                </TableCell>
                <TableCell>
                  {renderTableCell(value[nestedKey], level + 1)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return value;
};

function Settings() {
  const { values } = breakpoints;
  const [jsonData, setJsonData] = useState(null);
  const dispatch = useDispatch();
  const businessId = useSelector((state) => state.user.selectedBusinessID);
  const [loading, setLoading] = useState(true);
  const selectedBusinessInfo = useSelector((state) => state.user);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(
    selectData.financialYear[selectData.financialYear.length - 1].value
  );
  const [returnsData, setReturnsData] = useState([]);
  const { returnType, returnPeriod } = useParams();

  const navigate = useNavigate();

  const currentBusiness = useSelector((state) => state.user.selectedBusiness);

  const getGSTRSummary = useCallback(async () => {
    const formData = {
      businessId: businessId,
      RetPeriod: returnPeriod,
      RetType: returnType,
    };
    try {
      const response = await GSTRSummary(formData);

      if (response.success === true) {
        setReturnsData(response.data.sec_sum);
        console.log("response.data", response.data);
      } else if (response.data.error_cd == "AUTH158") {
        // set isGstLoggedIn to false
        dispatch(
          setSelectedBusinessInfo({
            ...selectedBusinessInfo,
            GSTloggedIn: false,
          })
        );

        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: "Please login to GST portal again",
            toasterToggle: true,
            toasterTitle: "Auth token expired",
          })
        );
        navigate("/gst/login");
      } else {
        dispatch(
          toasterShow({
            toasterColor: "error",
            toasterMessage: response.data.message,
            toasterToggle: true,
            toasterTitle: "Error getting data from GSP",
          })
        );
      }
    } catch (error) {
      dispatch(
        toasterShow({
          toasterColor: "error",
          toasterMessage: error.message,
          toasterToggle: true,
          toasterTitle: "Error getting data from GSP",
        })
      );
    }
    setLoading(false);
  }, [
    businessId,
    selectedFinancialYear,
    dispatch,
    selectedBusinessInfo,
    navigate,
  ]);

  useEffect(() => {
    getGSTRSummary();
  }, []);

  const handleButtonClick = () => {
    setLoading(true);
    getGSTRSummary();
  };

  const setFinancialYear = (year) => {
    setSelectedFinancialYear(year.value);
    console.log("year", selectedFinancialYear);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader open={loading} />

      <SoftBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            {/* <SoftBox mb={1} p={1}>
              <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                Return Track
              </SoftTypography>
            </SoftBox> */}



            <SoftBox mt={4}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} lg={3}>
                    <Card>
                      <SoftBox p={2}>
                        <Sidenav />
                      </SoftBox>
                    </Card>
                  </Grid> */}
                <Grid item xs={12} lg={12}>
                  <Card>
                    <SoftBox p={2}>
                      <FileReturn data={returnsData} returnPeriod={returnPeriod} returnType={returnType} />
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>

            {/* <SoftBox mt={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <div>
                    <h1>Your Table</h1>
                    {jsonData && (
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {Object.keys(jsonData[0]).map((key) => (
                                <StyledTableCell key={key}>{key}</StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {jsonData.map((row, index) => (
                              <TableRow key={index}>
                                {Object.keys(row).map((key) => (
                                  <StyledTableCell key={key}>{renderTableCell(row[key])}</StyledTableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                </Grid>
              </Grid>
            </SoftBox> */}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
