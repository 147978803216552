import { CurrencyRupee } from "@mui/icons-material";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { creditNotesListFetch, fetchCreditNoteFiltersConfig } from "api/credit_notes";
import Loader from "common/loader";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import InvoiceCell from "layouts/invoices/invoice/components/invoiceCell";
import "layouts/invoices/invoice/invoices.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Filters from "../components/ListViewFilters";


function CreditNotesList() {
  const columns = [
    {
      Header: "credit note no.",
      accessor: "credit_note_no",
      width: "12%",
      Cell: ({ value: [name, data], row }) => (
        <Link to={"/credit-note/view/" + row.original.credit_note_id}><InvoiceCell name={name} checked={data.checked} /></Link>
      ),
    },
    {
      Header: "invoice no.",
      accessor: "invoice_no",
      width: "12%",
      Cell: ({ value, row }) => (
        <Link to={"/invoice/view/" + row.original.invoice_id}><SoftTypography mx={2} variant="button" fontWeight="medium">{value}</SoftTypography></Link>
      ),
    },
    {
      Header: "buyer",
      accessor: "buyer",
      width: "20%",
    },
    { Header: "date", accessor: "date" },
    { Header: "due date", accessor: "due_date" },
    {
      Header: "total amount",
      accessor: "total_amount",
      Cell: ({ value, row }) => (
        <SoftBox display="flex" alignItems="center"><CurrencyRupee />
          {(parseFloat(value) + parseFloat(row.original.tax)).toFixed(2)}
        </SoftBox>
      ),
    },
  ];

  const [tableData, setTableData] = useState({
    columns: columns,
    rows: [],
  });
  const _initialFilters = {
    "credit_note_number": "",
    "invoice_number": "",
    "buyer_gst": "",
    "buyer_name": "",
    "buyer_email": "",
    "buyer_mobile": "",
    "min_total_amount": 0,
    "max_total_amount": 10000,
    "from_date": "",
    "to_date": "",
  };
  const [initialFilters, setInitialFilters] = useState(_initialFilters);
  const [filters, setFilters] = useState(_initialFilters);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingCreditNoteList, setLoadingCreditNoteList] = useState(true);

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );
  const fromDateFun = (data) => {
    setStartDate(data);
    console.log("setStartDate", data)
  }
  const toDateFun = (data) => {
    setEndDate(data);
    console.log("setEndDate", data)
  }
  useEffect(() => {
    if (startDate != "" && endDate != "") {
      getData();
    }
  }, [startDate, endDate])

  const loadFiltersConfig = async () => {
    setLoadingCreditNoteList(true)

    try {
      const response = await fetchCreditNoteFiltersConfig(currentBusinessID);
      if (response && response.success === true) {
        let _min_total_amount = response.data.total_amount.min;
        let _max_total_amount = response.data.total_amount.max;
        if (_min_total_amount === _max_total_amount) {
          _min_total_amount = 0;
        }

        const _filters = {
          ...initialFilters,
          "min_total_amount": _min_total_amount,
          "max_total_amount": _max_total_amount,
        }
        setInitialFilters(_filters)
        setFilters(_filters)
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoadingCreditNoteList(false)
  };

  useEffect(() => {
    loadFiltersConfig()
  }, []);

  const getData = async () => {
    try {
      const response = await creditNotesListFetch(currentBusinessID, filters);
      if (response && response.data.count >= 0) {
        const credit_notes = response.data.results;
        let credit_notes_rows = credit_notes?.map((credit_note) => ({
          credit_note_id: credit_note.id,
          credit_note_no: [credit_note.credit_note_number, { checked: false }],
          invoice_id: credit_note.invoice?.id,
          invoice_no: credit_note.invoice?.invoice_number,
          buyer: credit_note.buyer.name,
          date: credit_note.date,
          due_date: credit_note.due_date,
          total_amount: credit_note.total_amount,
          tax: credit_note.items.reduce(
            (partialSum, item) => partialSum + (
              item.product.taxes.reduce(
                (itemTaxPartialSum, tax) => itemTaxPartialSum + item.quantity * parseFloat(item.unit_price) * parseFloat(tax.rate) / 100, 0
              ) || 0
            ), 0
          ) || 0,
        }));
        const fromdate = new Date(startDate);
        const todate = new Date(endDate);
        const finalCreditNoteRows = [];
        credit_notes_rows?.filter(credit_note => {
          const rowDate = new Date(credit_note.date);
          if (rowDate > fromdate && rowDate < todate) {
            finalCreditNoteRows.push(credit_note);
          }
        });
        if (finalCreditNoteRows.length > 0) {
          credit_notes_rows = finalCreditNoteRows
        }
        setTableData({
          columns: columns,
          rows: credit_notes_rows,
        });

      } else {
        console.log("No credit note found");
      }
    } catch (error) {
      console.log("error", error);

    }

    setLoadingCreditNoteList(false)
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let filtersCount = ['credit_note_number', 'invoice_number', 'buyer_gst', 'buyer_name', 'buyer_email', 'buyer_mobile'].filter(
      (filter) => initialFilters[filter] !== filters[filter]
    ).length;

    if (initialFilters.from_date !== filters.from_date || initialFilters.to_date !== filters.to_date) {
      filtersCount += 1
    }
    if (initialFilters.min_total_amount !== filters.min_total_amount || initialFilters.max_total_amount !== filters.max_total_amount) {
      filtersCount += 1
    }
    setAppliedFiltersCount(filtersCount)
  }, [filters]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <DashboardLayout>

      <DashboardNavbar />
      <Loader open={loadingCreditNoteList} />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >

            <Stack spacing={1} direction="row">
              <Link to="/credit-notes/create">
                <SoftButton variant="gradient" color="success" size="medium">
                  <SoftTypography variant="button" fontWeight="bold" color="dark" textTransform="uppercase">
                    create new credit note
                  </SoftTypography>
                </SoftButton>
              </Link>
            </Stack>

            <Stack spacing={1} direction="row">
              <SoftBadge badgeContent={appliedFiltersCount} circular>
                <SoftButton variant="outlined" color="dark" size="small"
                  onClick={handleOpenDrawer}>
                  Filters
                </SoftButton>
              </SoftBadge>
            </Stack>
          </SoftBox>
          {tableData && (
            <DataTable
              table={tableData}
              pagination={
                {
                  color: "dark"
                }
              }
              entriesPerPage={{
                defaultValue: 10,
                entries: [10, 20, 50],
              }}
              canSearch={true}
              canFilter={false}
              fromDateProps={fromDateFun}
              toDateProps={toDateFun}
              noDataText={"No credit note found"}
            />
          )}
        </Card>
        <Filters
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
          initialFilters={initialFilters}
          filters={filters}
          setFilters={setFilters}
        />

      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreditNotesList;
