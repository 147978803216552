import { makeRequest, withAPI } from "api";

export const CityDetailsUsingPincode = withAPI(async function (pincode) {
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
    },
    };
    const data = await makeRequest(`api/v1/address/pincode/${pincode}/`, options);
    return data;
  });
