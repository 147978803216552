import FormHelperText from "@mui/material/FormHelperText";
import { addTermsAndConditions as addTermsAndConditionsAPI } from "api/TermsAndConditions";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftEditor from "components/SoftEditor";
import SoftTypography from "components/SoftTypography";
import { useFormik } from "formik";
import FormField from "layouts/pages/account/components/FormField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toasterShow } from "store/slices/userSlice";
import * as Yup from "yup";


export default function AddTermsAndConditions(props) {
    const { handleDialogClose } = props;
    const dispatch = useDispatch();
    let content = "";

    const [formData, setFormData] = useState({
        "title": "",
        "content": "",
    });
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        content: Yup.string(),
    });

    const currentBusinessID = useSelector(
        (state) => state.user.selectedBusinessID
    );

    const { values, setFieldValue, errors, touched, isValid, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: formData,
            validationSchema: validationSchema,
            onSubmit: async (values, action) => {
                try {
                    await addTermsAndConditionsAPI(values, currentBusinessID);
                    handleDialogClose();

                    dispatch(
                        toasterShow({
                            toasterColor: "success",
                            toasterMessage: "Terms and conditions added successfully",
                            toasterToggle: true,
                        })
                    );
                } catch (error) {
                    dispatch(
                        toasterShow({
                            toasterColor: "error",
                            toasterMessage: error.message,
                            toasterToggle: true,
                        })
                    );
                }
            },
            validateOnChange: true,
            validateOnBlur: true,
        });

    return (
        <SoftBox component="form" pb={3} px={3}>
            <form onSubmit={handleSubmit}>
                <SoftBox mt={2}>
                    <FormField
                        label="Title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                    />
                    <FormHelperText error={true}>{touched.title && errors.title}</FormHelperText>
                </SoftBox>
                <SoftBox mt={2}>
                    <FormField
                        multiline
                        type="text"
                        rows={5}
                        label="Terms and Conditions"
                        name="content"
                        value={values.content}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.content && Boolean(errors.content)}
                        helperText={touched.content && errors.content}
                    />
                    <FormHelperText error={true}>{touched.content && errors.content}</FormHelperText>
                </SoftBox>

                <SoftBox justifyContent="center" alignItems="center" display="flex" mt={4}>
                    <SoftButton onClick={handleSubmit} variant="contained" color="success">
                        Submit
                    </SoftButton>
                </SoftBox>
            </form>
        </SoftBox>
    );
}
