import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const CardConrol = ({ iconname, label1, label2, label3, filed, has_link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <SoftBox
      variant="gradient"
      className={filed ? "filedBg" : "notFiledBg"}
      width="100%"
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      shadow="md"
      p={2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Icon fontSize="medium">{iconname}</Icon>
      <SoftTypography component="div" className="filedcard">
        {label1} <br />
        {label2} <br />
        {label3} <br />
      </SoftTypography>
      {has_link && (
  <Icon fontSize={isHovered ? "large" : "small"}>arrow_forward_ios</Icon>
)}    </SoftBox>
  );
};

export default CardConrol;