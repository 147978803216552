// api.js
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function makeRequest(endpoint, options = {}) {
  const url = `${BASE_URL}/${endpoint}`;

  const token = localStorage.getItem("token");
  if (token) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers.Authorization = `Token ${token}`;
  }

  try {
    const response = await axios(url, options);
    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      return data;
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.status === 400
    ) {
      return error.response.data;
    } else if (error.response.status === 401) {
      localStorage.removeItem("token");
      throw new Error("Auth token invalid or expired");
    } else {
      throw new Error(`API request failed: ${error.message}`);
    }
  }
}

export function withAPI(apiFunction) {
  return async function (endpoint, options = {}) {
    const token = localStorage.getItem("token");
    if (token) {
      if (typeof options !== "object") {
        options = {};
      }
      options.headers = { Authorization: `Bearer ${token}` };
    }
    return apiFunction(endpoint, options);
  };
}

export const sendOTP = withAPI(async function (data) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  return makeRequest("auth/v2/api/v1/token/", options);
});

export const verifyOTP = withAPI(async function (data) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await makeRequest("auth/v2/api/v1/token/verify/", options);
  return response;
});

export const login = withAPI(async function (mobile, otp) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ mobile, otp }),
  };
  const data = await makeRequest("login", options);
  localStorage.setItem("token", data.token);
  return data;
});

export const logout = async function () {
  const options = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  const data = await makeRequest("auth/v2/api/v1/token/delete/", options);
  localStorage.removeItem("token");
  return data;
};

export const signup = withAPI(async function (mobile, otp) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ mobile, otp }),
  };
  const data = await makeRequest("signup", options);
  localStorage.setItem("token", data.token);
  return data;
});

export const businessFetch = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest("api/v1/business/", options);
};

export const editBusinessFetch = async (id) => {
  const editUrl = `api/v1/business/${id}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(editUrl, options);
};

export const deleteBusiness = async (id) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(`api/v1/business/${id}/`, options);
};

export const getCustomerDataFun = async (url) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(url, options);
};

export { makeRequest };
