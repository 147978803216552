import { makeRequest } from "api";

export const buyerListFetch = async (business_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(`api/v1/buyer?business=${business_id}`, options);
};

export const fetchBuyerDetails = async (business_id, buyer_id) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return makeRequest(`api/v1/buyer/${buyer_id}?business=${business_id}`, options);
};

export const addNewBuyer = async function (payload, business_id) {
  const additional_details = {};
  if (payload.companyGSTIN) {
    additional_details.gstin = payload.companyGSTIN;
  }
  if (payload.companyPAN) {
    additional_details.pan = payload.companyPAN;
  }
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      "name": payload.companyName,
      "mobile": payload.companyPhone,
      "email": payload.companyEmail,
      "addresses": [
        {
          "address_type": 1,
          "address": payload.companyAddress,
          "pincode": payload.companyPINCODE,
        }
      ],
      "additional_details": additional_details
    }),
  };
  const data = await makeRequest(`api/v1/buyer/?business=${business_id}`, options);
  return data;
};
