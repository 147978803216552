import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();

export default function SearchExistingCustomer(props) { 
    const [value, setValue] = React.useState(null)
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({
                        customer: newValue,
                    });
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                        customer: newValue.inputValue,
                    });
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        customer: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnClick
            // clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={props.customerData}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(props, option) => <li {...props}>
                <>
                    <div className="label-list">
                        <label>{option.name}</label> <br />
                        
                    </div>
                 
                </>
            </li>
            }
            sx={{ width: '100%' }}
            
            renderInput={(params) => (
                <TextField {...params}  placeholder={props.placeholder} />
            )}
        />
    );
}




