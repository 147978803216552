import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { setSelectedBusinessInfo } from "store/slices/userSlice";
import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

function BusinessListCard(props) {
  const { data, onClick, editModalOpenCallBack, handleDeleteBusiness } = props;

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );
  const [bgColor, setBgColor] = useState("grey-100");
  const dispatch = useDispatch();
  function handleClick() {
    onClick();
  }

  const selectedBusinessInfo = useSelector((state) => state.user);

  function update(id) {
    dispatch(
      setSelectedBusinessInfo({
        ...selectedBusinessInfo,
        selectedBusinessID: id,
      })
    );
    setBgColor("success");
    setTimeout(() => {
      handleClick();
    }, 100);
  }

  return (
    <>
      {data.map((business, index) => {
        console.log("business -- ", currentBusinessID);
        return (
          <SoftBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={currentBusinessID == business.id ? "success" : "grey-100"}
            variant="contained"
            borderRadius="lg"
            p={3}
            mb={props.noGutter ? 0 : 1}
            mt={2}
            key={index}
          >
            <SoftBox width="100%" display="flex" flexDirection="column">
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mb={2}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                  onClick={() => update(business.id)}
                  style={{ cursor: "pointer" }}
                >
                  {business.name}
                </SoftTypography>

                <SoftBox
                  display="flex"
                  alignItems="center"
                  mt={{ xs: 2, sm: 0 }}
                  ml={{ xs: -1.5, sm: 0 }}
                >
                  <SoftBox mr={1}>
                    <SoftButton variant="text" color="error" onClick={() => handleDeleteBusiness(business)}>
                      <Icon>delete</Icon>&nbsp;delete
                    </SoftButton>
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="dark"
                    onClick={(event) =>
                      editModalOpenCallBack(event, business.id)
                    }
                  >
                    <Icon>edit</Icon>&nbsp;edit
                  </SoftButton>
                </SoftBox>
              </SoftBox>
              <SoftBox mb={1} lineHeight={0}>
                <SoftTypography variant="caption" color="text">
                  Address :&nbsp;&nbsp;&nbsp;
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {business.addresses?.[0]?.address}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={1} lineHeight={0}>
                <SoftTypography variant="caption" color="text">
                  GSTIN :&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="caption" fontWeight="medium">
                    {business.additional_details?.gstin}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        );
      })}
    </>
  );
}

// Setting default values for the props of Bill
BusinessListCard.defaultProps = {
  noGutter: false,
  active: false,
};

// Typechecking props for the Bill
BusinessListCard.propTypes = {
  businessName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  gstin: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default BusinessListCard;
