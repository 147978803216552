import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "examples/Sidenav/styles/sidenavCollapse";
import { useSoftUIController } from "context";
import { useSelector } from 'react-redux';

function SidenavCollapse({ icon, name, children, active, noCollapse, open, locked, ...rest }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;

  const state = useSelector(state => state);

  const getStateByString = (state, string) => {
    const properties = string.split('.');
    const result = properties.reduce((obj, prop) => {
      return obj && obj[prop];
    }, state);
    return result;
  };

  const isStateTrue = locked ? getStateByString(state, locked.stateCheck) : false;

  const data = (locked && !isStateTrue) ? (
    <ListItem component="li">
      <SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
        <ListItemIcon
          sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, sidenavColor })}
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>
  
        <ListItemText
          primary={name}
          sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
        />
  
        <Icon
        >
          locked
        </Icon>
      </SoftBox>
    </ListItem>
  ) : (
    <ListItem component="li">
      <SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
        <ListItemIcon
          sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, sidenavColor })}
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>
  
        <ListItemText
          primary={name}
          sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
        />
  
        <Icon
          sx={(theme) =>
            collapseArrow(theme, { noCollapse, transparentSidenav, miniSidenav, open })
          }
        >
          expand_less
        </Icon>
      </SoftBox>
    </ListItem>
  );

  return (
    <>
      {data}
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

SidenavCollapse.defaultProps = {
  color: "info",
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;