// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

import { useEffect, useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { Add as AddIcon } from "@mui/icons-material";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import SoftDialog from "components/SoftDialog";
import BusinessDetails from "layouts/business/components/BusinessDetails";

function BusinessInformationCard(props) {
  const { details, values = {}, setFieldValue, handleBlur, touched = {}, errors = {} } = props;
  const [selectedBusiness, setSelectedBusiness] = useState(details);

  const { businessList, reloadBusinessList } = props;
  const buyersList = businessList.map((buyer) => ({
    label: buyer.name,
    value: buyer.id,
  }));

  const handleBusinessChange = (option) => {
    setSelectedBusiness(businessList.find((buyer) => buyer.id === option.value));
    setFieldValue("buyer", option.value);
  };

  useEffect(() => {
    if (values.buyer > -1) {
      setSelectedBusiness(businessList.find((buyer) => buyer.id === values.buyer));
    }
  }, [values.buyer])

  const [addBuyerOpen, setAddBuyerOpen] = useState(false);

  function handleAddBusinessOpen() {
    if (!addBuyerOpen) {
      setAddBuyerOpen(true);
    }
  }

  function handleAddBuyerClose() {
    setAddBuyerOpen(false);
    reloadBusinessList();
  }

  const renderAddBuyer = (
    <>
      <SoftDialog
        component={
          <BusinessDetails
            handleDialogClose={handleAddBuyerClose}
            loadEditData={false}
            addBuyerAPI={true}
          />
        }
        title={"Add a buyer"}
        handleOpen={handleAddBusinessOpen}
        handleClose={handleAddBuyerClose}
        open={addBuyerOpen}
        maxWidth="lg"
      />
    </>
  );

  return (
    <>
      <SoftBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        height="100%"
        bgColor="grey-100"
        borderRadius="lg"
        p={3}
      >

        <SoftBox
          width="100%"
          display="flex"
          flexDirection="column"
          lineHeight={1}
        >
          {!details ? (
            <SoftBox mb={2}>
              <Grid container>
                <Grid item md={7} mr={1}>
                  <SoftSelect
                    inputId="buyer"
                    placeholder="Select Buyer"
                    noOptionsMessage={() => "No Buyers"}
                    options={buyersList} mb={1}
                    value={buyersList.filter((option) => option.value === values.buyer)?.[0]}
                    onChange={handleBusinessChange}
                    onBlur={handleBlur}
                    error={touched.buyer && Boolean(errors.buyer)}
                  />
                </Grid>
                <Grid item md={4.5}>
                  <SoftButton
                    variant="gradient"
                    color="secondary"
                    onClick={handleAddBusinessOpen}
                  >
                    <AddIcon />&nbsp; Add Buyer
                    {renderAddBuyer}
                  </SoftButton>
                </Grid>
              </Grid>
              <FormHelperText error={true}>{touched.buyer && errors.buyer}</FormHelperText>
            </SoftBox>
          ) : (
            <SoftBox mb={2}>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {selectedBusiness?.name}
              </SoftTypography>
            </SoftBox>
          )}
          { selectedBusiness ?
            <>
              <SoftBox mb={1} lineHeight={0}>
                <SoftTypography variant="caption" color="text">
                  Address:&nbsp;&nbsp;&nbsp;
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {selectedBusiness?.addresses[0]?.address}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={1} lineHeight={0}>
                <SoftTypography variant="caption" color="text">
                  Email Address:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="caption" fontWeight="medium">
                    {selectedBusiness?.email}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="caption" color="text">
                GSTIN:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="caption" fontWeight="medium">
                  {selectedBusiness?.additional_details?.gstin}
                </SoftTypography>
              </SoftTypography>
            </> : null
          }
        </SoftBox>
      </SoftBox>
    </>
  );
}

export default BusinessInformationCard;
