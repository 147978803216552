import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { fetchCreditNoteDetails } from "api/credit_notes";
import logoCT from "assets/images/logo-ct.png";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import Loader from "common/loader";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

function ViewCreditNote() {
  const navigate = useNavigate();
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;

  const { creditNoteId } = useParams();
  const [creditNoteDetails, setCreditNoteDetails] = useState(null);
  const [loadingCreditNoteDetails, setLoadingCreditNoteDetails] = useState(true);

  const currentBusinessID = useSelector(
    (state) => state.user.selectedBusinessID
  );
  const businessList = useSelector((state) => {
    return state.user.businessesList;
  });
  const currentBusiness = businessList.find(
    (business) => business.id === currentBusinessID
  );

  const getData = async () => {
    try {
      const response = await fetchCreditNoteDetails(currentBusinessID, creditNoteId);
      let data = response.data;
      data.items = data.items.map((item) => {
        item.tax = item.product.taxes.reduce(
          (partialSum, tax) => partialSum + item.quantity * parseFloat(item.unit_price) * parseFloat(tax.rate) / 100, 0
        ) || 0;
        return item;
      })
      data.tax = data.items.reduce((partialSum, item) => partialSum + item.tax, 0) || 0;
      setCreditNoteDetails(data)
    } catch (error) {
      console.log("error", error);
    }

    setLoadingCreditNoteDetails(false)
  };

  useEffect(() => {
    getData();
  }, []);

  // Credit note printing
  const creditNotePrintViewRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => creditNotePrintViewRef.current,
    documentTitle: `Credit Note ${creditNoteDetails?.credit_note_number}`,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    }
  });

  const renderHeaderOrFooter = () => (
    <>
      {loadingCreditNoteDetails ? null :
        <SoftBox mt={2} mb={2}>
          <Card>
            <SoftBox p={2}>
              <SoftButton variant="gradient" color="secondary" onClick={() => navigate(-1)}>
                <Icon>arrow_back</Icon>&nbsp;
                back
              </SoftButton>
            </SoftBox>
          </Card>
        </SoftBox>
      }
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={{ xs: 2, md: 4 }} mb={{ xs: 2, md: 4 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={11}>
            {renderHeaderOrFooter()}
            <Card>
              <Loader open={loadingCreditNoteDetails} />
              {!creditNoteDetails ? (
                <>
                  {loadingCreditNoteDetails ? null :
                    <SoftBox p={3}>
                      <SoftTypography variant="h6" fontWeight="medium" align={"center"}>
                        Credit note details not found
                      </SoftTypography>
                    </SoftBox>
                  }
                </>
              ) : (
                <SoftBox ref={creditNotePrintViewRef}>
                  {/* Credit note header */}
                  <SoftBox p={3}>
                    <SoftBox p={1} mb={2} display="flex" lineHeight={1}>
                      <SoftBox component="img" src={logoCT} width="8%" />
                      <SoftBox width="90%">
                        <SoftTypography variant="span" display="flex" justifyContent="center" fontWeight="medium">Credit Note</SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={12} md={4}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          {currentBusiness.name}<br />
                          {currentBusiness.addresses[0].address}
                        </SoftTypography>
                        <SoftBox mt={1} mb={2}>
                          <SoftTypography display="block" variant="body2" color="secondary">
                            tel: {currentBusiness.mobile}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} md={7} lg={3}>
                        <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
                          <SoftBox mt={1}>
                            <SoftTypography variant="h6" fontWeight="medium">
                              Billed to: {creditNoteDetails.buyer.name}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={1}>
                            <SoftTypography variant="body2" color="secondary">
                              {creditNoteDetails.buyer.addresses?.[0]?.address}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                    </Grid>
                    <SoftBox mt={{ xs: 2, md: 4 }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={4}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Credit note no
                              </SoftTypography>
                              <SoftTypography variant="h5" fontWeight="medium">
                                {creditNoteDetails.credit_note_number}
                              </SoftTypography>
                            </Grid>
                            {creditNoteDetails.invoice ?
                              <Grid item>
                                <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                  Invoice no
                                </SoftTypography>
                                <SoftTypography variant="h5" fontWeight="medium">
                                  {creditNoteDetails.invoice.invoice_number}
                                </SoftTypography>
                              </Grid>
                              : null}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                          <SoftBox
                            width="100%"
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            alignItems={{ xs: "flex-start", md: "center" }}
                            textAlign={{ xs: "left", md: "right" }}
                            mt={{ xs: 3, md: 0 }}
                          >
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Credit note date:
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" fontWeight="medium">
                                {new Date(creditNoteDetails.date).toLocaleDateString("en-GB")}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox
                            width="100%"
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            alignItems={{ xs: "flex-start", md: "center" }}
                            textAlign={{ xs: "left", md: "right" }}
                          >
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Due date:
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" fontWeight="medium">
                                {new Date(creditNoteDetails.due_date).toLocaleDateString("en-GB")}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </SoftBox>

                  {/* Credit note table */}
                  <SoftBox p={3}>
                    <SoftBox width="100%" overflow="auto">
                      <Table sx={{ minWidth: "32rem" }}>
                        <SoftBox component="thead">
                          <TableRow>
                            <SoftBox
                              component="th"
                              width={{ xs: "35%", md: "40%" }}
                              py={1.5}
                              px={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                Item
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              width={{ xs: "15%", md: "20%" }}
                              py={1.5}
                              px={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                HSN
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                Qty
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                Rate
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                Tax
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                Amount
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {creditNoteDetails.items.map((creditNoteItem) => (
                            <TableRow key={creditNoteItem.id}>
                              <SoftBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                <SoftTypography variant="body2" color="text">
                                  {creditNoteItem.product.name}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                <SoftTypography variant="body2" color="text">
                                  {creditNoteItem.product.hsn}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {creditNoteItem.quantity}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text" display="flex" alignItems="center">
                                  <CurrencyRupeeIcon /> {creditNoteItem.unit_price}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text" display="flex" alignItems="center">
                                  <CurrencyRupeeIcon /> {creditNoteItem.tax.toFixed(2)}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text" display="flex" alignItems="center">
                                  <CurrencyRupeeIcon /> {(creditNoteItem.tax + parseFloat(creditNoteItem.total_price)).toFixed(2)}
                                </SoftTypography>
                              </SoftBox>
                            </TableRow>
                          ))}

                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              p={1}
                              borderBottom={borderBottom}
                            />
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            />
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            />
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            />
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h5">Total</SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h5"
                                display="flex"
                                alignItems="center">
                                <CurrencyRupeeIcon /> {(creditNoteDetails.tax + parseFloat(creditNoteDetails.total_amount)).toFixed(2)}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      {creditNoteDetails?.bank_account ?
                        <SoftBox p={3}>
                          <SoftTypography variant="h5" fontWeight="medium" mb={2}>
                            Bank Details
                          </SoftTypography>

                          <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text">
                              Account Number:&nbsp;&nbsp;&nbsp;
                              <SoftTypography
                                variant="caption"
                                fontWeight="medium"
                                textTransform="capitalize"
                              >
                                {creditNoteDetails?.bank_account?.account_number}
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text">
                              Account Holder Name:&nbsp;&nbsp;&nbsp;
                              <SoftTypography variant="caption" fontWeight="medium">
                                {creditNoteDetails?.bank_account?.account_holder_name}
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text">
                              IFSC Code:&nbsp;&nbsp;&nbsp;
                              <SoftTypography variant="caption" fontWeight="medium">
                                {creditNoteDetails?.bank_account?.ifsc_code}
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text">
                              Branch Name:&nbsp;&nbsp;&nbsp;
                              <SoftTypography variant="caption" fontWeight="medium">
                                {creditNoteDetails?.bank_account?.branch_name}
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text" hidden={!creditNoteDetails?.bank_account?.iban_number}>
                              IBAN Number:&nbsp;&nbsp;&nbsp;
                              <SoftTypography variant="caption" fontWeight="medium">
                                {creditNoteDetails?.bank_account?.iban_number}
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text" hidden={!creditNoteDetails?.bank_account?.swift_code}>
                              Swift Code:&nbsp;&nbsp;&nbsp;
                              <SoftTypography variant="caption" fontWeight="medium">
                                {creditNoteDetails?.bank_account?.swift_code}
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox> : null
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" flexDirection="column" alignItems="end" pr={2}>
                      {creditNoteDetails?.terms_and_conditions ?
                        <SoftBox p={3}>
                          <SoftTypography variant="h5" fontWeight="medium" mb={2}>
                            Terms and Conditions
                          </SoftTypography>

                          <SoftTypography mb={1} variant="button" fontWeight="regular">
                            <div dangerouslySetInnerHTML={{ __html: creditNoteDetails.terms_and_conditions.content }} />
                          </SoftTypography>
                        </SoftBox> : null
                      }
                    </Grid>
                  </Grid>

                  {/* Credit note footer */}
                  <SoftBox p={3}>
                    <Grid container>
                      {!isPrinting ?
                        <Grid item xs={12} lg={5}>
                          <SoftTypography variant="h5" fontWeight="medium">
                            Thank you!
                          </SoftTypography>
                          <SoftBox mt={1} mb={2} lineHeight={0}>
                            <SoftTypography variant="button" fontWeight="regular" color="secondary">
                              If you encounter any issues related to the credit note you can contact us at:
                            </SoftTypography>
                          </SoftBox>
                          <SoftTypography
                            component="span"
                            variant="h6"
                            fontWeight="medium"
                            color="secondary"
                          >
                            email:{" "}
                            <SoftTypography component="span" variant="h6" fontWeight="medium">
                              app.goseta@gmail.com
                            </SoftTypography>
                          </SoftTypography>
                        </Grid> : null
                      }
                      <Grid item xs={12} lg={7}>
                        <SoftBox
                          width="100%"
                          height={{ xs: "auto", md: "100%" }}
                          display="flex"
                          justifyContent={{ xs: "flex-start", md: "flex-end" }}
                          alignItems="flex-end"
                          mt={{ xs: 2, md: 0 }}
                        >
                          {!isPrinting ?
                            <SoftButton
                              variant="gradient"
                              color="success"
                              onClick={handlePrint}
                            >
                              print
                            </SoftButton> : null
                          }
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </SoftBox>
              )}
            </Card>
            {renderHeaderOrFooter()}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ViewCreditNote;
